import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addPolicy, deletePolicyById, getAllPolicy, getPolicyById, updatePolicyById } from "../../../features/policySlice";
import CreatePolicies from "./CreatePolicies";
import EditPolicies from "./EditPolicies";

// Define the Yup schema
const schema = Yup.object({
        policyIdentifier: Yup.string()
                // .required('Policy name is required'),
                .nullable(),
        departmentIdentifier: Yup.string()
                // .required('Department is required'),
                .nullable(),
        description: Yup.string()
                // .required('Description is required')
                // .min(3, 'Description must be at least 10 characters long'),
                .nullable(),
        companyIdentifier: Yup.string()
                // .required('Company is required'),
                .nullable(),
        uploadPolicy: Yup.mixed()
                .nullable()
                .test('fileType', 'Only PDF files are allowed', (value) => {
                        // Check if value is an array and has at least one file
                        if (!value || (Array.isArray(value) && value.length === 0)) return true; // Allow empty file selection
                        const allowedTypes = ['application/pdf'];
                        return allowedTypes.includes(value[0]?.type); // Use optional chaining
                })
}).required();

// const editSchema = Yup.object().shape({
//         policyIdentifier: Yup.string()
//                 .required('Policy name is required')
//                 .min(3, 'Policy name must be at least 3 characters long'),
//         department: Yup.string()
//                 .required('Department is required')
//                 .min(3, 'Department must be at least 3 characters long'),
//         description: Yup.string()
//                 .required('Description is required')
//                 .min(3, 'Description must be at least 10 characters long'),
//         uploadPolicy: Yup.mixed()
//                 .nullable()
//                 .test('fileType', 'Only PDF files are allowed', (value) => {
//                         // Check if value is an array and has at least one file
//                         if (!value || (Array.isArray(value) && value.length === 0)) return true; // Allow empty file selection
//                         const allowedTypes = ['application/pdf'];
//                         return allowedTypes.includes(value[0]?.type); // Use optional chaining
//                 })
// });

const policy = [
        {
                id: 1,
                policyName: 'RVD',
                department: 'IIt',
                description: 'hello joo',
                createdDate: '10 Feb 2019',
                companyName: 'Devex'
        },
        {
                id: 2,
                policyName: 'SVD',
                department: 'WED',
                description: 'hello robert',
                createdDate: '16 Feb 2019',
                companyName: 'Crown'
        },
        {
                id: 3,
                policyName: 'MVD',
                department: 'ECO',
                description: 'hello sam',
                createdDate: '10 Feb 2019',
                companyName: 'Facebook'
        },
        {
                id: 4,
                policyName: 'RBH',
                department: 'SALE',
                description: 'hello peter',
                createdDate: '1 Feb 2020',
                companyName: 'Google'
        },
]

const companiesList = [
        { companyIdentifier: 'Google', name: "Google" },
        { companyIdentifier: 'Microsoft', name: "Microsoft" },
];

const departmentList = [
        { departmentIdentifier: 'ITT', name: "Devex" },
        { departmentIdentifier: 'HR', name: "Human Resources" },
];

// const clientOptions = companyClients.map(client => ({
//         value: client.clientIdentifier,
//         label: client.clientName
// }));


export default function AllPolicy() {
        const dispatch = useDispatch();
        const { count, allPolicy } = useSelector((state) => state.policy);
        const [isModalVisible, setIsModalVisible] = useState(false);
        const [selectedPolicy, setSelectedPolicy] = useState(null);
        const [selectedImage, setSelectImage] = useState([]);
        const [selectedCompanyId, setSelectedCompanyId] = useState("");
        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10
        });

        const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm({
                resolver: yupResolver(schema),
        });



        const role = JSON.parse(localStorage.getItem('user'));

        useEffect(() => {
                if (selectedPolicy) {
                        setValue('policyName', selectedPolicy?.policyName);
                        setValue('department', selectedPolicy?.department);
                        setValue('description', selectedPolicy?.description);
                        setValue('uploadPolicy', selectedPolicy?.uploadPolicy);
                }
        }, [selectedPolicy]);

        // useEffect(() => {
        //         dispatch(getAllPolicy())
        // }, [dispatch])

        // useEffect(() => {
        //         dispatch(getPolicyById())
        // }, [dispatch])

        const handleEditClick = (record) => {
                setSelectedPolicy(record);
                setIsModalVisible(true);
        };

        const handleEditClickClose = () => {
                setIsModalVisible(false);
        };


        const handleDeleteClick = id => {
                confirmAlert({
                        title: 'Confirm to delete',
                        message: 'Are you sure to do this.',
                        buttons: [
                                {
                                        label: 'Yes',
                                        onClick: () => handleDelete(id),
                                },
                                {
                                        label: 'No',
                                },
                        ],
                });
        };

        const handleDelete = id => {
                // dispatch(deletePolicyById(id));
        }

        const onShowSizeChange = (current, pageSize) => {
                setState({ ...state, page: 1, pagesize: pageSize });
        };

        const itemRender = (current, type, originalElement) => {
                if (type === 'prev') {
                        return <button className="btn btn-sm btn-primary">Previous</button>;
                }
                if (type === 'next') {
                        return <button className="btn btn-sm btn-primary">Next</button>;
                }
                return originalElement;
        };

        const columns = [
                {
                        title: '#',
                        render: (text, record, index) => (
                                <span>{index + 1}</span>
                        ),
                        sorter: (a, b) => a.index - b.index
                },
                ...(role?.role === 'admin' ? [
                        {
                                title: 'Company Name',
                                dataIndex: 'companyName',
                                render: (text, record) => <span>{record.companyName}</span>,
                                sorter: (a, b) => a.companyName.length - b.companyName.length,
                        },
                ] : []),
                {
                        title: 'Policy Name',
                        dataIndex: 'policyName',
                        render: (text, record) => <span>{record.policyName}</span>,
                        sorter: (a, b) => a.policyName.length - b.policyName.length
                },
                {
                        title: 'Department',
                        dataIndex: 'department',
                        render: (text, record) => <span>{record.department}</span>,
                        sorter: (a, b) => a.department.length - b.department.length
                },
                {
                        title: 'Description',
                        dataIndex: 'description',
                        render: (text, record) => <span>{record.description}</span>,
                        sorter: (a, b) => a.description.length - b.description.length
                },
                {
                        title: 'Created Date',
                        dataIndex: 'createdDate',
                        render: (text, record) => <span>{record.createdDate}</span>,
                        sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
                },
                {
                        title: "Action",
                        render: (text, record) => (
                                <div
                                        className="d-flex justify-content-around"
                                        data-popper-placement="bottom-end"
                                >
                                        <Link className="dropdown-item text-success" onClick={() => handleEditClick(record)}>
                                                <i className="fa fa-pencil" />
                                        </Link>
                                        <Link
                                                className="dropdown-item text-danger"
                                                to={"#"}
                                                onClick={() => {
                                                        handleDeleteClick(record.policyIdentifier);
                                                }}
                                        >
                                                <i className="fa fa-trash-o m-r-5" />
                                        </Link>
                                </div>
                        ),
                },
        ];

        return (
                <>
                        <div className="content container-fluid">
                                <div className="page-header">
                                        <div className="row align-items-center">
                                                <div className="col">
                                                        <h3 className="page-title">Policies</h3>
                                                        <ul className="breadcrumb">
                                                                <li className="breadcrumb-item">
                                                                        <a href="admin-dashboard.html">Dashboard</a>
                                                                </li>
                                                                <li className="breadcrumb-item active">Policies</li>
                                                        </ul>
                                                </div>
                                                <div className="col-auto float-end ms-auto">
                                                        <a
                                                                href="#"
                                                                className="btn add-btn"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#add_policy"
                                                        >
                                                                <i className="fa fa-plus"></i> Add Policy
                                                        </a>
                                                </div>
                                        </div>
                                </div>

                                <div className="row">
                                        <div className="col-md-12">
                                                <div className="table-responsive">
                                                        <Table
                                                                columns={columns}
                                                                // dataSource={Array.isArray(policy?.data) ? policy.data : []}
                                                                dataSource={policy}
                                                                rowKey={(record) => record?.id}
                                                                pagination={{
                                                                        current: state.page,
                                                                        pageSize: state.pagesize,
                                                                        total: count,
                                                                        showTotal: (total, range) =>
                                                                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                                        showSizeChanger: true,
                                                                        onShowSizeChange: onShowSizeChange,
                                                                        itemRender: itemRender,
                                                                        onChange: (page, pageSize) =>
                                                                                setState({ ...state, page, pagesize: pageSize }),
                                                                }}
                                                        />
                                                </div>
                                        </div>
                                </div>
                        </div>

                        {/* edit policy */}
                        {isModalVisible && selectedPolicy && (
                                <EditPolicies handleEditClickClose={handleEditClickClose}/>
                        )}
                        <CreatePolicies />
                </>
        );
}
