import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import toast from "react-hot-toast";

const invoiceSlice = createSlice({
        name: 'invoice',
        initialState: {
                allInvoice: [],
                invoice: null,
                isLoading: false,
        },
        reducers: {
                addInvoiceSuccess: (state, action) => {
                        state.isLoading = false;
                },
                addInvoiceFailure: (state, action) => {
                        state.isLoading = false;
                },
                getAllInvoiceSucccess: (state, action) => {
                        state.isLoading = false;
                        state.allInvoice = action.payload.data.data;
                },
                getAllInvoiceFailure: (state, action) => {
                        state.isLoading = false;
                        state.allInvoice = [];
                },
                deleteInvoiceSuccess: (state, action) => {
                        state.isLoading = false;
                },
                deleteInvoiceFailure: (state, action) => {
                        state.isLoading = false;
                },
                getInvoiceSuccess: (state, action) => {
                        state.isLoading = false;
                        state.invoice = action.payload;
                },
                getInvoiceFailure: (state, action) => {
                        state.isLoading = false;
                        state.invoice = [];
                },
                updateInvoiceSuccess: (state, action) => {
                        state.isLoading = false;
                },
                updateInvoiceFailure: (state, action) => {
                        state.isLoading = false;
                },
                setLoading: (state) => {
                        state.isLoading = true;
                }
        }
});

export const addInvoice = (data, isSend) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/Invoice/createInvoice?isSend=${isSend}`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(addInvoiceSuccess(response.data));
                }
                else {
                        toast.error(response.data.message);
                        dispatch(addInvoiceFailure());
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(addInvoiceFailure());
        }
}

export const getAllInvoice = (From, To, search, status, page, pagesize, companyIdentifier) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/Invoice?from=${From}&to=${To}&search=${search}&status=${status}&page=${page}&pagesize=${pagesize}&companyIdentifier=${companyIdentifier}`);
                if (response.data.success) {
                        toast.success(response.data.message)
                        dispatch(getAllInvoiceSucccess(response));
                } else {
                        dispatch(getAllInvoiceFailure());
                }
        } catch (error) {
                dispatch(getAllInvoiceFailure());
                toast.error(error.message);
        }
}

export const deleteInvoice = (id) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/Invoice/deleteInvoice?Identifier=${id}`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(deleteInvoiceSuccess());
                } else {
                        dispatch(deleteInvoiceFailure());
                        toast.error(response.data.message);
                }
        }
        catch (error) {
                dispatch(deleteInvoiceFailure());
                toast.error(error.message);
        }
}

export const getInvoice = (invoiceIdentifier) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/Invoice/${invoiceIdentifier}`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(getInvoiceSuccess(response?.data?.data));
                }
        }
        catch (error) {
                dispatch(getInvoiceFailure());
                toast.error(error.message);
        }
}

export const updateInvoice = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/Invoice/updateInvoice`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(updateInvoiceSuccess());
                }
        }
        catch (error) {
                dispatch(updateInvoiceFailure());
                toast.error(error.message);
        }
}

export const {
        updateInvoiceSuccess, updateInvoiceFailure,
        getInvoiceSuccess, getInvoiceFailure,
        deleteInvoiceFailure, deleteInvoiceSuccess,
        getAllInvoiceSucccess, getAllInvoiceFailure,
        addInvoiceSuccess, addInvoiceFailure,
        getAllTaxTypesSuccess, getAllTaxTypesFailure,
        setLoading }
        = invoiceSlice.actions
export default invoiceSlice.reducer