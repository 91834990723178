import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllCompanies } from "../../../../features/companySlice";
import { addTax } from "../../../../features/taxSlice";
import Loader from "../../../../components/loader/Loader";

const taxSchema = yup.object({
    tax: yup.string().required("Tax is required").trim(),
    percentage: yup.string().required("Percentage is required").trim(),
    status: yup.string().required("Status is required").trim(),
});

const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
];

function AddTax() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const { isLoading } = useSelector((state) => state.tax);
    const { companiesList } = useSelector((state) => state.company);

    const state = {
        search: "",
        page: 1,
        pagesize: 10,
    };

    const {
        formState: { errors },
        control,
        handleSubmit,
    } = useForm({
        defaultValues: {
            tax: "",
            percentage: "",
            status: "",
        },
        resolver: yupResolver(taxSchema),
    });
    console.log("companiesList", companiesList);

    useEffect(() => {
        dispatch(getAllCompanies(state.search, state.page, state.pagesize));
    }, [dispatch, state.search, state.page, state.pagesize]);

    const companyOptions = companiesList.map((company) => ({
        value: company.companyIdentifier,
        label: company.name,
    }));

    const onSubmit = async (data) => {
        setIndex(index + 1);
        const requestData = {
            id: index,
            taxName: data.tax,
            isActive: data.status === "Approved" ? true : false,
            taxRate: data.percentage,
            taxCode: "",
            taxJurisdiction: "",
            taxIdentifier: "",
            companyIdentifier: data?.companyIdentifier,
        };
        dispatch(addTax(requestData));
        // navigate("/admin/all-tax");
    };

    return (
        <div className="content container-fluid">
            <div className="page-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h3 className="page-title">Add Tax</h3>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/admin-dashboard">Admin / Tax</a>
                            </li>
                            <li className="breadcrumb-item active"> Add Tax</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* {isLoading ? (
                <Loader />
            ) : ( */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="form-group">
                                    <label>Company</label>
                                    <Controller
                                        name="companyIdentifier"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <ReactSelect
                                                options={companyOptions}
                                                value={companyOptions.find(
                                                    (option) => option.value === value
                                                )}
                                                onChange={(selected) => onChange(selected?.value)}
                                                isClearable
                                                isSearchable
                                                className="company-box"
                                            />
                                        )}
                                        defaultValue=""
                                    />
                                    <small>{errors?.clientId?.message}</small>
                                </div>
                                <div className="form-group">
                                    <label>
                                        Tax Name <span className="text-danger">*</span>
                                    </label>
                                    <Controller
                                        name="tax"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                    />
                                    <small style={{ color: "red" }}>
                                        {errors?.tax?.message}
                                    </small>
                                </div>
                                <div className="form-group">
                                    <label>
                                        Tax Percentage (%) <span className="text-danger">*</span>
                                    </label>
                                    <Controller
                                        name="percentage"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                    />
                                    <small style={{ color: "red" }}>
                                        {errors?.percentage?.message}
                                    </small>
                                </div>
                                <div className="form-group">
                                    <label>
                                        Status <span className="text-danger">*</span>
                                    </label>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <ReactSelect
                                                options={statusOptions}
                                                value={statusOptions.find(
                                                    (option) => option.value === value
                                                )}
                                                onChange={(selected) => onChange(selected?.value)}
                                                isClearable
                                                isSearchable
                                                className="company-box"
                                            />
                                        )}
                                    />
                                    <small style={{ color: "red" }}>
                                        {errors?.status?.message}
                                    </small>
                                </div>
                                <div className="submit-section">
                                    <button
                                        onClick={handleSubmit(onSubmit)}
                                        className="btn btn-primary submit-btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </div>
    );
}

export default AddTax