import React from 'react';

function ClientProfile() {
  return (
    <div>
      <h2>Client Profile</h2>
      <p>This is the Client Profile Page</p>
    </div>
  );
}

export default ClientProfile;
