import { createSlice } from '@reduxjs/toolkit';
import httpHandler from '../utils/httpHandler';
import { toast } from 'react-hot-toast';

const policySlice = createSlice({
        name: 'policy',
        initialState: {
                allPolicy: [],
                policy: null,
                count: null,
                isLoading: false
        },
        reducers: {
                addPolicySuccess: (state, action) => {
                        state.isLoading = false
                },
                addPolicyFailure: (state, action) => {
                        state.isLoading = false
                },
                getAllPolicySuccess: (state, action) => {
                        state.isLoading = false;
                        state.allPolicy = action.payload;
                },
                getAllPolicyFailure: (state, action) => {
                        state.isLoading = false;
                        state.allPolicy = [];
                },
                getPolicySuccess: (state, action) => {
                        state.isLoading = false;
                        state.policy = action.payload;
                },
                getPolicyFailure: (state, action) => {
                        state.isLoading = false;
                        state.policy = null;
                },
                deletePolicySuccess: (state, action) => {
                        state.isLoading = false;
                },
                deletePolicyFailure: (state, action) => {
                        state.isLoading = false;
                },
                updatePolicySuccess: (state, action) => {
                        state.isLoading = false;
                },
                updatePolicyFailure: (state, action) => {
                        state.isLoading = false;
                },
                setLoading: (state, action) => {
                        state.isLoading = true
                }
        }
});

// create policy
export const addPolicy = (data) => async (dispatch) => {
        try {
                dispatch(setLoading);
                const response = await httpHandler.post('/api/policy/addPolicy', data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(addPolicySuccess())
                } else {
                        dispatch(addPolicyFailure())

                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(addPolicyFailure())
        }
}

// get all policy
export const getAllPolicy = () => async (dispatch) => {
        try {
                dispatch(setLoading);
                const response = await httpHandler.get('/api/policy/allPolicy');
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(getAllPolicySuccess(response.data))
                } else {
                        dispatch(getAllPolicyFailure())
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(getAllPolicyFailure())
        }
}

// get policy by id
export const getPolicyById = (Identifier) => async (dispatch) => {
        try {
                dispatch(setLoading);
                const response = await httpHandler.get(`/api/policy/getPolicy/${Identifier}`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(getPolicySuccess(response.data))
                } else {
                        dispatch(getPolicyFailure())
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(getPolicyFailure())
        }
}

// delete policy by id
export const deletePolicyById = (Identifier) => async (dispatch) => {
        try {
                dispatch(setLoading);
                const response = await httpHandler.get(`/api/policy/deletePolicy/${Identifier}`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(deletePolicySuccess())
                } else {
                        dispatch(deletePolicyFailure())
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(deletePolicyFailure())
        }
}

// update policy by id
export const updatePolicyById = (data) => async (dispatch) => {
        try {
                dispatch(setLoading);
                const response = await httpHandler.get(`/api/policy/updatePolicy`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(updatePolicySuccess())
                } else {
                        dispatch(updatePolicyFailure())
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(updatePolicyFailure())
        }
}


export const {
        addPolicySuccess, addPolicyFailure,
        getAllPolicySuccess, getAllPolicyFailure,
        getPolicyFailure, getPolicySuccess,
        deletePolicySuccess, deletePolicyFailure,
        updatePolicySuccess, updatePolicyFailure,
        setLoading
} = policySlice.actions;
export default policySlice.reducer