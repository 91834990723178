import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { deleteInvoice, getAllInvoice } from '../../../features/invoiceSlice';
import $ from 'jquery';
import Loader from '../../../components/loader/Loader';

function Invoices() {
    const dispatch = useDispatch();
    const companyId = useParams();
    console.log("Companyid",companyId);
    const { allInvoice, isLoading } = useSelector(state => state.invoice);
    const [state, setState] = useState({
        search: '',
        page: 1,
        pagesize: 10,
        fromDate: '',
        toDate: '',
        status: '',
    });

    useEffect(() => {
        dispatch(getAllInvoice(state.fromDate, state.toDate, state.search, state.status, state.page, state.pagesize,companyId?.companyID));
    }, [dispatch, state.fromDate, state.toDate, state.search, state.status, state.page, state.pagesize,companyId?.companyID]);

    const handleDeleteClick = id => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id),
                },
                {
                    label: 'No',
                },
            ],
        });
    };

    const handleDelete = id => {
        dispatch(deleteInvoice(id));
    };

    const filterEstimate = () => {
        dispatch(getAllInvoice(state.search, state.page, state.pagesize, state.fromDate, state.toDate, state.status, '2be1932c-2345-43'));
    };


    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'N/A';
        }
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };


    // Table columns
    const columns = [
        {
            title: '#',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            render: (text, record) => <Link to={`/admin/invoice-veiw/${record.invoiceIdentifier}`}>{text}</Link>,
            sorter: (a, b) => a.title.length - b.title.length,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            render: (text, record) => <span>{text}</span>,
            sorter: (a, b) => a.clientName.length - b.clientName.length,
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            render: (text) => formatDate(text),
            sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            render: (text) => formatDate(text),
            sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            render: (text) => <span>$ {text}</span>,
            sorter: (a, b) => a.totalAmount - b.totalAmount,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
            render: (text, record) => (
                <span className={text === 'Accepted' ? 'badge bg-inverse-success' : 'badge bg-inverse-info'}>
                    {text}
                </span>
            ),
        },
        {
            title: 'Action',
            render: (text, record) => (
                <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
                    <Link className="dropdown-item px-2 text-success" to={`/admin/edit-invoice/${record.invoiceIdentifier}`}>
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => handleDeleteClick(record.invoiceIdentifier)}>
                        <i className="fa fa-trash-o m-r-5" />
                    </Link>
                </div>
            ),
        },
    ];

    // Initialize select2 on mount
    useEffect(() => {
        if ($('.select').length > 0) {
            $('.select').select2({
                minimumResultsForSearch: -1,
                width: '100%',
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Invoices</title>
                <meta name="description" content="Login page" />
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Invoices</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/app/main/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Invoices</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ml-auto">
                            <Link to="/admin/create-invoice" className="btn add-btn"><i className="fa fa-plus" /> Create Invoice</Link>
                        </div>
                    </div>
                </div>
                <div className="row filter-row">
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus select-focus">
                            <div>
                                <input className="form-control floating datetimepicker" type="date" />
                            </div>
                            <label className="focus-label">From</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus select-focus">
                            <div>
                                <input className="form-control floating datetimepicker" type="date" />
                            </div>
                            <label className="focus-label">To</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus select-focus">
                            <select className="select floating">
                                <option>Select Status</option>
                                <option>Pending</option>
                                <option>Paid</option>
                                <option>Partially Paid</option>
                            </select>
                            <label className="focus-label">Status</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <Link href="#" className="btn btn-success btn-block w-100" onClick={filterEstimate}> Search </Link>
                    </div>
                </div>
                {isLoading ? <Loader /> :
                    <div className="row">
                        <div className="col-md-12">
                            {allInvoice?.length > 0 ?
                                <div className="table-responsive">
                                    <Table className="table-striped"
                                        pagination={{
                                            current: state.page,
                                            pageSize: state.pagesize,
                                            showTotal: (total, range) =>
                                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                            showSizeChanger: true,
                                            onShowSizeChange: onShowSizeChange,
                                            itemRender: itemRender,
                                            onChange: (page, pageSize) =>
                                                setState({ ...state, page, pagesize: pageSize }),
                                            total: allInvoice.totalRecords,
                                        }}
                                        style={{ overflowX: 'auto' }}
                                        columns={columns}
                                        dataSource={allInvoice}
                                        rowKey={record => record.id}
                                    />
                                </div>
                                :
                                <div className='is_data_here'>
                                    <h3>Item not found</h3>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Invoices;