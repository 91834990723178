import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import toast from "react-hot-toast";


const terminationSlice = createSlice({
        name: "termination",
        initialState: {
                isLoading: false,
                allTermination: [],
                termination: null,
                count: null
        },
        reducers: {
                addTerminationSuccess: (state, action) => {
                        state.isLoading = false;
                },
                addTerminationFailure: (state, action) => {
                        state.isLoading = false;
                },
                getAllTerminationSuccess: (state, action) => {
                        state.isLoading = false;
                        state.allTermination = action.payload
                },
                getAllTerminationFailure: (state, action) => {
                        state.isLoading = false;
                },
                deleteTerminationSuccess: (state, action) => {
                        state.isLoading = false;
                },
                deleteTerminationFailure: (state, action) => {
                        state.isLoading = false;
                },
                getTerminationSuccess: (state, action) => {
                        state.isLoading = false;
                        state.termination = action.payload;
                },
                getTerminationFailure: (state, action) => {
                        state.isLoading = false;
                },
                updateTerminationSuccess: (state, action) => {
                        state.isLoading = false;
                },
                updateTerminationFailure: (state, action) => {
                        state.isLoading = false;
                },
                setLoading: (state, action) => {
                        state.isLoading = true;
                }
        }
})

export const addTermination = (data) => async (dispatch) => {
        try {
                const response = await httpHandler.post(`/api/termination/add-termination`, data);
                if (response.data.success) {
                        toast.success(response.data.message)
                        dispatch(addTerminationSuccess());
                } else {
                        dispatch(addTerminationFailure());
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(addTerminationFailure())
        }
}

export const getAllTermination = () => async (dispatch) => {
        try {
                const response = await httpHandler.get('/api/termination/all');
                if (response.data.success) {
                        toast.success(response.data.message)
                        dispatch(getAllTerminationSuccess(response.data.data))
                } else {
                        dispatch(getAllTerminationFailure())
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(getAllTerminationFailure())
        }
}

export const deteleTermination = (identifier) => async (dispatch) => {
        try {
                const response = await httpHandler.get(`/api/termination/delete/${identifier}`);
                if (response.data.success) {
                        toast.success(response.data.message)
                        dispatch(deleteTerminationSuccess())
                } else {
                        dispatch(deleteTerminationFailure())
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(deleteTerminationFailure())
        }
}

export const getTermination = (identifier) => async (dispatch) => {
        try {
                const response = await httpHandler.get(`/api/termination/get/${identifier}`);
                if (!response.data.success) {
                        toast.success(response.data.message)
                        dispatch(getTerminationSuccess(response.data.data));
                } else {
                        dispatch(getTerminationFailure());
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(getTerminationFailure());
        }
}

export const updateTermination = (data) => async (dispatch) => {
        try {
                const response = await httpHandler.post(`/api/termination/update-termination`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(updateTerminationSuccess())
                } else {
                        dispatch(updateTerminationFailure())
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(updateTerminationFailure())
        }
}

export const {
        addTerminationSuccess, addTerminationFailure,
        getAllTerminationSuccess, getAllTerminationFailure,
        deleteTerminationSuccess, deleteTerminationFailure,
        getTerminationSuccess, getTerminationFailure,
        updateTerminationSuccess, updateTerminationFailure,
        setLoading
} = terminationSlice.actions;
export default terminationSlice.reducer;