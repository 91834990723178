import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { avatar_01 } from "../../../assets/img";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClient,
  getAllClients,
  getClient,
} from "../../../features/clientSlice";
import { confirmAlert } from "react-confirm-alert";

function Clients() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clients } = useSelector((state) => state.client);
  const [state, setState] = useState({
    search: "",
    page: 1,
    pagesize: 12,
  });

  useEffect(() => {
    dispatch(getAllClients(state.search, state.page, state.pagesize));
  }, [dispatch, state.search, state.page, state.pagesize]);

  const handleDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDelete = (id) => {
    dispatch(deleteClient(id));
  };

  const handleUpdateClick = (id) => {
    dispatch(getClient(id));
    navigate(`/admin/update-client/${id}`);
  };

  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Clients</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/main/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ul>
            </div>
            <div className="col-auto float-end ml-auto">
              <Link to="/admin/add-client" className="btn add-btn">
                <i className="fa fa-plus" /> Add Client
              </Link>
              <div className="view-icons">
                <Link
                  to="/admin/clients-list"
                  className="list-view btn btn-link"
                >
                  <i className="fa fa-bars" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row filter-row">
          <div className="col-sm-6 col-md-3">
            <div className="form-group form-focus">
              <input
                type="text"
                className="form-control floating"
                onChange={(e) =>
                  setState({
                    ...state,
                    search: e.target.value ? e.target.value.toLowerCase() : "",
                  })
                }
              />
              <label className="focus-label">Search...</label>
            </div>
          </div>
        </div>
        <div className="row mt-2 staff-grid-row">
          {clients.length > 0
            ? clients.map((client) => (
              <div
                className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                key={client.clientIdentifier}
              >
                <div className="profile-widget">
                  <div className="profile-img">
                    <Link to="#" className="avatar">
                      <img alt="" src={avatar_01} />
                    </Link>
                  </div>
                  <div className="dropdown profile-action">
                    <Link
                      to="#"
                      className="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link
                        className="dropdown-item"
                        to={"#"}
                        onClick={() =>
                          handleUpdateClick(client.clientIdentifier)
                        }
                      >
                        <i className="fa fa-pencil m-r-5" /> Edit
                      </Link>
                      <Link
                        className="dropdown-item"
                        to={"#"}
                        onClick={() => {
                          handleDeleteClick(client.clientIdentifier);
                        }}
                      >
                        <i className="fa fa-trash m-r-5" /> Delete
                      </Link>
                    </div>
                  </div>
                  <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                    <Link to="/admin/app/profile/client-profile">
                      {client.companyName}
                    </Link>
                  </h4>
                  <h5 className="user-name m-t-10 mb-0 text-ellipsis">
                    <Link to="/admin/app/profile/client-profile">
                      {client.clientName}
                    </Link>
                  </h5>
                  <div className="small text-muted">{client.country}</div>
                </div>
              </div>
            ))
            : ""}
        </div>
      </div>
    </>
  );
}

export default Clients;
