import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { deteleExpense, getAllExpense, statusUpdate } from '../../../features/expenseSlice';
import { Table } from 'antd';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../../components/loader/Loader';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { allCompaniesList } from '../../../features/companySlice';

const options = [
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
];

export default function AllExpense() {
        const user = JSON.parse(localStorage.getItem('user')); 
        const { allExpense, count, isLoading } = useSelector((state) => state.expense);
        const { allCompanies } = useSelector((state) => state.company);
        const dispatch = useDispatch();
        
        const [state, setState] = useState({
          search: "",
          page: 1,
          pagesize: 10,
          purchaseBy: '',
          paidBy: '',
          purchaseFrom: '',
          identifier: user && user.role === 'Company' ? user.companyIdentifier : '', 
        });
        
        
        const { formState: { errors }, control, handleSubmit, setValue } = useForm();
        
        
        const handleStatusChange = (id, status) => {
          dispatch(statusUpdate(id, status));
        };
        
  
        useEffect(() => {
          
          dispatch(getAllExpense(
            state.search,
            state.page,
            state.purchaseFrom,
            state.pagesize,
            state.purchaseBy,
            state.paidBy,
            state.purchaseFrom,
            state.identifier 
          ));
        }, [state.search, state.page,  state.purchaseFrom, state.pagesize,state.purchaseBy, state.paidBy, state.identifier, dispatch]);
        

        useEffect(() => {
                dispatch(allCompaniesList())
        }, [dispatch]);

        const companyOptions = allCompanies?.map(company => ({
                value: company?.companyIdentifier,
                label: company?.name
        }));


        const filterEstimate = () => {
                dispatch(getAllExpense(state.search, state.page, state.pagesize, state.fromDate, state.toDate, state.status, '2be1932c-2345-43'));
        };

        const onShowSizeChange = (current, pageSize) => {
                setState({ ...state, page: 1, pagesize: pageSize });
        };

        const itemRender = (current, type, originalElement) => {
                if (type === 'prev') {
                        return <button className="btn btn-sm btn-primary">Previous</button>;
                }
                if (type === 'next') {
                        return <button className="btn btn-sm btn-primary">Next</button>;
                }
                return originalElement;
        };

        const handleDeleteClick = id => {
                confirmAlert({
                        title: 'Confirm to delete',
                        message: 'Are you sure to do this.',
                        buttons: [
                                {
                                        label: 'Yes',
                                        onClick: () => handleDelete(id),
                                },
                                {
                                        label: 'No',
                                },
                        ],
                });
        };

        const handleDelete = id => {
                dispatch(deteleExpense(id));
        }

        const columns = [
                {
                        title: 'Item',
                        dataIndex: 'itemName',
                        render: (text, record) => <span>{text}</span>,
                        sorter: (a, b) => a.itemName.length - b.itemName.length,
                },
                {
                        title: 'Purchase From',
                        dataIndex: 'purchaseFrom',
                        render: (text) => <span>{text}</span>,
                        sorter: (a, b) => a.purchaseFrom.length - b.purchaseFrom.length,
                },
                {
                        title: 'Purchase Date',
                        dataIndex: 'purchaseDate',
                        sorter: (a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate),
                        render: (text) => {
                                const formattedDate = moment(text).format('DD MMM YYYY');
                                return <span>{formattedDate}</span>;
                        },
                },
                {
                        title: 'Purchase By',
                        dataIndex: 'purchaseBy',
                        render: (text) => <span>{text}</span>,
                        sorter: (a, b) => a.purchaseBy.length - b.purchaseBy.length,
                },
                {
                        title: 'Paid By',
                        dataIndex: 'paidBy',
                        render: (text) => <span>{text}</span>,
                        sorter: (a, b) => a.paidBy.length - b.paidBy.length,
                },
                {
                        title: 'Status',
                        dataIndex: 'status',
                        sorter: (a, b) => a.status.localeCompare(b.status),
                        render: (text, record) => (
                                <div className="dropdown action-label">
                                        <a
                                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                href="#"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="true"
                                        >
                                                <i className={`fa fa-dot-circle-o ${record.status === "Approved" ? 'text-success' : 'text-danger'}`}></i>
                                                {record.status === "Approved" ? 'Approved' : 'Pending'}
                                        </a>
                                        <div className="dropdown-menu">
                                                <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleStatusChange(record.expenseIdentifier, "Approved")}
                                                >
                                                        <i className="fa fa-dot-circle-o text-success"></i> Approved
                                                </span>
                                                <span
                                                        onClick={() => handleStatusChange(record.expenseIdentifier, "Pending")}
                                                        className="dropdown-item" style={{ cursor: 'pointer' }} >
                                                        <i className="fa fa-dot-circle-o text-danger"></i> Pending
                                                </span>
                                        </div>
                                </div>
                        ),
                },
                {
                        title: 'Action',
                        render: (text, record) => (
                                <div className="d-flex justify-content-around">
                                        <Link className="dropdown-item px-2 text-success" to={`/admin/edit-expense/${record.expenseIdentifier}`}>
                                                <i className="fa fa-pencil m-r-5" />
                                        </Link>
                                        <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
                                                handleDeleteClick(record.expenseIdentifier);
                                        }}>
                                                <i className="fa fa-trash-o m-r-5" />
                                        </Link>
                                </div>
                        ),
                },
        ];


        return (
                <div className="content container-fluid">
                        <div className="page-header">
                                <div className="row align-items-center">
                                        <div className="col">
                                                <h3 className="page-title">Expenses</h3>
                                                <ul className="breadcrumb">
                                                        <li className="breadcrumb-item"><a href="admin-dashboard.html">Dashboard</a></li>
                                                        <li className="breadcrumb-item active">Expenses</li>
                                                </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                                <Link to='/admin/create-expense' className="btn add-btn">
                                                        <i className="fa fa-plus"></i> Add Expense
                                                </Link>
                                        </div>
                                </div>
                        </div>
                        <div className="row filter-row">
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus">
                                                <Controller
                                                        name="search"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                                <input
                                                                        type="text"
                                                                        className="form-control floating"
                                                                        value={value}
                                                                        onChange={(e) => {
                                                                                onChange(e.target.value);
                                                                                setState({ ...state, search: e.target.value ? e.target.value.toLowerCase() : '' });
                                                                        }}
                                                                />
                                                        )}
                                                />
                                                <label className="focus-label">Item Name</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus select-focus focused">
                                                <select className="select floating select2-hidden-accessible" aria-hidden="true">
                                                        <option>-- Select --</option>
                                                        <option>Loren Gatlin</option>
                                                        <option>Tarah Shropshire</option>
                                                </select>
                                                <label className="focus-label">Purchased By</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus select-focus focused">
                                                {/* <select className="select floating select2-hidden-accessible" aria-hidden="true">
                                                        <option>-- Select --</option>
                                                        <option>Cash</option>
                                                        <option>Cheque</option>
                                                </select> */}
                                                <label className="focus-label">Paid By</label>
                                                <input type="text" className="form-control floating" />
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus">
                                                <div className="cal-icon">
                                                        <input className="form-control floating datetimepicker" type="text" />
                                                </div>
                                                <label className="focus-label">From</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus">
                                                <div className="cal-icon">
                                                        <input className="form-control floating datetimepicker" type="text" />
                                                </div>
                                                <label className="focus-label">To</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <button className="btn btn-success w-100" onClick={filterEstimate}>Search</button>
                                </div>
                        </div>
                        <div className="row">
                                <div className="col-md-12">
                                        <div className="table-responsive">
                                                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                        <div className="row">
                                                                <div className="col-sm-12 col-md-6">
                                                                        <div className="dataTables_length mt-3" id="DataTables_Table_0_length" style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
                                                                                <label>Show</label>
                                                                                <Controller
                                                                                        name="page"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={options}
                                                                                                        value={options.find(option => option.value === value)}
                                                                                                        onChange={selectedOption => onChange(selectedOption.value)}
                                                                                                        // styles={{ container: base => ({ ...base, width: 60 }) }}
                                                                                                        className="custom-select custom-select-sm"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                {/* <span>Entries</span> */}
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6"></div>
                                                        </div>
                                                        {isLoading
                                                                ?
                                                                <Loader />
                                                                :
                                                                <div className="row mt-3">
                                                                        <div className="col-sm-12">
                                                                                <Table
                                                                                        className="table-striped"
                                                                                        columns={columns}
                                                                                        // dataSource={allExpense}
                                                                                        dataSource={Array.isArray(allExpense) ? allExpense : []}
                                                                                        rowKey={(record) => record?.expenseIdentifier}
                                                                                        pagination={{
                                                                                                current: state.page,
                                                                                                pageSize: state.pagesize,
                                                                                                total: count,
                                                                                                showTotal: (total, range) =>
                                                                                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                                                                showSizeChanger: true,
                                                                                                onShowSizeChange: onShowSizeChange,
                                                                                                itemRender: itemRender,
                                                                                                onChange: (page, pageSize) =>
                                                                                                        setState({ ...state, page, pagesize: pageSize }),
                                                                                        }}
                                                                                />
                                                                        </div>
                                                                </div>
                                                        }
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        )
}
