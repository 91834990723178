import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { updatePolicyById } from '../../../features/policySlice';

const schema = Yup.object({
        policyIdentifier: Yup.string()
                // .required('Policy name is required'),
                .nullable(),
        departmentIdentifier: Yup.string()
                // .required('Department is required'),
                .nullable(),
        description: Yup.string()
                // .required('Description is required')
                // .min(3, 'Description must be at least 10 characters long'),
                .nullable(),
        companyIdentifier: Yup.string()
                // .required('Company is required'),
                .nullable(),
        uploadPolicy: Yup.mixed()
                .nullable()
                .test('fileType', 'Only PDF files are allowed', (value) => {
                        // Check if value is an array and has at least one file
                        if (!value || (Array.isArray(value) && value.length === 0)) return true; // Allow empty file selection
                        const allowedTypes = ['application/pdf'];
                        return allowedTypes.includes(value[0]?.type); // Use optional chaining
                })
}).required();

export default function EditPolicies({ handleEditClickClose }) {
        const dispatch = useDispatch();
        const { count, allPolicy } = useSelector((state) => state.policy);
        const [isModalVisible, setIsModalVisible] = useState(false);
        const [selectedPolicy, setSelectedPolicy] = useState(null);
        const [selectedImage, setSelectImage] = useState([]);
        const [selectedCompanyId, setSelectedCompanyId] = useState("");

        const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm({
                resolver: yupResolver(schema),
        });

        const handleFileChange = (e, onChange) => {
                const files = Array.from(e.target.files);
                setSelectImage(prevImages => [...prevImages, ...files]);
                onChange(files);
        };

        const onSubmit = (data) => {
                const formData = new FormData();
                formData.append('policyIdentifier', data?.policyIdentifier);
                formData.append('companyIdentifier', data?.companyIdentifier);
                formData.append('departmentIdentifier', data?.departmentIdentifier);
                formData.append('description', data?.description);
                formData.append('uploadPolicy', data?.uploadPolicy);

                dispatch(updatePolicyById(formData))
        };

        return (
                <>
                        <div className="modal fade show" id="edit_policy" style={{ display: "block" }}>
                                <div className="modal-dialog">
                                        <div className="modal-content">
                                                <div className="modal-header">
                                                        <h5 className="modal-title">Edit Policy</h5>
                                                        <button
                                                                type="button"
                                                                className="btn-close"
                                                                onClick={handleEditClickClose}
                                                        ></button>
                                                </div>
                                                <div className="modal-body">
                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                                <div className="mb-3">
                                                                        <label htmlFor="policyName" className="form-label">Policy Name</label>
                                                                        <Controller
                                                                                name="policyName"
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                        <input
                                                                                                type="text"
                                                                                                className={`form-control ${errors.policyName ? 'is-invalid' : ''}`}
                                                                                                id="policyName"
                                                                                                placeholder="Enter policy name"
                                                                                                {...field}
                                                                                        />
                                                                                )}
                                                                        />
                                                                        {errors.policyName && <div className="invalid-feedback">{errors.policyName.message}</div>}
                                                                </div>
                                                                <div className="mb-3">
                                                                        <label htmlFor="department" className="form-label">Department</label>
                                                                        <Controller
                                                                                name="department"
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                        <input
                                                                                                type="text"
                                                                                                className={`form-control ${errors.department ? 'is-invalid' : ''}`}
                                                                                                id="department"
                                                                                                placeholder="Enter department"
                                                                                                {...field}
                                                                                        />
                                                                                )}
                                                                        />
                                                                        {errors.department && <div className="invalid-feedback">{errors.department.message}</div>}
                                                                </div>
                                                                <div className="mb-3">
                                                                        <label htmlFor="description" className="form-label">Description</label>
                                                                        <Controller
                                                                                name="description"
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                        <textarea
                                                                                                className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                                                                                id="description"
                                                                                                placeholder="Enter description"
                                                                                                {...field}
                                                                                        ></textarea>
                                                                                )}
                                                                        />
                                                                        {errors.description && <div className="invalid-feedback">{errors.description.message}</div>}
                                                                </div>
                                                                <div className="mb-3">
                                                                        <label htmlFor="uploadPolicy" className="form-label">Upload Policy</label>
                                                                        <Controller
                                                                                name="uploadPolicy"
                                                                                control={control}
                                                                                render={({ field: { onChange } }) => (
                                                                                        <input
                                                                                                className={`form-control ${errors.uploadPolicy ? 'is-invalid' : ''}`}
                                                                                                type="file"
                                                                                                onChange={(e) => {
                                                                                                        onChange(e.target.files); // Pass the files to the onChange
                                                                                                }}
                                                                                                multiple
                                                                                        />
                                                                                )}
                                                                        />
                                                                        {errors.uploadPolicy && <div className="invalid-feedback">{errors.uploadPolicy.message}</div>}
                                                                </div>
                                                        </form>
                                                </div>
                                                <div className="modal-footer">
                                                        <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                onClick={handleEditClickClose}
                                                        >
                                                                Close
                                                        </button>
                                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
                                                                Save Policy
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        )
}
