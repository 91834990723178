import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { confirmAlert } from "react-confirm-alert";
import {
  changePriority,
  changeStatus,
  deleteTicket,
  getAllTickets,
} from "../../../features/ticketsSlice";
import { Priorities, Status } from "../../../constant";
import $ from "jquery";
import { avatar_01 } from "../../../assets/img";
import moment from "moment";

function AllTickets() {
  const dispatch = useDispatch();
  const { tickets, ticketsCount, ticketCountObject } = useSelector(
    (state) => state.ticket
  );
  const [state, setState] = useState({
    search: "",
    page: 1,
    pagesize: 5,
    status: "",
    priority: "",
  });

  useEffect(() => {
    $(".select").select2({
      width: "100%",
    });
  });

  const reloadData = useCallback(async () => {
    dispatch(
      getAllTickets(
        state.search,
        state.page,
        state.pagesize,
        state.status,
        state.priority
      )
    );
  }, [
    dispatch,
    state.search,
    state.page,
    state.pagesize,
    state.status,
    state.priority,
  ]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  const handleDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDelete = (id) => {
    dispatch(deleteTicket(id));
  };

  const handlePriorityChange = (record, newPriority) => {
    dispatch(changePriority(record.ticketIdentifier, newPriority, reloadData));
  };

  const handleStatusChange = (record, newStatus) => {
    dispatch(changeStatus(record.ticketIdentifier, newStatus, reloadData));
  };

  const onShowSizeChange = (current, pageSize) => {
    setState({ ...state, page: 1, pagesize: pageSize });
  };

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <button className="btn btn-sm btn-primary">Previous</button>;
    }
    if (type === "next") {
      return <button className="btn btn-sm btn-primary">Next</button>;
    }
    return originalElement;
  };

  const onStatusChange = (event) => {
    setState({ ...state, status: event.target.value });
  };
  const onPriorityChange = (event) => {
    setState({ ...state, priority: event.target.value });
  };
  const formatDate = (date) => {
    return moment(date ? date : new Date()).format("yyyy-MM-DD HH:mm");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "ticketIdentifier",
      render: (text, record, index) => (
        <div className="dropdown action-label">
          <Link key={text} to={`/admin/ticket-view/${record.ticketIdentifier}`}>
            #TKT-{index + 1}
          </Link>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Assigned Staff",
      dataIndex: "assignedTo",
      render: (text, record) => (
        <h6 className="table-avatar">
          {text ? (
            <div>
              <div className="avatar">
                <img alt="" src={avatar_01} />
              </div>
              {text}
            </div>
          ) : (
            "N/A"
          )}
        </h6>
      ),
    },
    {
      title: "Company",
      dataIndex: "companyName",
    },
    {
      title: "Raised By",
      dataIndex: "raisedByName",
    },
    {
      title: "Created Date",
      dataIndex: "createDate",
      render: (text, record) => formatDate(text),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      render: (text, record) => (
        <div className="dropdown action-label">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            to="#"
          >
            <i
              className={`fa fa-dot-circle-o text-${
                text === "High"
                  ? "danger"
                  : text === "Medium"
                  ? "warning"
                  : "success"
              }`}
            />{" "}
            {text}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {Priorities.map(({ label, value, faIcon }) => (
              <Link
                className="dropdown-item"
                to="#"
                key={value}
                onClick={() => handlePriorityChange(record, value)}
              >
                <i className={faIcon} /> {label}
              </Link>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            to="#"
          >
            <i
              className={`fa fa-dot-circle-o text-${
                text === "Open"
                  ? "info"
                  : text === "Re-Opened"
                  ? "info"
                  : text === "On-Hold"
                  ? "danger"
                  : text === "Closed"
                  ? "success"
                  : text === "InProgress"
                  ? "success"
                  : "danger"
              }`}
            />{" "}
            {text}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {Status.map(({ label, value, faIcon }) => (
              <Link
                className="dropdown-item"
                to="#"
                key={value}
                onClick={() => handleStatusChange(record, value)}
              >
                <i className={faIcon} /> {label}
              </Link>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <div
          className="d-flex justify-content-around"
          data-popper-placement="bottom-end"
        >
          <Link
            to={`/admin/edit-ticket/${record.ticketIdentifier}`}
            className="dropdown-item px-2 text-success"
          >
            <i className="fa fa-pencil m-r-5" />
          </Link>
          <Link
            to={"#"}
            className="dropdown-item px-2 text-danger"
            onClick={() => {
              handleDeleteClick(record.ticketIdentifier);
            }}
          >
            <i className="fa fa-trash-o m-r-5" />
          </Link>
        </div>
      ),
    },
  ];
  return (
    <>
      <Helmet>
        <title>Tickets - CHS</title>
        <meta name="description" content="Login page" />
      </Helmet>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Tickets</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Tickets</li>
              </ul>
            </div>
          </div>
        </div>
        {ticketCountObject ? (
          <div className="row">
            <div className="col-md-12">
              <div className="card-group m-b-30">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">Closed Tickets</span>
                      </div>
                    </div>
                    <h3 className="mb-3">{ticketCountObject.closedCount}</h3>
                    <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: `${(ticketCountObject.closedCount / ticketsCount) * 100}%` }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">In Progress Tickets</span>
                      </div>
                    </div>
                    <h3 className="mb-3">
                      {ticketCountObject.inProgressCount}
                    </h3>
                    <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: `${(ticketCountObject.inProgressCount / ticketsCount) * 100}%` }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">Pending Tickets</span>
                      </div>
                    </div>
                    <h3 className="mb-3">{ticketCountObject.isPendingCount}</h3>
                    <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: `${(ticketCountObject.isPendingCount / ticketsCount) * 100}%` }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">Open Tickets</span>
                      </div>
                    </div>
                    <h3 className="mb-3">{ticketCountObject.openCount}</h3>
                    <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: `${(ticketCountObject.openCount / ticketsCount) * 100}%` }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          "Loading"
        )}
        <div className="row filter-row">
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="form-group form-focus">
              <input
                type="text"
                className="form-control floating"
                onChange={(e) =>
                  setState({
                    ...state,
                    search: e.target.value ? e.target.value.toLowerCase() : "",
                  })
                }
              />
              <label className="focus-label">--Subject--</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="form-group form-focus select-focus">
              <select
                className="form-control floating statuss"
                value={state.status}
                onChange={onStatusChange}
              >
                <option value={""}>Select...</option>
                {Status.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <label className="focus-label">Status</label>
              <span className="select2-selection__arrow" role="presentation">
                <b role="presentation" className="down"></b>
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="form-group form-focus select-focus">
              <select
                className="form-control floating statuss"
                value={state.priority}
                onChange={onPriorityChange}
              >
                <option value={""}>Select...</option>
                {Priorities.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <label className="focus-label">Priority</label>
              <span className="select2-selection__arrow" role="presentation">
                <b role="presentation" className="down"></b>
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                pagination={{
                  current: state.page,
                  pageSize: state.pagesize,
                  total: ticketsCount,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                  onChange: (page, pageSize) =>
                    setState({ ...state, page, pagesize: pageSize }),
                }}
                style={{ overflowX: "auto" }}
                columns={columns}
                dataSource={tickets}
                rowKey={(record) => record.ticketIdentifier}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTickets;
