import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactSelect from "react-select";
import { getTax, updateTax } from "../../../features/taxSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { getAllCompanies } from "../../../features/companySlice";

const taxSchema = yup.object({
  tax: yup.string().required("Tax is required").trim(),
  percentage: yup.string().required("Percentage is required").trim(),
  status: yup.string().required("Status is required").trim(),
});

export default function EditTax() {
  const Id = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tax, isLoading } = useSelector((state) => state.tax);
  const { companiesList } = useSelector((state) => state.company);

  const state = {
    search: "",
    page: 1,
    pagesize: 10,
  };

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
  ];

  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(taxSchema),
  });

  useEffect(() => {
    if (tax) {
      setValue("tax", tax?.data?.taxName || "");
      setValue("percentage", tax?.data?.taxRate || "");
      setValue("status", tax?.data?.isActive ? "Approved" : "Pending");
      setValue("companyIdentifier", tax?.data?.companyIdentifier);
    }
  }, [tax, setValue]);

  useEffect(() => {
    dispatch(getAllCompanies(state.search, state.page, state.pagesize));
  }, [dispatch, state.search, state.page, state.pagesize]);

  const companyOptions = companiesList.map((company) => ({
    value: company.companyIdentifier,
    label: company.name,
  }));

  const onSubmit = (data) => {
    const requestData = {
      id: tax?.data?.id,
      taxName: data?.tax,
      taxCode: "",
      taxRate: data?.percentage,
      taxJurisdiction: "",
      taxIdentifier: Id?.taxIdentifier,
      isActive: data?.status === "Approved" ? true : false,
      companyIdentifier: data?.companyIdentifier,
    };
    dispatch(updateTax(requestData));
    navigate("/admin/all-tax");
  };

  useEffect(() => {
    dispatch(getTax(Id?.taxIdentifier));
  }, [dispatch, Id?.taxIdentifier]);

  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">Edit Tax</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin-dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">Edit Tax</li>
            </ul>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="form-group">
                    <label>Company</label>
                    <Controller
                      name="companyIdentifier"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ReactSelect
                          options={companyOptions}
                          value={companyOptions.find(
                            (option) => option.value === value
                          )}
                          onChange={(selected) => onChange(selected?.value)}
                          isClearable
                          isSearchable
                          className="company-box"
                        />
                      )}
                    />
                    <small>{errors?.companyIdentifier?.message}</small>
                  </div>
                  <div className="form-group">
                    <label>
                      Tax Name <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="tax"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control mb-0`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                    />
                    <small style={{ color: "red" }}>
                      {errors?.tax?.message}
                    </small>
                  </div>
                  <div className="form-group">
                    <label>
                      Tax Percentage (%) <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="percentage"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control mb-0`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                    />
                    <small style={{ color: "red" }}>
                      {errors?.percentage?.message}
                    </small>
                  </div>
                  <div className="form-group">
                    <label>
                      Status <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ReactSelect
                          options={statusOptions}
                          value={statusOptions.find(
                            (option) => option.value === value
                          )}
                          onChange={(selected) => onChange(selected?.value)}
                          isClearable
                          isSearchable
                          className="company-box"
                        />
                      )}
                    />
                    <small style={{ color: "red" }}>
                      {errors?.status?.message}
                    </small>
                  </div>
                  <div className="submit-section">
                    <button
                      onClick={handleSubmit(onSubmit)}
                      className="btn btn-primary submit-btn"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
