import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addPayRollItem } from '../../../features/payRollItemSlice';
import { useSelector } from 'react-redux';
import { getAllCompanies } from '../../../features/companySlice';

export default function CreateAddition({ showModal, closeModal }) {
        const dispatch = useDispatch();
        const { companies } = useSelector((state) => state.company);
        const { control, handleSubmit, formState: { errors } } = useForm();
        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10,
                type: 'PayRoll'
        });

        const onsubmit = (data) => {
                const payroll = {
                        name: data.name,
                        categories: getCategoryValue(data.category),
                        assignee: 1,
                        employeeIdentifier: "02cced1f-e81e-4d20-a5c7-c5846188",
                        companyIdentifier: "4fd4d770-4c15-49",
                        unitCalculation: true,
                        unitPrice: data?.unitPrice,
                        payStatus: "string",
                        type: "PayRoll",
                        payrollIdentifier: null
                }
                dispatch(addPayRollItem(payroll))
                closeModal();
        };

        const getCategoryValue = (category) => {
                switch (category) {
                        case 'Furniture':
                                return 1;
                        case 'Electronic':
                                return 2;
                        case 'Vehicle':
                                return 3;
                        case 'Instruments':
                                return 4;
                        case 'Land':
                                return 5;
                        case 'Building':
                                return 6;
                        default:
                                return 0;
                }
        };

        return (
                <Modal show={showModal} onHide={closeModal} centered>
                        <Modal.Header closeButton>
                                <Modal.Title>Add Deduction</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                <form onSubmit={handleSubmit(onsubmit)}>
                                        <div className="form-group">
                                                <label>Name <span className="text-danger">*</span></label>
                                                <Controller
                                                        name="name"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: "Name is required" }}
                                                        render={({ field }) => (
                                                                <input className="form-control" type="text" {...field} />
                                                        )}
                                                />
                                                {errors.name && <small className="text-danger">{errors.name.message}</small>}
                                        </div>

                                        <div className="form-group">
                                                <label className="d-block">Unit calculation</label>
                                                <Controller
                                                        name="unitCalculation"
                                                        control={control}
                                                        defaultValue={false}
                                                        render={({ field }) => (
                                                                <div className="status-toggle">
                                                                        <input type="checkbox" id="unit_calculation_deduction" className="check" {...field} />
                                                                        <label htmlFor="unit_calculation_deduction" className="checktoggle">checkbox</label>
                                                                </div>
                                                        )}
                                                />
                                        </div>

                                        <div className="form-group">
                                                <label>Unit Amount <span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                        <span className="input-group-text">$</span>
                                                        <Controller
                                                                name="unitPrice"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{
                                                                        required: "Unit amount is required",
                                                                        pattern: { value: /^\d+(\.\d{1,2})?$/, message: "Invalid amount" }
                                                                }}
                                                                render={({ field }) => (
                                                                        <input type="text" className="form-control" {...field} />
                                                                )}
                                                        />
                                                        <span className="input-group-text">.00</span>
                                                </div>
                                                {errors.unitAmount && <small className="text-danger">{errors.unitAmount.message}</small>}
                                        </div>

                                        <div className="form-group">
                                                <label className="d-block">Assignee</label>
                                                <Controller
                                                        name="deductionAssignee"
                                                        control={control}
                                                        defaultValue="option1"
                                                        rules={{ required: "Assignee is required" }}
                                                        render={({ field }) => (
                                                                <div>
                                                                        <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" id="deduction_no_emp" value="option1" {...field} />
                                                                                <label className="form-check-label" htmlFor="deduction_no_emp">No assignee</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" id="deduction_all_emp" value="option2" {...field} />
                                                                                <label className="form-check-label" htmlFor="deduction_all_emp">All employees</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" id="deduction_single_emp" value="option3" {...field} />
                                                                                <label className="form-check-label" htmlFor="deduction_single_emp">Select Employee</label>
                                                                        </div>
                                                                </div>
                                                        )}
                                                />
                                                {errors.deductionAssignee && <small className="text-danger">{errors.deductionAssignee.message}</small>}
                                        </div>

                                        <div className="form-group">
                                                <label>Select Employee</label>
                                                <Controller
                                                        name="employee"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: "Please select an employee" }}
                                                        render={({ field }) => (
                                                                <select className="form-control" {...field}>
                                                                        <option value="">-</option>
                                                                        <option value="all">Select All</option>
                                                                        <option value="john">John Doe</option>
                                                                        <option value="richard">Richard Miles</option>
                                                                </select>
                                                        )}
                                                />
                                                {errors.employee && <small className="text-danger">{errors.employee.message}</small>}
                                        </div>

                                        <div className="submit-section">
                                                <Button type="submit" className="btn btn-primary submit-btn">Submit</Button>
                                        </div>
                                </form>
                        </Modal.Body>
                </Modal>
        )
}
