export const alphaNumericPattern = /^[a-zA-Z0-9_ .-]*$/;
export const emailrgx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
export const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

export const ROLES = {
  Company: "Company",
  Employee: "Employee",
  Admin: "Admin",
};

export const Priorities = [
  {
    label: "Low",
    value: "Low",
    faIcon: "fa fa-dot-circle-o text-success",
  },
  {
    label: "Medium",
    value: "Medium",
    faIcon: "fa fa-dot-circle-o text-warning",
  },
  {
    label: "High",
    value: "High",
    faIcon: "fa fa-dot-circle-o text-danger",
  },
];

export const Status = [
  {
    label: "Select....",
    value: "",
  },
  {
    label: "Open",
    value: "Open",
    faIcon: "fa fa-dot-circle-o text-info",
  },
  {
    label: "Re-Opened",
    value: "Re-Opened",
    faIcon: "fa fa-dot-circle-o text-info",
  },
  {
    label: "On-Hold",
    value: "On-Hold",
    faIcon: "fa fa-dot-circle-o text-danger",
  },
  {
    label: "Closed",
    value: "Closed",
    faIcon: "fa fa-dot-circle-o text-success",
  },
  {
    label: "InProgress",
    value: "InProgress",
    faIcon: "fa fa-dot-circle-o text-success",
  },
  {
    label: "IsPending",
    value: "IsPending",
    faIcon: "fa fa-dot-circle-o text-danger",
  },
];
export const typeOptions = [
  {
    label: "Fixed",
    value: "Fixed",
  },
];
export const ProjectCategory = [
  {
    label: "Select....",
    value: "",
  },
  {
    label: "Development",
    value: "Development",
  },
  {
    label: "Management",
    value: "Management",
  },
  {
    label: "Digital Marketing",
    value: "DigitalMarketing",
  },
  {
    label: "Designing",
    value: "Designing",
  },
];

export const CompanyType = [
  {
    label: "E-commerce",
    value: "E-commerce",
  },
  {
    label: "Tech Startup",
    value: "TechStartup",
  },
  {
    label: "Software Development",
    value: "SoftwareDevelopment",
  },
  {
    label: "Digital Marketing",
    value: "DigitalMarketing",
  },
  {
    label: "Online Education",
    value: "OnlineEducation",
  },
  {
    label: "Media & Entertainment",
    value: "Media&Entertainment",
  },
  {
    label: "Telecommunications",
    value: "Telecommunications",
  },
  {
    label: "Financial Technology (Fintech)",
    value: "FinancialTechnology(Fintech)",
  },
  {
    label: "Healthtech",
    value: "Healthtech",
  },
  {
    label: "E-health",
    value: "E-health",
  },
  {
    label: "Gaming",
    value: "Gaming",
  },
  {
    label: "Cloud Services",
    value: "CloudServices",
  },
  {
    label: "E-learning",
    value: "E-learning",
  },
  {
    label: "SaaS (Software-as-a-Service)",
    value: "SaaS(Software-as-a-Service)",
  },
  {
    label: "Social Media",
    value: "SocialMedia",
  },
  {
    label: "E-services",
    value: "E-services",
  },
  {
    label: "Cybersecurity",
    value: "Cybersecurity",
  },
  {
    label: "Artificial Intelligence (AI)",
    value: "ArtificialIntelligence(AI)",
  },
  {
    label: "IoT (Internet of Things)",
    value: "IoT(InternetOfThings)",
  },
  {
    label: "Blockchain/Cryptocurrency",
    value: "Blockchain/Cryptocurrency",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const CompanySize = [
  {
    label: "0-10",
    value: "0-10",
  },
  {
    label: "11-25",
    value: "11-25",
  },
  {
    label: "26-100",
    value: "26-100",
  },
  {
    label: "100-500",
    value: "100-500",
  },
  {
    label: "500-10000",
    value: "500-10000",
  },
  {
    label: "10000+",
    value: "10000+",
  },
];

export const PunchFlg = {
  IN: 'I',
  OUT: 'O',
};

export const ButtonTexts = {
  PunchIN: 'Punch IN',
  PunchOUT: 'Punch OUT',
};

export const TaskboardVisibility = [
  {
    label: "WorkSpace",
    value: "WorkSpace",
  },
  {
    label: "Private",
    value: "Private",
  },
];