import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import NavMenu from '../../../components/NavBars/NavMenu'
import Sidebar from '../../../components/SideBar/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProject, getAllProjects } from '../../../features/projectSlice'
import { confirmAlert } from 'react-confirm-alert'
import moment from 'moment'

const CompanyAllProjects = () => {
    const { companyId } = useParams();
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);
    const dispatch = useDispatch();
    const { projects } = useSelector(state => state.project);
    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 10
    })
    useEffect(() => {
        const isCompany = true;
        const allProjects = async () => {
            dispatch(getAllProjects(state.search, state.page, state.pagesize, isCompany, companyId));
        };
        allProjects();
    }, [state.search, state.page, state.pagesize])

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };
    const handleDelete = (id) => {
        dispatch(deleteProject(id));
    };
    const formatDate = (date) => {
        return moment(date).format("DD-MMM-YYYY");
    };
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <NavMenu onMenuClick={() => toggleMobileMenu()} />
                <Sidebar />
                <div className="page-wrapper">
                    <Helmet>
                        <title>Projects - HRMS Admin Template</title>
                        <meta name="description" content="Login page" />
                    </Helmet>
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h3 className="page-title">Projects</h3>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/company-dashboard">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Projects</li>
                                    </ul>
                                </div>
                                <div className="col-auto float-end ml-auto">
                                    <Link to={`/create-project/${companyId}`} className="btn add-btn"><i className="fa fa-plus" /> Create Project</Link>
                                    <div className="view-icons">
                                        <Link to={`/projects-list/${companyId}`} className="list-view btn btn-link"><i className="fa fa-bars" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row filter-row mb-3">
                            <div className="col-sm-6 col-md-3">
                                <div className="form-group form-focus">
                                    <input
                                        type="text"
                                        className="form-control floating"
                                        onChange={(e) => setState({ ...state, search: e.target.value ? e.target.value.toLowerCase() : '' })}
                                    />
                                    <label className="focus-label">Search...</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {projects.length > 0 ? (
                                projects.map((project) => (
                                    <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3"
                                        key={project.projectIdentifier}
                                    >
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="dropdown dropdown-action profile-action">
                                                    <Link to="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></Link>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link
                                                            to={`/update-project/${project.projectIdentifier}/${companyId}`}
                                                            className="dropdown-item"
                                                        >
                                                            <i className="fa fa-pencil m-r-5" /> Edit
                                                        </Link>
                                                        <Link
                                                            className="dropdown-item"
                                                            to={"#"}
                                                            onClick={() => {
                                                                handleDeleteClick(project.projectIdentifier);
                                                            }}
                                                        >
                                                            <i className="fa fa-trash-o m-r-5" /> Delete
                                                        </Link>
                                                    </div>
                                                </div>
                                                <h4 className="project-title"><Link to={`/project-view/${project.projectIdentifier}/${companyId}`}>{project.projectName}</Link></h4>
                                                {/* <small className="block text-ellipsis m-b-15">
                                                    <span className="text-xs">12</span> <span className="text-muted">open tasks, </span>
                                                    <span className="text-xs">4</span> <span className="text-muted">tasks completed</span>
                                                </small> */}
                                                <p className="text-muted">{project.projectDescription.substring(0, 27)+"..."}
                                                </p>
                                                <div className="pro-deadline m-b-15">
                                                    <div className="sub-title">
                                                        Deadline:
                                                    </div>
                                                    <div className="text-muted">
                                                        {formatDate(project.endDate)}
                                                    </div>
                                                </div>
                                                <div className="project-members m-b-15">
                                                    <div>Project Leader :</div>
                                                    <ul className="team-members">
                                                        <li>
                                                            <div className="text-muted">{project.ownerName}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* <div className="project-members m-b-15">
                                                    <div>Team :</div>
                                                    <ul className="team-members">
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src={avatar_01} /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src={avatar_01} /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src={avatar_01} /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src={avatar_01} /></a>
                                                        </li>
                                                        <li className="dropdown avatar-dropdown">
                                                            <a href="#" className="all-users dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">+15</a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <div className="avatar-group">
                                                                    <a className="avatar avatar-xs" href="#">
                                                                        <img alt="" src={avatar_01} />
                                                                    </a>
                                                                    <a className="avatar avatar-xs" href="#">
                                                                        <img alt="" src={avatar_01} />
                                                                    </a>
                                                                    <a className="avatar avatar-xs" href="#">
                                                                        <img alt="" src={avatar_01} />
                                                                    </a>
                                                                    <a className="avatar avatar-xs" href="#">
                                                                        <img alt="" src={avatar_01} />
                                                                    </a>
                                                                    <a className="avatar avatar-xs" href="#">
                                                                        <img alt="" src={avatar_01} />
                                                                    </a>
                                                                    <a className="avatar avatar-xs" href="#">
                                                                        <img alt="" src={avatar_01} />
                                                                    </a>
                                                                    <a className="avatar avatar-xs" href="#">
                                                                        <img alt="" src={avatar_01} />
                                                                    </a>
                                                                    <a className="avatar avatar-xs" href="#">
                                                                        <img alt="" src={avatar_01} />
                                                                    </a>
                                                                    <a className="avatar avatar-xs" href="#">
                                                                        <img alt="" src={avatar_01} />
                                                                    </a>
                                                                </div>
                                                                <div className="avatar-pagination">
                                                                    <ul className="pagination">
                                                                        <li className="page-item">
                                                                            <a className="page-link" href="#" aria-label="Previous">
                                                                                <span aria-hidden="true">«</span>
                                                                                <span className="sr-only">Previous</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                                        <li className="page-item">
                                                                            <a className="page-link" href="#" aria-label="Next">
                                                                                <span aria-hidden="true">»</span>
                                                                                <span className="sr-only">Next</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                                {/* <p className="m-b-5">Progress <span className="text-success float-end">40%</span></p>
                                                <div className="progress progress-xs mb-0">
                                                    <div className="progress-bar bg-success" role="progressbar" data-bs-toggle="tooltip" title="40%" style={{ width: '40%' }} />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : ("")}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyAllProjects