import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import { deleteEmployee, getAllEmployeesforCompany, getEmployee } from '../../../features/employeeSlice';
import Loader from '../../../components/loader/Loader';

function CompanyEmployees() {
    const { companyId } = useParams();
    const dispatch = useDispatch();
    
    const { isLoading, companyEmployees, companyEmployeesCount } = useSelector(state => state.employee);
    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 10
    })

    useEffect(() => {
        reloadData();
    }, [state.search, state.page, state.pagesize])

    const reloadData = async () => {
        dispatch(getAllEmployeesforCompany(state.search, companyId, state.page, state.pagesize));
    };

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const handleDelete = (id) => {
        var company = true;
        dispatch(deleteEmployee(id, companyId, company));
    };

    const handleEditClick = (id) => {
        dispatch(getEmployee(id));
    };

    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    const user = JSON.parse(localStorage.getItem('user'));
    const role = user?.role;

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };

    const columns = [
        {
            title: "#",
            dataIndex: "ticketIdentifier",
            render: (text, record, index) => (
                <div className="dropdown action-label">
                    <h6 key={text}>{index + 1}</h6>
                </div>
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => (
                <h5 className="table-avatar">
                    <Link
                        to={`/${role.toLowerCase()}/employee-profile/${record.employeeIdentifier}`}
                        className="avatar"
                    >
                        <img alt="" src={record.userProfile} />
                    </Link>
                    <Link
                        to={`/${role.toLowerCase()}/employee-profile/${record.employeeIdentifier}`}>
                        {text}
                    </Link>
                </h5>
            ),
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Designation",
            dataIndex: "designation",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Address",
            dataIndex: "address",
            render: (text, record, index) => (
                record.address.substring(0, 40) + "..."
            ),
            sorter: (a, b) => a.address.length - b.address.length,
        },
        {
            title: "Country",
            dataIndex: "country",
            sorter: (a, b) => a.joiningDate.length - b.joiningDate.length,
        },
        {
            title: "Action",
            render: (text, record) => (
                <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
                    <Link to={`/${role}/update-employee/${record.employeeIdentifier}`} className="dropdown-item px-2 text-success" onClick={() => {
                        handleEditClick(record.employeeIdentifier);
                    }}>
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
                        handleDeleteClick(record.employeeIdentifier);
                    }}>
                        <i className="fa fa-trash-o m-r-5" />
                    </Link>
                </div>
            ),
        },
    ];
    return (
        <>

            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Employee</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/company-dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Employees</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ml-auto">
                            <Link to={`/${role}/company-addemployee/${companyId}`} className="btn add-btn">
                                <i className="fa fa-plus" /> Add Employee
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row filter-row justify-content-end">
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus">
                            <input
                                type="text"
                                className="form-control floating"
                                onChange={(e) => setState({ ...state, search: e.target.value ? e.target.value.toLowerCase() : '' })}
                            />
                            <label className="focus-label">Search....</label>
                        </div>
                    </div>
                </div>
                {isLoading ? <Loader /> :
                    <>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <Table
                                        className="table-striped"
                                        pagination={{
                                            current: state.page,
                                            pageSize: state.pagesize,
                                            total: companyEmployeesCount,
                                            showTotal: (total, range) =>
                                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                            showSizeChanger: true,
                                            onShowSizeChange: onShowSizeChange,
                                            itemRender: itemRender,
                                            onChange: (page, pageSize) =>
                                                setState({ ...state, page, pagesize: pageSize }),
                                        }}
                                        style={{ overflowX: "auto" }}
                                        columns={columns}
                                        dataSource={companyEmployees}
                                        rowKey={(record) => record.employeeIdentifier}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

        </>
    )
}

export default CompanyEmployees;