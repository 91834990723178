import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAllCompanies } from "../../../features/companySlice";
import { emailrgx } from "../../../constant";
import ReactSelect from "react-select";
import { addClient } from "../../../features/clientSlice";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    clientName: yup.string().required("Name is required").trim(),
    clientEmail: yup
      .string()
      .required("Email is required")
      .matches(emailrgx, "Invalid Email")
      .trim(),
    clientAddress: yup.string().required("Address is required"),
    country: yup.string().required("Country is required").trim(),
    about: yup.string().required().trim(),
    companyIdentifier: yup.string().required(),
  })
  .required();

function AddClient() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companiesList } = useSelector((state) => state.company);

  const state = {
    search: "",
    page: 1,
    pagesize: 25,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getAllCompanies(state.search, state.page, state.pagesize));
  }, [dispatch, state.search, state.page, state.pagesize]);

  const companyOptions = companiesList.map((company) => {
    return {
      value: company.companyIdentifier,
      label: company.name,
    };
  });

  const onSubmit = async (data) => {
    const isCompany = false;
    const formData = new FormData();
    formData.append("ClientName", data.clientName);
    formData.append("ClientEmail", data.clientEmail);
    formData.append("ClientAddress", data.clientAddress);
    formData.append("Country", data.country);
    formData.append("About", data.about);
    formData.append("CompanyIdentifier", data.companyIdentifier);
    dispatch(addClient(formData, navigate, isCompany));
  };
  return (
    <>
      <div>
        <div className="account-wrapper">
          <h3 className="account-title">Add Client</h3>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Client Name</label>
                    <Controller
                      name="clientName"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.clientName ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.clientName?.message}</small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Client Email</label>
                    <Controller
                      name="clientEmail"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.clientEmail ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.clientEmail?.message}</small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Client Address</label>
                    <Controller
                      name="clientAddress"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.clientAddress ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.clientAddress?.message}</small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Country</label>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.country ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.country?.message}</small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>About</label>
                    <Controller
                      name="about"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.about ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.about?.message}</small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Company</label>
                    <Controller
                      name="companyIdentifier"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ReactSelect
                          options={companyOptions}
                          value={companyOptions.find(
                            (option) => option.value === value
                          )}
                          onChange={(selected) => onChange(selected?.value)}
                          isClearable
                          isSearchable
                        />
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.companyIdentifier?.message}</small>
                  </div>
                </div>
              </div>
              <div className="form-group text-center">
                <button className="btn btn-primary account-btn" type="submit">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddClient;
