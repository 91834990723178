import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import { toast } from "react-hot-toast";

const estimateSlice = createSlice({
    name: 'estimates',
    initialState: {
        allEstimates: [],
        taxTypes: [],
        estimate: null,
        isLoading: false,
    },
    reducers: {
        getAllEstimatesSuccess: (state, action) => {
            state.isLoading = false;
            state.allEstimates = action.payload.data;
        },
        getAllEstimatesFailure: (state, action) => {
            state.isLoading = false;
        },
        addEstimateSuccess: (state, action) => {
            state.isLoading = false;
        },
        addEstimateFailure: (state, action) => {
            state.isLoading = false;
        },
        updateEstimateSuccess: (state, action) => {
            state.isLoading = false;
        },
        updateEstimateFailure: (state, action) => {
            state.isLoading = false;
        },
        getEstimateSuccess: (state, action) => {
            state.estimate = action.payload.data.data;
            state.isLoading = false;
        },
        getEstimateFailure: (state, action) => {
            state.estimate = null;
            state.isLoading = false;
        },
        deleteEstimateSuccess: (state, action) => {
            let id = action.payload.id;
            state.allEstimates.data = state.allEstimates.data.filter(estimate => estimate.estimateIdentifier !== id);
            state.isLoading = false;
        },
        deleteEstimateFailure: (state, action) => {
            state.isLoading = false;
        },
        getAllTaxTypesSuccess: (state, action) => {
            state.isLoading = false;
            state.taxTypes = action.payload.data;
        },
        getAllTaxTypesFailure: (state, action) => {
            state.isLoading = false;
        },
        addEstimateSentSuccess: (state, action) => {
            state.isLoading = false;
        },
        addEstimateSentFailure: (state, action) => {
            state.isLoading = false;
            state.allEstimates = [];
        },
        setLoading: (state, action) => {
            state.isLoading = true;
        },
    }
});

export const getAllEstimates = (from, to, search, status, page, pagesize) => async (dispatch) => {
    try {
        dispatch(setLoading());
        var response = await httpHandler.get(`/api/estimate/getAllEstimates?from=${from}&to=${to}&search=${search}&status=${status}&page=${page}&pagesize=${pagesize}`);
        dispatch(getAllEstimatesSuccess(response));
    }
    catch (error) {
        dispatch(getAllEstimatesFailure(error));
    }
}

export const addEstimate = (data, isSend) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.post(`/api/estimate/createEstimate?isSend=${isSend}`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(addEstimateSuccess(response.data));
        }
        else {
            toast.error(response.data.message);
            dispatch(addEstimateFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(addEstimateFailure());
    }
}

export const updateEstimate = (data, isSend) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.post(`/api/estimate/updateEstimate?isSend=${isSend}`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(updateEstimateSuccess());
        }
        else {
            toast.error(response.data.message);
            dispatch(updateEstimateFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(updateEstimateFailure());
    }
}

export const getEstimate = (id) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.get(`/api/estimate/getEstimateById?Identifier=${id}`);
        dispatch(getEstimateSuccess(response));
    } catch (error) {
        toast.error(error.message);
        dispatch(getEstimateFailure());
    }
};

export const deleteEstimate = (id) => async (dispatch) => {
    try {
        dispatch(setLoading());
        await httpHandler.get(`/api/estimate/deleteEstimate/${id}`);
        toast.success("Deleted Successfully");
        dispatch(deleteEstimateSuccess({ id }));
    } catch (error) {
        toast.error(error.message);
        dispatch(deleteEstimateFailure(error));
    }
};

export const getAllTaxTypes = () => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.get(`/api/Tax/getalltaxes`);
        dispatch(getAllTaxTypesSuccess(response.data));
    } catch (error) {
        dispatch(getAllTaxTypesFailure());
    }
}

export const addEstimateSent = (data) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.post(`/api/estimate/createEstimate/send`, data);
        dispatch(addEstimateSentSuccess(response));
    }
    catch (error) {
        toast.error(error.message);
        dispatch(addEstimateSentFailure());
    }
}

export const { addEstimateSentSuccess, addEstimateSentFailure, getAllEstimatesSuccess, getAllEstimatesFailure, addEstimateSuccess, addEstimateFailure, updateEstimateSuccess, updateEstimateFailure,
    getEstimateSuccess, getEstimateFailure, deleteEstimateSuccess, deleteEstimateFailure, getAllTaxTypesSuccess, getAllTaxTypesFailure, setLoading } = estimateSlice.actions;
export default estimateSlice.reducer;