import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addResignation, deleteResignation, getAllResignation, getResignation, updateResignation } from '../../features/resignationSlice';
import { Table } from 'antd';
import ReactSelect from 'react-select';
import Loader from '../../components/loader/Loader';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllEmployees } from '../../features/employeeSlice';
import { getAllDepartments } from '../../features/departmentSlice';
import { confirmAlert } from 'react-confirm-alert';

// Yup Schema for form validation
const validationSchema = Yup.object().shape({
        name: Yup.string().required("Employee name is required"),
        // department: Yup.string().required("Department is required"),
        reason: Yup.string().required("Reason is required"),
        noticeDate: Yup.date().required("Notice date is required"),
        resignationDate: Yup.date().required("Resignation date is required"),
});

export default function Resignation() {
        const [showModal, setShowModal] = useState(false);
        const [showEditModal, setShowEditModal] = useState(false);
        const [selectedCompanyId, setSelectedCompanyId] = useState("");
        const { allResignation, isLoading, count, resignation } = useSelector((state) => state.resignation);
        const { employees } = useSelector((state) => state.employee);
        const { departments } = useSelector((state) => state.department)
        const dispatch = useDispatch();

        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10,
        });

        const { control, handleSubmit, formState: { errors }, reset } = useForm({
                resolver: yupResolver(validationSchema),
        });

        useEffect(() => {
                dispatch(getAllResignation());
        }, [dispatch]);

        useEffect(() => {
                dispatch(getAllEmployees(state.search, state.page, state.pagesize))
        }, [dispatch,]);

        useEffect(() => {
                dispatch(getAllDepartments(state.search, state.page, state.pagesize))
        }, [dispatch])

        // Set default values in form
        useEffect(() => {
                if (resignation) {
                        reset({
                                name: resignation?.employeeIdentifier,
                                reason: resignation?.reason,
                                noticeDate: moment(resignation?.noticeDate).format('DD MM, YYYY'),  // Date format updated
                                resignationDate: resignation?.resignationDate,  // Date format updated
                        });
                }
        }, [resignation, reset]);

        const employeeOptions = employees.map(employee => ({
                label: employee?.name,
                value: employee?.employeeIdentifier
        }));

        // const departmentsOptions = departments?.data.map(department => ({
        //         label: department?.departmentName,
        //         value: department?.departmentIdentifier
        // }));

        const handleDeleteClick = id => {
                confirmAlert({
                        title: 'Confirm to delete',
                        message: 'Are you sure to do this.',
                        buttons: [
                                {
                                        label: 'Yes',
                                        onClick: () => handleDelete(id),
                                },
                                {
                                        label: 'No',
                                },
                        ],
                });
        };

        const handleDelete = id => {
                dispatch(deleteResignation(id));
        }

        const onShowSizeChange = (current, pageSize) => {
                setState({ ...state, page: 1, pagesize: pageSize });
        };

        const itemRender = (current, type, originalElement) => {
                if (type === 'prev') {
                        return <button className="btn btn-sm btn-primary">Previous</button>;
                }
                if (type === 'next') {
                        return <button className="btn btn-sm btn-primary">Next</button>;
                }
                return originalElement;
        };

        const handleEdit = (id) => {
                dispatch(getResignation(id));
                setShowEditModal(true);
        };

        const columns = [
                {
                        title: '#',
                        render: (text, record, index) => <span>{index + 1}</span>,
                },
                {
                        title: 'Resigning Employee',
                        dataIndex: 'name',
                        render: (text) => <span>{text}</span>,
                        sorter: (a, b) => a.name.length - b.name.length
                },
                // {
                //         title: 'Department',
                //         dataIndex: 'department',
                //         render: (text) => <span>{text}</span>,
                //         sorter: (a, b) => a.text - b.text
                // },
                {
                        title: 'Reason',
                        dataIndex: 'reason',
                        render: (text) => <span>{text}</span>,
                        sorter: (a, b) => a.reason.length - b.reason.length
                },
                // {
                //         title: 'Notice Date',
                //         dataIndex: 'noticeDate',
                //         render: (text) => <span>{text}</span>,
                // },
                {
                        title: 'Resignation Date',
                        dataIndex: 'resignationDate',
                        render: (text) => <span>{text}</span>,
                },
                {
                        title: 'Action',
                        render: (text, record) => (
                                <div className="d-flex justify-content-around">
                                        <button className="dropdown-item px-2 text-success" onClick={() => {
                                                handleEdit(record.resignationIdentifier)
                                        }}>
                                                <i className="fa fa-pencil m-r-5" />
                                        </button>
                                        <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
                                                handleDeleteClick(record.resignationIdentifier);
                                        }}>
                                                <i className="fa fa-trash-o m-r-5" />
                                        </Link>
                                </div>
                        ),
                },
        ];

        const onsubmit = (data) => {
                const resignationData = {
                        // department: data?.department,
                        reason: data?.reason,
                        noticeDate: data?.noticeDate,
                        resignationDate: data?.resignationDate,
                        employeeIdentifier: data?.name,
                        resignationIdentifier: null
                };
                dispatch(addResignation(resignationData));
                setShowModal(false);
                setShowEditModal(false);
        };

        const onsubmits = (data) => {
                const resignationData = {
                        // department: data?.department,
                        reason: data?.reason,
                        noticeDate: data?.noticeDate,
                        resignationDate: data?.resignationDate,
                        employeeIdentifier: data?.name,
                        resignationIdentifier: resignation?.resignationIdentifier
                };
                dispatch(updateResignation(resignationData));
                setShowModal(false);
                setShowEditModal(false);
        };

        return (
                <div className="content container-fluid">
                        <div className="page-header">
                                <div className="row align-items-center">
                                        <div className="col">
                                                <h3 className="page-title">Resignation</h3>
                                                <ul className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                                <a href="admin-dashboard.html">Dashboard</a>
                                                        </li>
                                                        <li className="breadcrumb-item active">Resignation</li>
                                                </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                                <button className="btn add-btn" onClick={() => setShowModal(true)}>
                                                        <i className="fa fa-plus"></i> Add Resignation
                                                </button>
                                        </div>
                                </div>
                        </div>

                        <div className="row">
                                <div className="col-md-12">
                                        <div className="table-responsive">
                                                {isLoading ? (
                                                        <Loader />
                                                ) : (
                                                        <Table
                                                                className="table-striped"
                                                                columns={columns}
                                                                dataSource={allResignation}
                                                                rowKey={(record) => record?.resignationIdentifier}
                                                                pagination={{
                                                                        current: state.page,
                                                                        pageSize: state.pagesize,
                                                                        total: count,
                                                                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                                        showSizeChanger: true,
                                                                        onShowSizeChange: onShowSizeChange,
                                                                        itemRender: itemRender,
                                                                        onChange: (page, pageSize) => setState({ ...state, page, pagesize: pageSize }),
                                                                }}
                                                        />
                                                )}
                                        </div>
                                </div>
                        </div>

                        {/* create resignation */}
                        {showModal && (
                                <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="addResignationLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                        <div className="modal-header">
                                                                <h5 className="modal-title h4" id="addResignationLabel">Add Resignation</h5>
                                                        </div>
                                                        <div className="modal-body">
                                                                <form>
                                                                        <div className="form-group">
                                                                                <label>Resigning Employee</label>
                                                                                <Controller
                                                                                        name="name"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={employeeOptions}
                                                                                                        value={employeeOptions?.data?.find(option => option.value === value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                        onChange={(selected) => {
                                                                                                                const selectedValue = selected ? selected.value : "";
                                                                                                                onChange(selectedValue); // Update react-hook-form state
                                                                                                                setSelectedCompanyId(selectedValue); // Update local state
                                                                                                        }}
                                                                                                />
                                                                                        )}
                                                                                        defaultValue=""
                                                                                />
                                                                                <span className="invalid-feedback">{errors.name?.message}</span>
                                                                        </div>
                                                                        {/* <div className="form-group">
                                                                                <label>Department</label>
                                                                                <Controller
                                                                                        name="department"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={departmentsOptions}
                                                                                                        value={departmentsOptions?.data?.find(option => option.value === value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                        onChange={(selected) => {
                                                                                                                const selectedValue = selected ? selected.value : "";
                                                                                                                onChange(selectedValue); // Update react-hook-form state
                                                                                                                setSelectedCompanyId(selectedValue); // Update local state
                                                                                                        }}
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <div className="invalid-feedback">{errors.department?.message}</div>
                                                                        </div> */}
                                                                        <div className="form-group">
                                                                                <label>Reason</label>
                                                                                <Controller
                                                                                        name="reason"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <input
                                                                                                        type="text"
                                                                                                        className={`mb-0 form-control ${errors.reason ? 'is-invalid' : ''}`}
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <div className="invalid-feedback">{errors.reason?.message}</div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                                <label>Notice Date</label>
                                                                                <Controller
                                                                                        name="noticeDate"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <input
                                                                                                        type="date"
                                                                                                        className={`mb-0 form-control ${errors.noticeDate ? 'is-invalid' : ''}`}
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <div className="invalid-feedback">{errors.noticeDate?.message}</div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                                <label>Resignation Date</label>
                                                                                <Controller
                                                                                        name="resignationDate"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <input
                                                                                                        type="date"
                                                                                                        className={`mb-0 form-control ${errors.resignationDate ? 'is-invalid' : ''}`}
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <div className="invalid-feedback">{errors.resignationDate?.message}</div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                                                                        Close
                                                                                </button>
                                                                                <button type="submit" className="btn btn-primary" onClick={handleSubmit(onsubmit)}>
                                                                                        Save
                                                                                </button>
                                                                        </div>
                                                                </form>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        )}

                        {/* update resignation */}
                        {showEditModal && (
                                <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="editResignationLabel">
                                        <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                        <div className="modal-header">
                                                                <h5 className="modal-title" id="editResignationLabel">Update Resignation</h5>
                                                        </div>
                                                        <div className="modal-body">
                                                                <form onSubmit={handleSubmit(onsubmits)}>
                                                                        {/* Resigning Employee */}
                                                                        <div className="form-group">
                                                                                <label>Resigning Employee</label>
                                                                                <Controller
                                                                                        name="name"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={employeeOptions}
                                                                                                        value={employeeOptions.find(option => option.value === value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        onChange={(selected) => onChange(selected?.value || "")}
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <span className="invalid-feedback">{errors.name?.message}</span>
                                                                        </div>

                                                                        {/* Reason */}
                                                                        <div className="form-group">
                                                                                <label>Reason</label>
                                                                                <Controller
                                                                                        name="reason"
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                                <input
                                                                                                        type="text"
                                                                                                        className={`mb-0 form-control ${errors.reason ? 'is-invalid' : ''}`}
                                                                                                        {...field}
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <div className="invalid-feedback">{errors.reason?.message}</div>
                                                                        </div>

                                                                        {/* Notice Date */}
                                                                        <div className="form-group">
                                                                                <label>Notice Date</label>
                                                                                <Controller
                                                                                        name="noticeDate"
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                                <input
                                                                                                        type="date"
                                                                                                        className={`mb-0 form-control ${errors.noticeDate ? 'is-invalid' : ''}`}
                                                                                                        {...field}
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <div className="invalid-feedback">{errors.noticeDate?.message}</div>
                                                                        </div>

                                                                        {/* Resignation Date */}
                                                                        <div className="form-group">
                                                                                <label>Resignation Date</label>
                                                                                <Controller
                                                                                        name="resignationDate"
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                                <input
                                                                                                        type="date"
                                                                                                        className={`mb-0 form-control ${errors.resignationDate ? 'is-invalid' : ''}`}
                                                                                                        {...field}
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <div className="invalid-feedback">{errors.resignationDate?.message}</div>
                                                                        </div>

                                                                        <div className="modal-footer">
                                                                                <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>
                                                                                        Close
                                                                                </button>
                                                                                <button type="submit" className="btn btn-primary">
                                                                                        Save
                                                                                </button>
                                                                        </div>
                                                                </form>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        )}
                </div>
        );
}
