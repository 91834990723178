import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import {
  Priorities,
  ProjectCategory,
  Status,
  typeOptions,
} from "../../../constant";
import { useNavigate } from "react-router-dom";
import { createProject } from "../../../features/projectSlice";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { allCompaniesList } from "../../../features/companySlice";
import { allEmployeesList } from "../../../features/employeeSlice";

const schema = yup
  .object({
    projectName: yup.string().required("ProjectName is required").trim(),
    startDate: yup.date().required("Date is required"),
    endDate: yup.date().required("Date is required"),
    budget: yup.string().required("Budget is required").max(12),
    projectOwnerIdentifier: yup.string().min(6).required().trim(),
    projectDescription: yup.string().required("Description is required").trim(),
    priority: yup.string().required("Priority is required"),
    status: yup.string().required("Status is required"),
    category: yup.string().required("ProjectCategory is required"),
    notes: yup.string().required("Notes are required"),
    companyIdentifier: yup.string().required(),
  })
  .required();

function CreateProject() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyID, setCompanyID] = useState();
  const [showProjectLeader, setShowProjectLeader] = useState(false);

  const { allCompanies } = useSelector((state) => state.company);
  const { allEmployees } = useSelector((state) => state.employee);

  useEffect(() => {
    dispatch(allCompaniesList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(allEmployeesList(companyID));
  }, [dispatch, companyID]);

  const employeeOptions = allEmployees.map((employee) => {
    return {
      value: employee.employeeIdentifier,
      label: employee.name,
    };
  });

  const companyOptions = allCompanies.map((company) => {
    return {
      value: company.companyIdentifier,
      label: company.name,
    };
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const isCompany = false;
    const formData = new FormData();
    formData.append("ProjectName", data.projectName);
    formData.append("ProjectDescription", data.projectDescription);
    formData.append("ProjectOwnerIdentifier", data.projectOwnerIdentifier);
    formData.append("StartDate", data.startDate.toISOString());
    formData.append("EndDate", data.endDate.toISOString());
    formData.append("Budget", data.budget);
    formData.append("Status", data.status);
    formData.append("Priority", data.priority);
    formData.append("Category", data.category);
    formData.append("Notes", data.notes);
    formData.append("Notes", data.notes);
    formData.append("CompanyIdentifier", data.companyIdentifier);
    dispatch(createProject(formData, navigate, isCompany));
  };

  return (
    <>
      <div className="account-wrapper">
        <h3 className="account-title">Create Project</h3>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Project Name</label>
                  <Controller
                    name="projectName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control  ${
                          errors?.projectName ? "error-input" : ""
                        }`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.projectName?.message}</small>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Company</label>
                  <Controller
                    name="companyIdentifier"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        options={companyOptions}
                        value={companyOptions.find(
                          (option) => option.value === value
                        )}
                        onChange={(selected) => {
                          const selectedCompanyID = selected?.value || null;
                          onChange(selectedCompanyID);
                          setCompanyID(selectedCompanyID);
                          setShowProjectLeader(!!selectedCompanyID);
                        }}
                        isClearable
                        isSearchable
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: "#f7f7f7",
                            borderRadius: "5px",
                            border: errors?.companyIdentifier
                              ? "1px solid red"
                              : "1px solid #e3e3e3",
                            boxShadow: state.isFocused
                              ? "0 0 0 2px transparent"
                              : provided.boxShadow,
                            "&:hover": {
                              border: errors?.companyIdentifier
                                ? "1px solid red"
                                : "1px solid #B8BDC9",
                            },
                            fontSize: "15px",
                            height: "44px",
                            color: "#000",
                            outline: "none",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "#ccc",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: "#f8f8f8",
                            borderRadius: "5px",
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            padding: "0",
                          }),
                        }}
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.companyIdentifier?.message}</small>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Project Description</label>
                  <Controller
                    name="projectDescription"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <textarea
                        className={`form-control  ${
                          errors?.projectDescription ? "error-input" : ""
                        }`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.projectDescription?.message}</small>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Start Date</label>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control  ${
                          errors?.startDate ? "error-input" : ""
                        }`}
                        type="date"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.startDate?.message}</small>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>End Date</label>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control  ${
                          errors?.endDate ? "error-input" : ""
                        }`}
                        type="date"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.endDate?.message}</small>
                </div>
              </div>

              {/* Conditionally rendered Project Leader field */}
              {showProjectLeader && (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Project Leader</label>
                    <Controller
                      name="projectOwnerIdentifier"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ReactSelect
                          options={employeeOptions}
                          value={employeeOptions.find(
                            (option) => option.value === value
                          )}
                          onChange={(selected) => onChange(selected?.value)}
                          isClearable
                          isSearchable
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#f7f7f7",
                              borderRadius: "5px",
                              border: errors?.projectOwnerIdentifier
                                ? "1px solid red"
                                : "1px solid #e3e3e3",
                              boxShadow: state.isFocused
                                ? "0 0 0 2px transparent"
                                : provided.boxShadow,
                              "&:hover": {
                                border: errors?.projectOwnerIdentifier
                                  ? "1px solid red"
                                  : "1px solid #B8BDC9",
                              },
                              fontSize: "15px",
                              height: "44px",
                              color: "#000",
                              outline: "none",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#000",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "#000",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "#ccc",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: "#f8f8f8",
                              borderRadius: "5px",
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              padding: "0",
                            }),
                          }}
                        />
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.projectOwnerIdentifier?.message}</small>
                  </div>
                </div>
              )}
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Project Category</label>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        options={ProjectCategory}
                        value={ProjectCategory.find(
                          (option) => option.value === value
                        )}
                        onChange={(selected) => onChange(selected?.value)}
                        isClearable
                        isSearchable
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: "#f7f7f7",
                            borderRadius: "5px",
                            border: errors?.category
                              ? "1px solid red"
                              : "1px solid #e3e3e3",
                            boxShadow: state.isFocused
                              ? "0 0 0 2px transparent"
                              : provided.boxShadow,
                            "&:hover": {
                              border: errors?.category
                                ? "1px solid red"
                                : "1px solid #B8BDC9",
                            },
                            fontSize: "15px",
                            height: "44px",
                            color: "#000",
                            outline: "none",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "#ccc",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: "#f8f8f8",
                            borderRadius: "5px",
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            padding: "0",
                          }),
                        }}
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.category?.message}</small>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label>Budget</label>
                  <Controller
                    name="budget"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control  ${
                          errors?.budget ? "error-input" : ""
                        }`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.budget?.message}</small>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label></label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        options={typeOptions}
                        value={typeOptions.find(
                          (option) => option.value === value
                        )}
                        onChange={(selected) => onChange(selected?.value)}
                        isSearchable
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: "#f7f7f7",
                            borderRadius: "5px",
                            border: errors?.type
                              ? "1px solid red"
                              : "1px solid #e3e3e3",
                            boxShadow: state.isFocused
                              ? "0 0 0 2px transparent"
                              : provided.boxShadow,
                            "&:hover": {
                              border: errors?.type
                                ? "1px solid red"
                                : "1px solid #B8BDC9",
                            },
                            fontSize: "15px",
                            height: "44px",
                            color: "#000",
                            outline: "none",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: "#ccc",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: "#f8f8f8",
                            borderRadius: "5px",
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            padding: "0",
                          }),
                        }}
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.type?.message}</small>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Priority</label>
                  <Controller
                    name="priority"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <select
                        className={`form-control ${
                          errors?.priority ? "error-input" : ""
                        } select`}
                        value={value}
                        onChange={onChange}
                      >
                        <option value={""}>{"--Select--"}</option>
                        {Priorities.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  <small>{errors?.priority?.message}</small>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Status</label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <select
                        className={`form-control ${
                          errors?.status ? "error-input" : ""
                        } select`}
                        value={value}
                        onChange={onChange}
                      >
                        {Status.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  <small>{errors?.status?.message}</small>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Notes</label>
                  <Controller
                    name="notes"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <textarea
                        className={`form-control  ${
                          errors?.notes ? "error-input" : ""
                        }`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.notes?.message}</small>
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <button className="btn btn-primary account-btn" type="submit">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateProject;
