import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import toast from "react-hot-toast";

const leaveSlice = createSlice({
        name: "leave",
        initialState: {
                allLeave: [],
                leave: null,
                isLoading: false,
                count: null,
                isActive: null
        },
        reducers: {
                getAllLeaveSuccess: (state, action) => {
                        state.isLoading = false;
                        state.allLeave = action.payload;
                },
                getAllLeaveFailure: (state, action) => {
                        state.isLoading = false;
                        state.allLeave = [];
                },
                addLeaveSuccess: (state, action) => {
                        state.isLoading = false;
                },
                addLeaveFailure: (state, action) => {
                        state.isLoading = false;
                },
                getLeaveSuccess: (state, action) => {
                        state.isLoading = false;
                        state.leave = action.payload
                },
                getLeaveFailure: (state, action) => {
                        state.isLoading = false;
                },
                updateLeaveSuccess: (state, action) => {
                        state.isLoading = false;
                },
                updateLeaveFailure: (state, action) => {
                        state.isLoading = false;
                },
                leaveApprovelSuccess: (state, action) => {
                        state.isLoading = false;
                        const { isActive, leaveIdentifier } = action.payload;
                        const leave = state.allLeave.find(leave => leave.leaveIdentifier === leaveIdentifier);
                        if (leave) {
                                leave.isActive = isActive;
                        }
                },
                leaveApprovelFailure: (state, action) => {
                        state.isLoading = false;
                },
                deleteLeaveSuccess: (state, action) => {
                        state.isLoading = false;
                },
                deleteLeaveFailure: (state, action) => {
                        state.isLoading = false;
                },
                setLoading: (state, action) => {
                        state.isLoading = true;
                }
        }
});

// get all leave
export const getAllLeave = () => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/leave`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(getAllLeaveSuccess(response.data.data))
                }
                else {
                        toast.error(response.data.message)
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(getAllLeaveFailure(error.message))
        }
}

// create leave 
export const addLeave = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/leave`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(addLeaveSuccess());
                } else {
                        dispatch(addLeaveFailure())
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(addLeaveFailure())
        }
}

// get leave by id
export const getLeaveById = (id) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/leave/id?Identifier=${id}`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(getLeaveSuccess(response.data.data))
                } else {
                        dispatch(getLeaveFailure())
                }
        }
        catch (error) {
                toast.success(error.message);
                dispatch(getLeaveFailure(error.message))
        }
}

// update leave
export const updateLeave = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/leave/updateLeave`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(updateLeaveSuccess())
                } else {
                        dispatch(updateLeaveFailure(response.data.message));
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(updateLeaveFailure(error.message));
        }
}

// delete leave 
export const deleteLeave = (leaveIdentifier) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/leave/deleteLeave?Identifier=${leaveIdentifier}`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(deleteLeaveSuccess());
                } else {
                        dispatch(deleteLeaveFailure());
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(deleteLeaveFailure(error.message));
        }
}


// leave approval
export const leaveApprovelUpdate = (isActive, companyID, leaveIdentifier) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/leave/approveLeave?LeaveIdentifier=${leaveIdentifier}&CompanyIdentifier=${companyID}&Status=${isActive}`);
                if (response.data.success) {
                        toast.success("Status Successfully Changed");
                        dispatch(leaveApprovelSuccess({ isActive, companyID, leaveIdentifier }));
                } else {
                        dispatch(leaveApprovelFailure());
                }
        } catch (error) {
                toast.error(error.message);
                dispatch(leaveApprovelFailure(error.message));
        }
};

export const {
        getAllLeaveSuccess, getAllLeaveFailure,
        addLeaveSuccess, addLeaveFailure,
        leaveApprovelSuccess, leaveApprovelFailure,
        getLeaveSuccess, getLeaveFailure,
        updateLeaveSuccess, updateLeaveFailure,
        deleteLeaveSuccess, deleteLeaveFailure,
        setLoading
} = leaveSlice.actions;
export default leaveSlice.reducer;