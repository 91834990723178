import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getEmployee } from "../../../features/employeeSlice";
import Loader from "../../../components/loader/Loader";

function CompanyEmployeeProfile() {
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const { isLoading, employee } = useSelector((state) => state.employee);

  useEffect(() => {
    const reloadData = () => {
      dispatch(getEmployee(employeeId));
    };
    reloadData();
  }, [dispatch, employeeId]);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      {employee ? (
        <>
          <Helmet>
            <title>Company Profile - CHS Admin Template</title>
            <meta name="description" content="Reactify Blank Page" />
          </Helmet>
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Profile</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Employees</Link>
                    </li>
                    <li className="breadcrumb-item active">{employee.name}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-view">
                      <div className="profile-img-wrap">
                        <div className="profile-img">
                          <Link to="#">
                            <img alt="profile" src={employee.userProfile} />
                          </Link>
                        </div>
                      </div>
                      <div className="profile-basic">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="profile-info-left">
                              <h3 className="user-name m-t-0 mb-0">
                                {employee.name}
                              </h3>
                              <small className="text-muted">
                                {employee.designation}
                              </small>
                              <div className="staff-id">
                                Employee ID :{" "}
                                {employee.IdNumber ? employee.IdNumber : "N/A"}
                              </div>
                              <div className="small doj text-muted">
                                Date of Join :{" "}
                                {formatDate(employee.joiningDate)}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <ul className="personal-info">
                              <li>
                                <div className="title">Phone:</div>
                                <div className="text">{employee.mobile}</div>
                              </li>
                              <li>
                                <div className="title">Email:</div>
                                <div className="text">{employee.email}</div>
                              </li>
                              <li>
                                <div className="title">Birthday:</div>
                                <div className="text">
                                  {employee.DOB ? employee.DOB : "N/A"}
                                </div>
                              </li>
                              <li>
                                <div className="title">Address:</div>
                                <div className="text">{employee.address}</div>
                              </li>
                              <li>
                                <div className="title">Gender:</div>
                                <div className="text">
                                  {employee.gender ? employee.gender : "N/A"}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="pro-edit">
                        <Link
                          className="edit-icon"
                          to={`/company/update-employee/${employeeId}`}
                        >
                          <i className="fa fa-pencil" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
}

export default CompanyEmployeeProfile;
