import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  addTermination,
  deteleTermination,
  getAllTermination,
  getTermination,
  updateTermination,
} from "../../features/terminationSlice";
import { useSelector } from "react-redux";
import { getAllEmployees } from "../../features/employeeSlice";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { confirmAlert } from "react-confirm-alert";

const terminationType = [
  {
    label: "Others",
    value: 2,
  },
  {
    label: "Misconduct",
    value: 1,
  },
];

export default function AllTerminate() {
  const dispatch = useDispatch();
  const { allTermination, count, termination } = useSelector(
    (state) => state.termination
  );
  const { employees } = useSelector((state) => state.employee);
  const [selectedEmployee, setSelectedEmpolyee] = useState(null);

  const { control, handleSubmit, setValue } = useForm({});

  const [state, setState] = useState({
    search: "",
    page: 1,
    pagesize: 100,
  });

  useEffect(() => {
    if (termination) {
      setValue("employeeIdentifier", termination?.employeeIdentifier);
      setValue(
        "terminationType",
        termination?.terminationType?.terminationType
      );
      setValue("noticeDate", termination?.noticeDate);
      setValue("terminationDate", termination?.terminationDate);
      setValue("reason", termination?.reason);
    }
  }, [termination, setValue]);

  useEffect(() => {
    dispatch(getAllTermination());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllEmployees(state.search, state.page, state.pagesize));
  }, [dispatch, state.search, state.page, state.pagesize]);

  const employeeOptions = employees.map((employee) => ({
    value: employee.employeeIdentifier,
    label: employee.name,
    designation: employee.designation,
  }));

  const terminationTypeOption = terminationType.map((type) => ({
    value: type.value,
    label: type.label,
  }));

  const onSubmit = (formData) => {
    const data = {
      employeeIdentifier: formData.employeeIdentifier,
      terminationType: formData.terminationType,
      noticeDate: formData.noticeDate,
      terminationDate: formData.terminationDate,
      terminationIdentifier: null,
      reason: formData.reason,
    };
    dispatch(addTermination(data));
  };

  const onSubmits = (formData) => {
    const data = {
      department: formData.department,
      noticeDate: formData.noticeDate,
      terminationDate: formData.terminationDate,
      reason: formData.reason,
      employeeIdentifier: formData.employeeIdentifier,
      terminationType: formData.terminationType,
      terminationIdentifier: termination?.terminationIdentifier,
    };
    dispatch(updateTermination(data));
  };

  const handleDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDelete = (id) => {
    dispatch(deteleTermination(id));
  };

  const onShowSizeChange = (current, pageSize) => {
    setState({ ...state, page: 1, pagesize: pageSize });
  };

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <button className="btn btn-sm btn-primary">Previous</button>;
    }
    if (type === "next") {
      return <button className="btn btn-sm btn-primary">Next</button>;
    }
    return originalElement;
  };

  const columns = [
    {
      title: "Terminated Employee",
      dataIndex: "name",
      render: (text, record) => <span>{record.name}</span>,
      sorter: (a, b) => a.taxName.length - b.taxName.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text, record) => <span>{record.department}</span>,
      sorter: (a, b) => a.taxRate - b.taxRate,
    },
    {
      title: "Termination Type",
      dataIndex: "terminationType",
      render: (text, record) => <span>{terminationType?.value}</span>,
      sorter: (a, b) => a.taxRate - b.taxRate,
    },
    {
      title: "Termination Date",
      dataIndex: "terminationDate",
      render: (text, record) => <span>{record.terminationDate}</span>,
      sorter: (a, b) => a.taxRate - b.taxRate,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      render: (text, record) => <span>{record.reason}</span>,
      sorter: (a, b) => a.taxRate - b.taxRate,
    },
    {
      title: "Notice Date",
      dataIndex: "noticeDate",
      render: (text, record) => <span>{record.noticeDate}</span>,
      sorter: (a, b) => a.taxRate - b.taxRate,
    },
    {
      title: "Action",
      render: (text, record) => (
        <div
          className="d-flex justify-content-around"
          data-popper-placement="bottom-end"
        >
          <button
            className="dropdown-item px-2 text-success"
            data-bs-toggle="modal"
            data-bs-target="#edit_termination"
            onClick={() => {
              dispatch(getTermination(record.terminationIdentifier));
            }}
          >
            <i className="fa fa-pencil mr-2" />
          </button>
          <Link
            className="dropdown-item px-2 text-danger"
            to={"#"}
            onClick={() => {
              handleDeleteClick(record.terminationIdentifier);
            }}
          >
            <i className="fa fa-trash-o m-r-5" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">Termination</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="admin-dashboard.html">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">Termination</li>
            </ul>
          </div>
          <div className="col-auto float-end ms-auto">
            <button
              className="btn add-btn"
              data-bs-toggle="modal"
              data-bs-target="#add_termination"
            >
              <i className="fa fa-plus"></i> Add Termination
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <Table
              columns={columns}
              dataSource={allTermination}
              rowKey={(record) => record?.terminationIdentifier}
              pagination={{
                current: state.page,
                pageSize: state.pagesize,
                total: count,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
                onChange: (page, pageSize) =>
                  setState({ ...state, page, pagesize: pageSize }),
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="add_termination"
        tabIndex="-1"
        aria-labelledby="addTerminationLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addTerminationLabel">
                Add Termination
              </h5>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Terminated Employee
                  </label>
                  <Controller
                    name="employeeIdentifier"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        options={employeeOptions}
                        value={employeeOptions.find(
                          (option) => option.value === value
                        )}
                        onChange={(selected) => {
                          onChange(selected?.value);
                          setSelectedEmpolyee(selected?.designation);
                        }}
                        isClearable
                        isSearchable
                        className="company-box"
                      />
                    )}
                    defaultValue=""
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="designation" className="form-label">
                    Department
                  </label>
                  <Controller
                    name="designation"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control mb-0`}
                        type="text"
                        value={value}
                        onChange={() => onChange(selectedEmployee)}
                        autoComplete="false"
                      />
                    )}
                    defaultValue=""
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="terminationType" className="form-label">
                    Termination Type
                  </label>
                  <Controller
                    name="terminationType"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        options={terminationTypeOption}
                        value={terminationTypeOption.find(
                          (option) => option.value === value
                        )}
                        onChange={(selected) => {
                          onChange([selected]);
                          console.log(selected);
                        }}
                        isClearable
                        isSearchable
                        className="company-box mb-3"
                      />
                    )}
                    defaultValue=""
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="terminationDate" className="form-label">
                    Termination Date
                  </label>
                  <Controller
                    name="terminationDate"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control mb-0`}
                        type="date"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="noticeDate" className="form-label">
                    Notice Date
                  </label>
                  <Controller
                    name="noticeDate"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control mb-0`}
                        type="date"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="reason" className="form-label">
                    Reason
                  </label>
                  <Controller
                    name="reason"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control mb-0`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="edit_termination"
        tabIndex="-1"
        aria-labelledby="editTerminationLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editTerminationLabel">
                Edit Termination
              </h5>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Terminated Employee
                  </label>
                  <Controller
                    name="employeeIdentifier"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        options={employeeOptions}
                        value={employeeOptions.find(
                          (option) => option.value === value
                        )}
                        onChange={(selected) => {
                          onChange(selected?.value);
                          setSelectedEmpolyee(selected?.designation);
                        }}
                        isClearable
                        isSearchable
                        className="company-box"
                        isDisabled
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="department" className="form-label">
                    Department
                  </label>
                  <Controller
                    name="department"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control mb-0`}
                        type="text"
                        value={value}
                        onChange={() => onChange(selectedEmployee)}
                        autoComplete="false"
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="employeeIdentifier" className="form-label">
                    Termination Type
                  </label>
                  <Controller
                    name="terminationType"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ReactSelect
                        options={terminationTypeOption}
                        value={terminationTypeOption.find(
                          (option) => option.value === value
                        )}
                        onChange={(selected) => {
                          onChange([selected]);
                        }}
                        isClearable
                        isSearchable
                        className="company-box mb-3"
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="terminationDate" className="form-label">
                    Termination Date
                  </label>
                  <Controller
                    name="terminationDate"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control mb-0`}
                        type="date"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="noticeDate" className="form-label">
                    Notice Date
                  </label>
                  <Controller
                    name="noticeDate"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control mb-0`}
                        type="date"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="reason" className="form-label">
                    Reason
                  </label>
                  <Controller
                    name="reason"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control mb-0`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit(onSubmits)}
                  >
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
