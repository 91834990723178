import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import * as yup from "yup";
import Loader from "../../../components/loader/Loader";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getDepartment,
  updateCompanyDepartment,
} from "../../../features/departmentSlice";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { allEmployeesList } from "../../../features/employeeSlice";

const schema = yup
  .object({
    departmentName: yup.string().required("Name is required").trim(),
    departmentHead: yup.string().required("Department Head is required"),
    departmentDescription: yup
      .string()
      .required("Discription is required")
      .trim(),
  })
  .required();

function CompanyUpdateDepartment() {
  const { departmentID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, department } = useSelector((state) => state.department);
  const { isLoading: employeeLoading, allEmployees } = useSelector(
    (state) => state.employee
  );

  useEffect(() => {
    dispatch(getDepartment(departmentID));
  }, [dispatch, departmentID]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const requestData = {
      departmentName: data.departmentName,
      departmentHead: data.departmentHead,
      departmentDescription: data.departmentDescription,
      companyID: department.company,
      departmentIdentifier: departmentID,
    };
    dispatch(updateCompanyDepartment(requestData, navigate));
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch(allEmployeesList(user?.companyIdentifier));
  }, [dispatch]);

  const employeeOptions = allEmployees.map((employee) => {
    return {
      value: employee.employeeIdentifier,
      label: employee.name,
    };
  });

  return (
    <>
      {isLoading || employeeLoading ? (
        <Loader />
      ) : (
        <div className="account-content">
          <div className="container">
            <div>
              <div className="account-wrapper">
                <h3 className="account-title">Update Department</h3>
                <div className="modal-body">
                  {department ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Department Name</label>
                            <Controller
                              name="departmentName"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control mb-0 ${
                                    errors?.departmentName
                                      ? "error-input mb-0"
                                      : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                />
                              )}
                              defaultValue={department?.departmentName}
                            />
                            <small>{errors?.departmentName?.message}</small>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Department Head</label>
                            <Controller
                              name="departmentHead"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <ReactSelect
                                  options={employeeOptions}
                                  value={employeeOptions.find(
                                    (option) => option.value === value
                                  )}
                                  onChange={(selected) =>
                                    onChange(selected?.value)
                                  }
                                  isClearable
                                  isSearchable
                                  className="company-box"
                                />
                              )}
                              defaultValue={department?.departmentHead}
                            />
                            <small>{errors?.departmentHead?.message}</small>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Description</label>
                            <Controller
                              name="departmentDescription"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control mb-0 ${
                                    errors?.departmentDescription
                                      ? "error-input mb-0"
                                      : ""
                                  }`}
                                  type="textarea"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                />
                              )}
                              defaultValue={department?.departmentDescription}
                            />
                            <small>
                              {errors?.departmentDescription?.message}
                            </small>
                          </div>
                        </div>
                        <div className="form-group text-center">
                          <Link
                            to={`/company/company-departments/${department?.company}`}
                            className="btn btn-info text-white mr-3 back-list-btn"
                            type="button"
                          >
                            Back To List
                          </Link>
                          <button
                            className="btn btn-primary account-btn"
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <p>No department data found.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CompanyUpdateDepartment;
