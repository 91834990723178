import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addPolicy } from "../../../features/policySlice";

// Define the Yup schema
const schema = Yup.object({
        policyIdentifier: Yup.string()
                // .required('Policy name is required'),
                .nullable(),
        departmentIdentifier: Yup.string()
                // .required('Department is required'),
                .nullable(),
        description: Yup.string()
                // .required('Description is required')
                // .min(3, 'Description must be at least 10 characters long'),
                .nullable(),
        companyIdentifier: Yup.string()
                // .required('Company is required'),
                .nullable(),
        uploadPolicy: Yup.mixed()
                .nullable()
                .test('fileType', 'Only PDF files are allowed', (value) => {
                        // Check if value is an array and has at least one file
                        if (!value || (Array.isArray(value) && value.length === 0)) return true; // Allow empty file selection
                        const allowedTypes = ['application/pdf'];
                        return allowedTypes.includes(value[0]?.type); // Use optional chaining
                })
}).required();

const companiesList = [
        { companyIdentifier: 'Google', name: "Google" },
        { companyIdentifier: 'Microsoft', name: "Microsoft" },
];

const departmentList = [
        { departmentIdentifier: 'ITT', name: "Devex" },
        { departmentIdentifier: 'HR', name: "Human Resources" },
];

export default function CreatePolicies() {
        const dispatch = useDispatch()
        const [selectedImage, setSelectImage] = useState([]);
        const [selectedCompanyId, setSelectedCompanyId] = useState("");
        const role = JSON.parse(localStorage.getItem('user'));

        const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm({
                resolver: yupResolver(schema),
        });

        const companyOptions = companiesList.map(company => ({
                value: company.name,
                label: company.companyIdentifier
        }));

        const departmentOptions = departmentList.map(company => ({
                value: company.companyIdentifier,
                label: company.name
        }));

        const handleFileChange = (e, onChange) => {
                const files = Array.from(e.target.files);
                setSelectImage(prevImages => [...prevImages, ...files]);
                onChange(files); // Set value in the form state using onChange from Controller
        };

        const onsubmit = (data) => {
                const formData = new FormData();
                formData.append('policyIdentifier', data?.policyIdentifier);
                formData.append('companyIdentifier', data?.companyIdentifier);
                formData.append('departmentIdentifier', data?.departmentIdentifier);
                formData.append('description', data?.description);
                formData.append('uploadPolicy', data?.uploadPolicy);

                dispatch(addPolicy(formData))
        };

        return (
                <>
                        <div
                                className="modal fade"
                                id="add_policy"
                                tabIndex="-1"
                                aria-labelledby="addPolicyLabel"
                                aria-hidden="true"
                        >
                                <div className="modal-dialog">
                                        <div className="modal-content">
                                                <div className="modal-header">
                                                        <h5 className="modal-title" id="addPolicyLabel">Add Policy</h5>
                                                        <button
                                                                type="button"
                                                                className="btn-close"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                        ></button>
                                                </div>
                                                <div className="modal-body">
                                                        <form onSubmit={handleSubmit(onsubmit)}>
                                                                {role?.role === 'admin' ?
                                                                        <div className="mb-3">
                                                                                <label htmlFor="companyIdentifier" className="form-label">
                                                                                        Company Name
                                                                                </label>
                                                                                <Controller
                                                                                        name="companyIdentifier"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={companyOptions}
                                                                                                        value={companyOptions.find(option => option.value === value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                        onChange={(selected) => {
                                                                                                                const selectedValue = selected ? selected.value : "";
                                                                                                                onChange(selectedValue);
                                                                                                                setSelectedCompanyId(selectedValue);
                                                                                                        }}
                                                                                                />
                                                                                        )}
                                                                                        defaultValue=""
                                                                                />
                                                                                {errors.companyIdentifier && <div className="invalid-feedback">{errors.companyIdentifier.message}</div>}
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                <div className="mb-3">
                                                                        <label htmlFor="policyName" className="form-label">
                                                                                Policy Name
                                                                        </label>
                                                                        <Controller
                                                                                name="policyIdentifier"
                                                                                control={control}
                                                                                defaultValue=""
                                                                                render={({ field }) => (
                                                                                        <input
                                                                                                type="text"
                                                                                                className={`form-control ${errors.policyIdentifier ? 'is-invalid' : ''}`}
                                                                                                id="policyIdentifier"
                                                                                                placeholder="Enter policy name"
                                                                                                {...field}
                                                                                        />
                                                                                )}
                                                                        />
                                                                        {errors.policyIdentifier && <div className="invalid-feedback">{errors.policyIdentifier.message}</div>}
                                                                </div>

                                                                <div className="mb-3">
                                                                        <label htmlFor="department" className="form-label">
                                                                                Department
                                                                        </label>
                                                                        <Controller
                                                                                name="departmentIdentifier"
                                                                                control={control}
                                                                                render={({ field: { value, onChange } }) => (
                                                                                        <ReactSelect
                                                                                                options={departmentOptions}
                                                                                                value={departmentOptions.find(option => option.value === value)}
                                                                                                isClearable
                                                                                                isSearchable
                                                                                                className="company-box"
                                                                                                onChange={(selected) => onChange(selected?.value)}
                                                                                        />
                                                                                )}
                                                                                defaultValue=""
                                                                        />
                                                                        {errors.departmentIdentifier && <div className="invalid-feedback">{errors.departmentIdentifier.message}</div>}
                                                                </div>

                                                                <div className="mb-3">
                                                                        <label htmlFor="description" className="form-label">
                                                                                Description
                                                                        </label>
                                                                        <Controller
                                                                                name="description"
                                                                                control={control}
                                                                                defaultValue=""
                                                                                render={({ field }) => (
                                                                                        <textarea
                                                                                                className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                                                                                id="description"
                                                                                                placeholder="Enter description"
                                                                                                {...field}
                                                                                        ></textarea>
                                                                                )}
                                                                        />
                                                                        {errors.description && <div className="invalid-feedback">{errors.description.message}</div>}
                                                                </div>

                                                                <div className="mb-3">
                                                                        <label htmlFor="upload" className="form-label">
                                                                                Upload Policy
                                                                        </label>
                                                                        <Controller
                                                                                name="uploadPolicy"
                                                                                control={control}
                                                                                render={({ field: { onChange, value } }) => (
                                                                                        <input
                                                                                                className={`form-control ${errors.uploadPolicy ? 'is-invalid' : ''}`}
                                                                                                type="file"
                                                                                                multiple
                                                                                                onChange={(e) => handleFileChange(e, onChange)}
                                                                                        />
                                                                                )}
                                                                        />
                                                                        {errors.uploadPolicy && <div className="invalid-feedback">{errors.uploadPolicy.message}</div>}
                                                                </div>
                                                                <div className="mb-3">
                                                                        <ul className="list-unstyled">
                                                                                {selectedImage.map((image, index) => (
                                                                                        <li className="d-flex align-items-center mb-2 position-relative" key={index}>
                                                                                                <img src={URL.createObjectURL(image)} alt="attachment" className="me-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                                                                                <span style={{ position: 'absolute', top: 0, }} className="btn btn-danger btn-sm" onClick={() => {
                                                                                                        setSelectImage(prevImages => prevImages.filter((_, i) => i !== index));
                                                                                                }}><i className="fa fa-close"></i></span>
                                                                                        </li>
                                                                                ))}
                                                                        </ul>
                                                                </div>
                                                        </form>
                                                </div>

                                                <div className="modal-footer">
                                                        <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                data-bs-dismiss="modal"
                                                        >
                                                                Close
                                                        </button>
                                                        <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                        >
                                                                Save Policy
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        )
}
