import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { deletePayRoll, getAllPayRollItem, getPayRoll } from '../../../features/payRollItemSlice';
import CreatePayrollItems from './CreatePayrollItems';
import EditPayrollItem from './EditPayrollItem';
import CreateOverTime from './CreateOverTime';
import CreateAddition from './CreateAddition';
import EditOverTime from './EditOverTime';
import EditAddtion from './EditAddtion';

export default function PayrollItems() {
        const dispatch = useDispatch();
        const { count, allPayRollItem } = useSelector((state) => state.payrollItem);
        const [showModel, setShowModel] = useState(false);
        const [showOverTimeModel, setShowOverTimeModel] = useState(false);
        const [showEditOverTimeModel, setShowEditOverTimeModel] = useState(false);
        const [showEditModel, setShowEditModel] = useState(false);
        const [showAddtionModel, setShowAddtionModel] = useState(false);
        const [showEditAddtionModel, setShowEditAddtionModel] = useState(false);
        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10,
                type: 'payRoll'
        });

        // deductions
        const openModal = () => setShowModel(true);
        const closeModal = () => setShowModel(false);
        const openEditModal = () => setShowEditModel(true);
        const closeEditModal = () => setShowEditModel(false);

        // overtime
        const openOverTimeModal = () => setShowOverTimeModel(true);
        const closeOverTimeModal = () => setShowOverTimeModel(false);
        const openEditOverTimeModal = () => setShowEditOverTimeModel(true);
        const closeEditOverTimeModal = () => setShowEditOverTimeModel(false);

        // addtion
        const openAddtionModal = () => setShowAddtionModel(true);
        const closeAddtionModal = () => setShowAddtionModel(false);
        const openEditAddtionModal = () => setShowAddtionModel(true);
        const closeEditAddtionModal = () => setShowAddtionModel(false);

        useEffect(() => {
                dispatch(getAllPayRollItem(state.type))
        }, [dispatch]);

        const handleDeleteClick = id => {
                confirmAlert({
                        title: 'Confirm to delete',
                        message: 'Are you sure to do this.',
                        buttons: [
                                {
                                        label: 'Yes',
                                        onClick: () => handleDelete(id),
                                },
                                {
                                        label: 'No',
                                },
                        ],
                });
        };

        const handleDelete = id => {
                dispatch(deletePayRoll(id));
        }

        const handleStatusChange = (newStatus, id) => {
                // dispatch(statusUpdate(newStatus, id));
        };

        const handleTabChange = (type) => {
                setState({ ...state, type, page: 1 });
        }

        const onShowSizeChange = (current, pageSize) => {
                setState({ ...state, page: 1, pagesize: pageSize });
        };

        const itemRender = (current, type, originalElement) => {
                if (type === 'prev') {
                        return <button className="btn btn-sm btn-primary">Previous</button>;
                }
                if (type === 'next') {
                        return <button className="btn btn-sm btn-primary">Next</button>;
                }
                return originalElement;
        };


        const columns = [
                {
                        title: '#',
                        render: (text, record, index) => (
                                <span>{index + 1}</span>
                        ),
                        sorter: (a, b) => a.id - b.id
                },
                {
                        title: 'Name',
                        dataIndex: 'name',
                        render: (text, record) => <span>{record.name}</span>,
                        sorter: (a, b) => a.name.length - b.name.length
                },
                {
                        title: 'Default/Unit Amount',
                        dataIndex: 'unitPrice',
                        render: (text, record) => <span>{record.unitPrice}</span>,
                        sorter: (a, b) => a.unitPrice - b.unitPrice
                },
                {
                        title: "Action",
                        render: (text, record) => (
                                <div
                                        className="d-flex justify-content-around"
                                        data-popper-placement="bottom-end"
                                >
                                        <Link
                                                className="dropdown-item text-success"
                                                onClick={() => {
                                                        dispatch(getPayRoll(record.payrollIdentifier))
                                                        setShowEditModel(true)
                                                }}
                                        >
                                                <i className="fa fa-pencil" />
                                        </Link>
                                        <Link
                                                className="dropdown-item text-danger"
                                                to={"#"}
                                                onClick={() => {
                                                        handleDeleteClick(record.payrollIdentifier);
                                                }}
                                        >
                                                <i className="fa fa-trash-o m-r-5" />
                                        </Link>
                                </div>
                        ),
                },
        ];

        const tableAdditions = [
                {
                        title: '#',
                        render: (text, record, index) => (
                                <span>{index + 1}</span>
                        ),
                        sorter: (a, b) => a.id - b.id
                },
                {
                        title: 'Name',
                        dataIndex: 'name',
                        render: (text, record) => <span>{record.name}</span>,
                        sorter: (a, b) => a.name.length - b.name.length
                },
                {
                        title: 'Category',
                        dataIndex: 'category',
                        render: (text, record) => <span>{record.unitPrice}</span>,
                        sorter: (a, b) => a.unitPrice - b.unitPrice
                },
                {
                        title: 'Default/ Unit Amount',
                        dataIndex: 'unitPrice',
                        render: (text, record) => <span>{record.unitPrice}</span>,
                        sorter: (a, b) => a.unitPrice - b.unitPrice
                },
                {
                        title: "Action",
                        render: (text, record) => (
                                <div
                                        className="d-flex justify-content-around"
                                        data-popper-placement="bottom-end"
                                >
                                        <Link
                                                className="dropdown-item text-success"
                                                onClick={() => {
                                                        dispatch(getPayRoll(record.payrollIdentifier))
                                                        setShowEditAddtionModel(true)
                                                }}
                                        >
                                                <i className="fa fa-pencil" />
                                        </Link>
                                        <Link
                                                className="dropdown-item text-danger"
                                                to={"#"}
                                                onClick={() => {
                                                        handleDeleteClick(record.payrollIdentifier);
                                                }}
                                        >
                                                <i className="fa fa-trash-o m-r-5" />
                                        </Link>
                                </div>
                        ),
                },
        ];

        const overTimeForTable = [
                {
                        title: '#',
                        render: (text, record, index) => (
                                <span>{index + 1}</span>
                        ),
                        sorter: (a, b) => a.id - b.id
                },
                {
                        title: 'Name',
                        dataIndex: 'name',
                        render: (text, record) => <span>{record.name}</span>,
                        sorter: (a, b) => a.name.length - b.name.length
                },
                {
                        title: 'Rate',
                        dataIndex: 'unitPrice',
                        render: (text, record) => <span>{record.unitPrice}</span>,
                        sorter: (a, b) => a.unitPrice - b.unitPrice
                },
                {
                        title: "Action",
                        render: (text, record) => (
                                <div
                                        className="d-flex justify-content-around"
                                        data-popper-placement="bottom-end"
                                >
                                        <Link
                                                className="dropdown-item text-success"
                                                onClick={() => {
                                                        dispatch(getPayRoll(record.payrollIdentifier))
                                                        openEditOverTimeModal(true)
                                                }}
                                        >
                                                <i className="fa fa-pencil" />
                                        </Link>
                                        <Link
                                                className="dropdown-item text-danger"
                                                to={"#"}
                                                onClick={() => {
                                                        handleDeleteClick(record.payrollIdentifier);
                                                }}
                                        >
                                                <i className="fa fa-trash-o m-r-5" />
                                        </Link>
                                </div>
                        ),
                },
        ];

        return (
                <div className="content container-fluid">

                        <div className="page-header">
                                <div className="row align-items-center">
                                        <div className="col">
                                                <h3 className="page-title">Payroll Items</h3>
                                                <ul className="breadcrumb">
                                                        <li className="breadcrumb-item"><a href="admin-dashboard.html">Dashboard</a></li>
                                                        <li className="breadcrumb-item active">Payroll Items</li>
                                                </ul>
                                        </div>
                                </div>
                        </div>
                        <div className="page-menu">
                                <div className="row">
                                        <div className="col-sm-12">
                                                <ul className="nav nav-tabs nav-tabs-bottom">
                                                        <li className="nav-item">
                                                                <a
                                                                        className={`nav-link ${state.type === 'payRoll' ? 'active' : ''}`}
                                                                        data-bs-toggle="tab"
                                                                        onClick={() => {
                                                                                handleTabChange('payRoll')
                                                                                dispatch(getAllPayRollItem('payRoll'))
                                                                        }
                                                                        }
                                                                        href="#tab_additions"
                                                                >
                                                                        Additions
                                                                </a>
                                                        </li>
                                                        <li className="nav-item">
                                                                <a
                                                                        className={`nav-link ${state.type === 'overTime' ? 'active' : ''}`}
                                                                        onClick={() => {
                                                                                handleTabChange('overTime')
                                                                                dispatch(getAllPayRollItem('OverTime'))
                                                                        }
                                                                        }
                                                                        href="#tab_overtime"
                                                                        data-bs-toggle="tab"
                                                                >
                                                                        Overtime
                                                                </a>
                                                        </li>
                                                        <li className="nav-item">
                                                                <a
                                                                        className={`nav-link ${state.type === 'deductions' ? 'active' : ''}`}
                                                                        onClick={() => {
                                                                                handleTabChange('deductions')
                                                                                dispatch(getAllPayRollItem('payRoll'))

                                                                        }
                                                                        }
                                                                        href="#tab_deductions"
                                                                        data-bs-toggle="tab"
                                                                >
                                                                        Deductions
                                                                </a>
                                                        </li>
                                                </ul>
                                        </div>
                                </div >
                        </div >
                        <div className="tab-content">
                                <div className={`tab-pane ${state.type === 'payRoll' ? 'show active' : ''}`} id="tab_additions">
                                        <div className="text-end mb-4 clearfix">
                                                <button className="btn btn-primary add-btn" type="button" onClick={openAddtionModal} ><i className="fa fa-plus"></i> Add Addition</button>
                                        </div>
                                        <div className="payroll-table card">
                                                <div className="table-responsive">
                                                        <Table
                                                                columns={tableAdditions}
                                                                dataSource={allPayRollItem}
                                                                rowKey={(record) => record?.payrollIdentifier}
                                                                pagination={{
                                                                        current: state.page,
                                                                        pageSize: state.pagesize,
                                                                        total: count,
                                                                        showTotal: (total, range) =>
                                                                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                                        showSizeChanger: true,
                                                                        onShowSizeChange: onShowSizeChange,
                                                                        itemRender: itemRender,
                                                                        onChange: (page, pageSize) =>
                                                                                setState({ ...state, page, pagesize: pageSize }),
                                                                }}
                                                        />
                                                </div>
                                        </div>
                                </div>
                                <div className={`tab-pane ${state.type === 'overtime' ? 'show active' : ''}`} id="tab_overtime">

                                        <div className="text-end mb-4 clearfix">
                                                <button
                                                        className="btn btn-primary add-btn"
                                                        type="button"
                                                        onClick={openOverTimeModal}>
                                                        <i className="fa fa-plus"></i>
                                                        Add Overtime</button>
                                        </div>
                                        <div className="payroll-table card">
                                                <div className="table-responsive">
                                                        <Table
                                                                columns={overTimeForTable}
                                                                dataSource={allPayRollItem}
                                                                rowKey={(record) => record?.payrollIdentifier}
                                                                pagination={{
                                                                        current: state.page,
                                                                        pageSize: state.pagesize,
                                                                        total: count,
                                                                        showTotal: (total, range) =>
                                                                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                                        showSizeChanger: true,
                                                                        onShowSizeChange: onShowSizeChange,
                                                                        itemRender: itemRender,
                                                                        onChange: (page, pageSize) =>
                                                                                setState({ ...state, page, pagesize: pageSize }),
                                                                }}
                                                        />
                                                </div>
                                        </div>
                                </div>
                                <div className={`tab-pane ${state.type === 'deductions' ? 'show active' : ''}`} id="tab_deductions">

                                        <div className="text-end mb-4 clearfix">
                                                <button
                                                        className="btn btn-primary add-btn"
                                                        type="button"
                                                        onClick={openModal}
                                                >
                                                        <i className="fa fa-plus"></i> Add Deduction
                                                </button>
                                        </div>

                                        <div className="payroll-table card">
                                                <div className="table-responsive">
                                                        <Table
                                                                columns={columns}
                                                                dataSource={allPayRollItem}
                                                                rowKey={(record) => record?.payrollIdentifier}
                                                                pagination={{
                                                                        current: state.page,
                                                                        pageSize: state.pagesize,
                                                                        total: count,
                                                                        showTotal: (total, range) =>
                                                                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                                        showSizeChanger: true,
                                                                        onShowSizeChange: onShowSizeChange,
                                                                        itemRender: itemRender,
                                                                        onChange: (page, pageSize) =>
                                                                                setState({ ...state, page, pagesize: pageSize }),
                                                                }}
                                                        />
                                                </div>
                                        </div>
                                </div>
                        </div>
                        {/* deduction */}
                        {showModel && <CreatePayrollItems showModal={showModel} closeModal={closeModal} />}
                        {showEditModel && <EditPayrollItem showModal={openEditModal} closeModal={closeEditModal} />}

                        {/* over time */}
                        {showOverTimeModel && <CreateOverTime showModal={showOverTimeModel} closeModal={closeOverTimeModal} />}
                        {showEditOverTimeModel && <EditOverTime showModal={showEditOverTimeModel} closeModal={closeEditOverTimeModal} />}

                        {/* addtion */}
                        {showAddtionModel && <CreateAddition showModal={showAddtionModel} closeModal={closeAddtionModal} />}
                        {showEditAddtionModel && <EditAddtion showModal={openEditAddtionModal} closeModal={closeEditAddtionModal} />}
                </div >
        )
}
