import { useForm, Controller } from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addOverTime } from '../../../features/payRollItemSlice';

export default function AddOvertimeModal({ showModal, closeModal }) {
        const { control, handleSubmit, formState: { errors } } = useForm();
        const dispatch = useDispatch()

        const onSubmit = (data) => {
                console.log('Form Data:', data);
                const overTime = {
                        name: data.name,
                        rate: data.rate,
                        rateType: data.rateType,
                        overTimeIdentifier: null
                }
                dispatch(addOverTime(overTime))
                closeModal();
        };

        return (
                <Modal show={showModal} onHide={closeModal} centered>
                        <Modal.Header closeButton>
                                <Modal.Title>Add Overtime</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                        {/* Name Input */}
                                        <div className="form-group">
                                                <label>Name <span className="text-danger">*</span></label>
                                                <Controller
                                                        name="name"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: "Name is required" }}
                                                        render={({ field }) => (
                                                                <input className="form-control" type="text" {...field} />
                                                        )}
                                                />
                                                {errors.name && <small className="text-danger">{errors.name.message}</small>}
                                        </div>

                                        {/* Rate Type Select */}
                                        <div className="form-group">
                                                <label>Rate Type <span className="text-danger">*</span></label>
                                                <Controller
                                                        name="rateType"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: "Rate type is required" }}
                                                        render={({ field }) => (
                                                                <select className="form-control" {...field}>
                                                                        <option value="">-</option>
                                                                        <option value="Daily Rate">Daily Rate</option>
                                                                        <option value="Hourly Rate">Hourly Rate</option>
                                                                </select>
                                                        )}
                                                />
                                                {errors.rateType && <small className="text-danger">{errors.rateType.message}</small>}
                                        </div>

                                        {/* Rate Input */}
                                        <div className="form-group">
                                                <label>Rate <span className="text-danger">*</span></label>
                                                <Controller
                                                        name="rate"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{
                                                                required: "Rate is required",
                                                                pattern: { value: /^\d+(\.\d{1,2})?$/, message: "Invalid rate" }
                                                        }}
                                                        render={({ field }) => (
                                                                <input className="form-control" type="text" {...field} />
                                                        )}
                                                />
                                                {errors.rate && <small className="text-danger">{errors.rate.message}</small>}
                                        </div>

                                        {/* Submit Button */}
                                        <div className="submit-section">
                                                <Button type="submit" className="btn btn-primary submit-btn">Submit</Button>
                                        </div>
                                </form>
                        </Modal.Body>
                </Modal>
        );
}
