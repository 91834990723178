import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { emailrgx } from '../../../constant';
import { getAllClients, getAllClientsforCompany } from '../../../features/clientSlice';
import { addEstimate, addEstimateSent, getAllTaxTypes } from '../../../features/estimateSlice';
import { getAllProjects } from '../../../features/projectSlice';
import { getAllCompanies } from '../../../features/companySlice';
import Loader from '../../../components/loader/Loader';

const schema = yup.object({
    billingAddress: yup.string().required('Billing Address is required').trim(),
    clientId: yup.string(),
    createDate: yup.date().required('Estimate Date is required'),
    email: yup.string().required('Email is required').matches(emailrgx, 'Invalid Email').trim(),
    expirationDate: yup.date().required('Expiration Date is required'),
    otherInfo: yup.string(),
    tax: yup.string().required('Tax is required').trim(),
    title: yup.string().required('Title is required').trim(),
    discount: yup.number().required('Discount is required'),
}).required();

function CreateEstimate() {
    const navigate = useNavigate()
    const [overallTotal, setOverallTotal] = useState(0);
    const [isSend, setIsSend] = useState(false)
    const [total, setTotal] = useState(0);
    const [taxPercentage, setTaxPercentage] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const dispatch = useDispatch();

    const state = {
        search: '',
        page: 1,
        pagesize: 10
    };

    const { companyClients } = useSelector(state => state.client);
    const { taxTypes } = useSelector(state => state.estimates);
    const { projects } = useSelector(state => state.project);
    const { companiesList } = useSelector(state => state.company);
    const { isLoading } = useSelector((state) => state.estimates);

    useEffect(() => {
        dispatch(getAllCompanies(state.search, state.page, state.pagesize, companiesList))
    }, [dispatch])

    useEffect(() => {
        if (selectedCompanyId) {
            dispatch(getAllProjects(state.search, state.page, state.pagesize, true, selectedCompanyId));
        }
    }, [selectedCompanyId, dispatch, state.search, state.page, state.pagesize]);

    const { register, formState: { errors }, handleSubmit, control, getValues, setValue } = useForm({
        defaultValues: { cart: [{ title: '', description: '', unitPrice: 0, quantity: 0, subTotal: 0 }] },
        resolver: yupResolver(schema),
    });

    const amountCalculation = (index, unitPrice, quantity) => {
        const subTotal = unitPrice * quantity || 0;
        setValue(`cart[${index}].subTotal`, subTotal);

        let newOverallTotal = 0;
        fields.forEach((field, i) => {
            newOverallTotal += parseFloat(getValues(`cart[${i}].subTotal`)) || 0;
        });

        const taxAmount = (newOverallTotal * taxPercentage) / 100;
        setOverallTotal(newOverallTotal);
        const totalBeforeDiscount = newOverallTotal + taxAmount;

        setTotal(totalBeforeDiscount);
    };

    const applyDiscount = (value) => {
        const discountValue = value !== null && value !== '' ? parseFloat(value) : 0;

        const taxAmount = (overallTotal * taxPercentage) / 100;
        const totalBeforeDiscount = overallTotal + taxAmount;

        const discountedAmount = (totalBeforeDiscount * discountValue) / 100;
        setGrandTotal(totalBeforeDiscount - discountedAmount);
    };

    const removeItem = (index) => {
        remove(index);

        // Recalculate totals after removal
        let newOverallTotal = 0;
        fields.forEach((field, i) => {
            const subTotal = parseFloat(getValues(`cart[${i}].subTotal`)) || 0;
            newOverallTotal += subTotal;
        });

        const taxAmount = (newOverallTotal * taxPercentage) / 100;
        setOverallTotal(newOverallTotal);

        const totalBeforeDiscount = newOverallTotal + taxAmount;
        setTotal(totalBeforeDiscount);

        // Reapply the discount
        const discountValue = parseFloat(getValues('discount')) || 0;
        applyDiscount(discountValue);
    };


    useEffect(() => {
        if ($('.select').length > 0) {
            $('.select').select2({ minimumResultsForSearch: -1, width: '100%' });
        }
    });

    useEffect(() => {
        dispatch(getAllClientsforCompany(selectedCompanyId, state.search, state.page, state.pagesize, true));
    }, [state.search, state.page, state.pagesize, true, selectedCompanyId])

    useEffect(() => {
        dispatch(getAllTaxTypes());
    }, [])

    const { fields, append, remove } = useFieldArray({ name: 'cart', control });

    const clientOptions = companyClients.map(client => ({
        value: client.clientIdentifier,
        label: client.clientName
    }));

    const companyOptions = companiesList.map(company => ({
        value: company.companyIdentifier,
        label: company.name
    }));

    const taxOptions = taxTypes.map(type => ({
        value: type.id,
        label: type.taxName
    }));

    const projectOptions = projects.map(project => ({
        value: project.projectIdentifier,
        label: project.projectName
    }));

    const onSubmit = (data) => {
        data.grandTotal = grandTotal;
        data.overallTotal = overallTotal;

        const createDate = new Date(data.createDate);
        const expirationDate = new Date(data.expirationDate);

        const estimateData = {
            title: data.title,
            clientId: data.clientId,
            projectIdentifier: data?.projectIdentifier,
            companyIdentifier: data?.companyIdentifier,
            email: data.email,
            createDate: new Date(createDate.getTime() - createDate.getTimezoneOffset() * 60000).toISOString(),
            expirationDate: new Date(expirationDate.getTime() - expirationDate.getTimezoneOffset() * 60000).toISOString(),
            tax: data.tax,
            billingAddress: data.billingAddress,
            cart: data.cart,
            otherInfo: data.otherInfo,
            discount: data.discount,
            overallTotal: data.overallTotal,
            grandTotal: data.grandTotal,
            clientAddress: data?.clientAddress,
        };

        dispatch(addEstimate(estimateData, isSend));

        navigate('/admin/all-estimates');
    };

    return (
        <>
            <Helmet>
                <title>Create Estimate - HRMS Admin Template</title>
                <meta name="description" content="Login page" />
            </Helmet>
            {isLoading ? <Loader />
                :
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="page-title">Create Estimate</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/app/main/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Create Estimate</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <Controller
                                                name="title"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <input
                                                        className={`form-control mb-0 ${errors?.title ? "error-input mb-0" : ""
                                                            }`}
                                                        type="text"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.title?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Company</label>
                                            <Controller
                                                name="companyIdentifier"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactSelect
                                                        options={companyOptions}
                                                        value={companyOptions.find(option => option.value === value)}
                                                        isClearable
                                                        isSearchable
                                                        className="company-box"
                                                        onChange={(selected) => {
                                                            const selectedValue = selected ? selected.value : "";
                                                            onChange(selectedValue); // Update react-hook-form state
                                                            setSelectedCompanyId(selectedValue); // Update local state
                                                        }}
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.clientId?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Client</label>
                                            <Controller
                                                name="clientId"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <CreatableSelect
                                                        options={clientOptions}
                                                        onChange={selected => {
                                                            onChange(selected?.value);
                                                        }}
                                                        value={clientOptions.find(option => option.value === value)}
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.clientId?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Project</label>
                                            <Controller
                                                name="projectIdentifier"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <CreatableSelect
                                                        options={projectOptions}
                                                        value={projectOptions.find(option => option.value === value)}
                                                        onChange={selected => {
                                                            onChange(selected?.value);
                                                        }}
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.project?.message}</small>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <input
                                                        className={`form-control mb-0 ${errors?.email ? "error-input mb-0" : ""
                                                            }`}
                                                        type="text"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.email?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Estimate Date</label>
                                            <Controller
                                                name="createDate"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <input
                                                        className={`form-control mb-0 ${errors?.createDate ? "error-input mb-0" : ""
                                                            }`}
                                                        type="date"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.createDate?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Expiration Date</label>
                                            <Controller
                                                name="expirationDate"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <input
                                                        className={`form-control mb-0 ${errors?.expirationDate ? "error-input mb-0" : ""
                                                            }`}
                                                        type="date"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.expirationDate?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Tax</label>
                                            <Controller
                                                name="tax"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactSelect
                                                        options={taxOptions}
                                                        value={taxOptions.find(option => option.value === value)}
                                                        onChange={selected => {
                                                            const selectedTax = taxTypes.find(type => type.id === selected?.value);
                                                            setTaxPercentage(selectedTax?.taxRate || 0);
                                                            onChange(selected?.value);
                                                        }}
                                                        isClearable
                                                        isSearchable
                                                        className="company-box"
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.tax?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Billing Address</label>
                                            <Controller
                                                name="billingAddress"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <textarea
                                                        className={`form-control mb-0 ${errors?.billingAddress ? "error-input mb-0" : ""
                                                            }`}
                                                        type="date"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.billingAddress?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Client Address</label>
                                            <Controller
                                                name="clientAddress"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <textarea
                                                        className={`form-control mb-0 ${errors?.billingAddress ? "error-input mb-0" : ""
                                                            }`}
                                                        type="date"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                            <small>{errors?.clientAddress?.message}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-white">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '20px' }}>#</th>
                                                        <th className="col-sm-2">Item</th>
                                                        <th className="col-md-6">Description</th>
                                                        <th style={{ width: '100px' }}>Unit Cost</th>
                                                        <th style={{ width: '80px' }}>Qty</th>
                                                        <th>Amount</th>
                                                        <th> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fields.map((field, index) => {
                                                        return (
                                                            <tr key={field.id}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        name={`title-${index}`}
                                                                        className="form-control"
                                                                        type="text"
                                                                        style={{ minWidth: '150px' }}
                                                                        {...register(`cart[${index}].title`)}
                                                                        required
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        style={{ minWidth: '150px' }}
                                                                        {...register(`cart[${index}].description`)}
                                                                        required
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        style={{ width: '100px' }}
                                                                        {...register(`cart[${index}].unitPrice`)}
                                                                        onChange={(e) => {
                                                                            const unitPrice = parseFloat(e.target.value);
                                                                            const quantity = parseFloat(getValues(`cart[${index}].quantity`));
                                                                            amountCalculation(index, unitPrice, quantity);
                                                                        }}
                                                                        min="0"
                                                                        required
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        style={{ width: '80px' }}
                                                                        {...register(`cart[${index}].quantity`)}
                                                                        onChange={(e) => {
                                                                            const quantity = parseFloat(e.target.value);
                                                                            const unitPrice = parseFloat(getValues(`cart[${index}].unitPrice`));
                                                                            amountCalculation(index, unitPrice, quantity);
                                                                        }}
                                                                        min="0"
                                                                        required
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        readOnly
                                                                        style={{ width: '120px' }}
                                                                        {...register(`cart[${index}].subTotal`)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link text-danger font-18"
                                                                        title="Remove"
                                                                        onClick={() => removeItem(index)}
                                                                    >
                                                                        <i className="fa fa-trash-o" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary"
                                                onClick={() => {
                                                    append({
                                                        title: "",
                                                        description: "",
                                                        unitPrice: 0,
                                                        quantity: 0,
                                                        subTotal: 0
                                                    });
                                                    // Automatically apply the discount to the updated total
                                                    const discountValue = parseFloat(getValues('discount')) || 0;
                                                    applyDiscount(discountValue);
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-white">
                                                <tbody>
                                                    <tr>
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td className="text-end">Total</td>
                                                        <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>{overallTotal.toFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5} className="text-end">Tax</td>
                                                        <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>{taxPercentage.toFixed(2)} %</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5} className="text-end">
                                                            Discount %
                                                        </td>
                                                        <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>
                                                            <Controller
                                                                name="discount"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <input
                                                                        className={`form-control text-end`}
                                                                        type="number"
                                                                        value={value}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                            applyDiscount(e.target.value);
                                                                        }}
                                                                        min="0"
                                                                        max="90"
                                                                        autoComplete="false"
                                                                    />
                                                                )}
                                                                defaultValue=""
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                            Grand Total
                                                        </td>
                                                        <td style={{ textAlign: 'right', paddingRight: '30px', fontWeight: 'bold', fontSize: '16px', width: '230px' }}>
                                                            $ {grandTotal.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Other Information</label>
                                                    <Controller
                                                        name="otherInfo"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <textarea
                                                                className={`form-control ${errors?.otherInfo ? "error-input mb-0" : ""
                                                                    }`}
                                                                rows={4}
                                                                type="date"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    <small>{errors?.otherInfo?.message}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button
                                        type="submit"
                                        className="btn btn-primary submit-btn m-r-10"
                                        onClick={() => setIsSend(false)}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary submit-btn m-r-10"
                                        onClick={() => setIsSend(true)}
                                    >
                                        Save &amp; Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CreateEstimate