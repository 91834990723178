import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addPayRollItem, updatePayroll } from '../../../features/payRollItemSlice';
import { useSelector } from 'react-redux';
import { getAllCompanies } from '../../../features/companySlice';

export default function EditPayrollItem({ showModal, closeModal }) {
  const dispatch = useDispatch();
  const { payrollItem } = useSelector((state) => state.payrollItem);
  const { control, handleSubmit, formState: { errors }, setValue } = useForm();

  const [state, setState] = useState({
    search: "",
    page: 1,
    pagesize: 10,
    type: 'overTime'
  });

  useEffect(() => {
    dispatch(getAllCompanies(state.search, state.page, state.pagesize))
  }, [dispatch]);

  useEffect(() => {
    if (payrollItem) {
      setValue('name', payrollItem?.value);
    }
  }, [dispatch])

  const onSubmit = (data) => {
    const payroll = {
      name: data.name,
      categories: getCategoryValue(data.category),
      assignee: 1,
      employeeIdentifier: "02cced1f-e81e-4d20-a5c7-c5846188",
      companyIdentifier: "4fd4d770-4c15-49",
      unitCalculation: true,
      unitPrice: 54.12,
      payStatus: "string",
      type: "PayRoll",
      payrollIdentifier: payrollItem?.payrollIdentifier
    }
    dispatch(updatePayroll(payroll))
    closeModal();
  };

  const getCategoryValue = (category) => {
    switch (category) {
      case 'Furniture':
        return 1;
      case 'Electronic':
        return 2;
      case 'Vehicle':
        return 3;
      case 'Instruments':
        return 4;
      case 'Land':
        return 5;
      case 'Building':
        return 6;
      default:
        return 0;
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Deduction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>Name <span className="text-danger">*</span></label>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Name is required" }}
              render={({ field }) => (
                <input className="form-control" type="text" {...field} />
              )}
            />
            {errors.name && <small className="text-danger">{errors.name.message}</small>}
          </div>

          <div className="form-group">
            <label className="d-block">Unit calculation</label>
            <Controller
              name="unitCalculation"
              control={control}
              render={({ field }) => (
                <div className="status-toggle">
                  <input type="checkbox" id="unit_calculation_deduction" className="check" {...field} />
                  <label htmlFor="unit_calculation_deduction" className="checktoggle">checkbox</label>
                </div>
              )}
            />
          </div>

          <div className="form-group">
            <label>Unit Amount <span className="text-danger">*</span></label>
            <div className="input-group">
              <span className="input-group-text">$</span>
              <Controller
                name="unitAmount"
                control={control}
                rules={{
                  required: "Unit amount is required",
                  pattern: { value: /^\d+(\.\d{1,2})?$/, message: "Invalid amount" }
                }}
                render={({ field }) => (
                  <input type="text" className="form-control" {...field} />
                )}
              />
              <span className="input-group-text">.00</span>
            </div>
            {errors.unitAmount && <small className="text-danger">{errors.unitAmount.message}</small>}
          </div>

          <div className="form-group">
            <label className="d-block">Assignee</label>
            <Controller
              name="deductionAssignee"
              control={control}
              rules={{ required: "Assignee is required" }}
              render={({ field }) => (
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="deduction_no_emp1"
                      value="no_assignee"
                      checked={field.value === "no_assignee"}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="deduction_no_emp1">No assignee</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="deduction_all_emp1"
                      value="all_employees"
                      checked={field.value === "all_employees"}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="deduction_all_emp1">All employees</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="deduction_single_emp1"
                      value="select_employee"
                      checked={field.value === "select_employee"}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="deduction_single_emp1">Select Employee</label>
                  </div>
                </div>
              )}
            />
            {errors.deductionAssignee && <small className="text-danger">{errors.deductionAssignee.message}</small>}
          </div>

          <div className="form-group">
            <label>Select Employee</label>
            <Controller
              name="employee"
              control={control} S
              rules={{ required: "Please select an employee" }}
              render={({ field }) => (
                <select className="form-control" {...field}>
                  <option value="">-</option>
                  <option value="all">Select All</option>
                  <option value="john">John Doe</option>
                  <option value="richard">Richard Miles</option>
                </select>
              )}
            />
            {errors.employee && <small className="text-danger">{errors.employee.message}</small>}
          </div>

          <div className="submit-section">
            <Button type="submit" className="btn btn-primary submit-btn">Submit</Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
