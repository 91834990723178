import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployeesforCompany } from "../../../features/employeeSlice";
import { getAllTicketsForCompany } from "../../../features/ticketsSlice";
import {
  deleteProject,
  getAllProjects,
  getProject,
} from "../../../features/projectSlice";
import { confirmAlert } from "react-confirm-alert";
import { getAllClientsforCompany } from "../../../features/clientSlice";
import { getAllInvoice } from "../../../features/invoiceSlice";

function CompanyDashboard() {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();

  // selector that get data from redux tool kit
  const { companyEmployees } = useSelector((state) => state.employee);
  const { companyTickets } = useSelector((state) => state.ticket);
  const { companyClients } = useSelector((state) => state.client);
  const { allInvoice } = useSelector((state) => state.invoice);

  // set pagination values
  const state = {
    search: "",
    page: 1,
    pagesize: 12,
  };
 
  // Methods all clients for company
  const allClients = async () => {
    dispatch(
      getAllClientsforCompany(
        user.companyIdentifier,
        state.search,
        state.page,
        state.pagesize
      )
    );
  };

  useEffect(() => {
    allClients();
  }, [state.search, state.page, state.pagesize]);

  // fetch projects of company

  const { projects } = useSelector((state) => state.project);
  useEffect(() => {
    reloadData();
  }, [state.search, state.page, state.pagesize]);

  //method for page Reload
  const reloadData = async () => {
    dispatch(
      getAllEmployeesforCompany(
        state.search,
        user.companyIdentifier,
        state.page,
        state.pagesize
      )
    );
    dispatch(
      getAllProjects(
        state.search,
        state.page,
        state.pagesize,
        true,
        user.companyIdentifier
      )
    );
  };

  // fetch tickets of  company
  useEffect(() => {
    getAllTickets();
  }, [state.search, state.page, state.pagesize, state.status, state.priority]);

  const getAllTickets = async () => {
    dispatch(
      getAllTicketsForCompany(
        user.companyIdentifier,
        state.search,
        state.page,
        state.pagesize
      )
    );
  };

  //set companyId to  set in link  for routing  purpose
  const [stateCompanyId, setStateCompanyId] = useState(user.companyIdentifier);

  //get invoices of a company
  useEffect(() => {
    dispatch(
      getAllInvoice("", "", "", "", state.page, state.pagesize, stateCompanyId)
    );
  }, [dispatch, state.page, state.pagesize, stateCompanyId]);

  // delete project of company
  const handleProjectDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleProjectDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleProjectDelete = (id) => {
    dispatch(deleteProject(id));
  };

  // update project of company
  const handleProjectUpdateClick = (id) => {
    dispatch(getProject(id));
  };

  return (
    <>
      <Helmet>
        <title>Dashboard - HRMS Admin Template</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="page-title">Welcome Company!</h3>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <i className="fa fa-cubes" />
                </span>
                <div className="dash-widget-info">
                  <h3>{projects ? projects.length : "0"}</h3>
                  <span>Projects</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <i className="fa fa-usd" />
                </span>
                <div className="dash-widget-info">
                  <h3>{companyClients ? companyClients.length : "0"}</h3>
                  <span>Clients</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <i className="fa fa-diamond" />
                </span>
                <div className="dash-widget-info">
                  <h3>{companyTickets.length}</h3>
                  <span>Tickets</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <i className="fa fa-user" />
                </span>
                <div className="dash-widget-info">
                  <h3>{companyEmployees ? companyEmployees.length : "0"}</h3>
                  <span>Employees</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
              <div className="card-header">
                <h3 className="card-title mb-0">Invoices</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-nowrap custom-table mb-0">
                    <thead>
                      <tr>
                        <th>Invoice ID</th>
                        <th>Client</th>
                        <th>Due Date</th>
                        <th>Total</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allInvoice.map((invoice, index) => (
                        <tr>
                          <td>
                            <Link to="/app/sales/invoices-view">
                              #INV-000{index}
                            </Link>
                          </td>
                          <td>
                            <h2>
                              <Link to="#">{invoice.Name}</Link>
                            </h2>
                          </td>
                          <td>11 Mar 2019</td>
                          <td>${invoice.total}</td>
                          <td>
                            <span className="badge bg-inverse-warning">
                              {invoice.status}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <Link to={`/company/all-invoice/${stateCompanyId}`}>
                  View all invoices
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
              <div className="card-header">
                <h3 className="card-title mb-0">Clients</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table custom-table mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Country</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyClients.map((client) => (
                        <tr>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="#" className="avatar">
                                <img alt="" src={"Avatar_19"} />
                              </Link>
                              <Link to="/company/app/profile/client-profile">
                                {client.clientName}
                              </Link>
                            </h2>
                          </td>
                          <td>{client.clientEmail}</td>
                          <td>{client.country}</td>
                          <td className="text-end">
                            <div className="dropdown dropdown-action">
                              <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons">more_vert</i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-right">
                                <Link
                                  to={`/company/update-client/${client.clientIdentifier}/${setStateCompanyId}`}
                                  className="dropdown-item"
                                >
                                  <i className="fa fa-pencil m-r-5" /> Edit
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <i className="fa fa-trash-o m-r-5" /> Delete
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <Link to={`/company/all-clients/${stateCompanyId}`}>
                  View all clients
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
              <div className="card-header">
                <h3 className="card-title mb-0">Recent Projects</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table  custom-table mb-0">
                    <thead>
                      <tr>
                        <th>Project Name </th>
                        <th>Progress</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project) => (
                        <tr>
                          <td>
                            <h2>
                              <Link to="#">{project.projectName}</Link>
                            </h2>
                            <small className="block text-ellipsis">
                              <span>1</span>{" "}
                              <span className="text-muted">open tasks, </span>
                              <span>9</span>{" "}
                              <span className="text-muted">
                                tasks completed
                              </span>
                            </small>
                          </td>
                          <td>{project.status}</td>
                          <td className="text-end">
                            <div className="dropdown dropdown-action">
                              <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons">more_vert</i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-right">
                                <Link
                                  onClick={() =>
                                    handleProjectUpdateClick(
                                      project.projectIdentifier
                                    )
                                  }
                                  className="dropdown-item"
                                  to={`/admin/update-project/${project.projectIdentifier}`}
                                >
                                  <i className="fa fa-pencil m-r-5" /> Edit
                                </Link>
                                <Link
                                  onClick={() =>
                                    handleProjectDeleteClick(
                                      project.projectIdentifier
                                    )
                                  }
                                  className="dropdown-item"
                                  to="#"
                                >
                                  <i className="fa fa-trash-o m-r-5" /> Delete
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <Link to="/company/projects/project_dashboard">
                  View all projects
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDashboard;
