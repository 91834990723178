import React, { useEffect, useRef, useState } from 'react';
import NavMenu from '../../../components/NavBars/NavMenu';
import Sidebar from '../../../components/SideBar/Sidebar';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { orangeLogo } from '../../../assets/img';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactToPrint from 'react-to-print';
import { getEstimate } from '../../../features/estimateSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { CSVLink } from 'react-csv'; // Import CSVLink for CSV export

function EstimateView() {
    const dispatch = useDispatch();
    const { estimateIdentifier } = useParams();
    const { estimate } = useSelector((state) => state.estimates);
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);
    const componentRef = useRef();

    useEffect(() => {
        dispatch(getEstimate(estimateIdentifier));
    }, [dispatch]);

    console.log('estimate', estimate);

    const printPDF = () => {
        const input = document.getElementById('estimate-pdf');
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('estimate.pdf');
        });
    };

    const columns = [
        {
            title: '#',
            render: (text, record, index) => <span>{index + 1}</span>,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'ITEM',
            dataIndex: 'title',
            render: (text, record) => <span>{record.title}</span>,
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            render: (text, record) => <span>{record.description}</span>,
        },
        {
            title: 'UNIT COST',
            dataIndex: 'unitPrice',
            render: (text, record) => <span>{record.unitPrice}</span>,
        },
        {
            title: 'QUANTITY',
            dataIndex: 'quantity',
            render: (text, record) => <span>{record.quantity}</span>, // Corrected to render quantity
        },
        {
            title: 'TOTAL',
            dataIndex: 'subTotal',
            render: (text, record) => <span>{record.subTotal}</span>, // Corrected to render subtotal
        },
    ];

    // Prepare CSV data
    const csvData = estimate?.items ? estimate.items.map((item, index) => ({
        '#': index + 1,
        'ITEM': item.title,
        'DESCRIPTION': item.description,
        'UNIT COST': item.unitPrice,
        'QUANTITY': item.quantity,
        'TOTAL': item.subTotal,
    })) : [];

    const csvHeaders = [
        { label: '#', key: '#' },
        { label: 'ITEM', key: 'ITEM' },
        { label: 'DESCRIPTION', key: 'DESCRIPTION' },
        { label: 'UNIT COST', key: 'UNIT COST' },
        { label: 'QUANTITY', key: 'QUANTITY' },
        { label: 'TOTAL', key: 'TOTAL' },
    ];

    return (
        <>
            <Helmet>
                <title>Estimates - HRMS Admin Template</title>
                <meta name="description" content="Estimate page" />
            </Helmet>
            {/* Page Content */}
            <div className="content container-fluid" id="estimate-content">
                {/* Page Header */}
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Estimate</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/app/main/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Estimate</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ml-auto">
                            <div className="btn-group btn-group-sm">
                                <CSVLink data={csvData} headers={csvHeaders} filename={"estimate.csv"} className="btn btn-white">CSV</CSVLink>
                                <button className="btn btn-white" onClick={printPDF}>PDF</button>
                                <ReactToPrint
                                    trigger={() => <button className="btn btn-white">Print</button>}
                                    content={() => componentRef.current}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Page Header */}
                <div className="row" id='estimate-pdf' ref={componentRef}>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 m-b-20">
                                        <img src={orangeLogo} className="inv-logo" alt="Company Logo" />
                                        <ul className="list-unstyled">
                                            <li>{estimate?.company[0]?.name}</li>
                                            <li>{estimate?.company[0]?.country}</li>
                                            <li>{estimate?.company[0]?.address}</li>
                                            <li>GST No: {estimate?.taxIdentificationNumber}</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6 m-b-20">
                                        <div className="invoice-details">
                                            <h3 className="text-uppercase">Estimate #49029</h3>
                                            <ul className="list-unstyled">
                                                <li>Create Date: <span>{new Date(estimate?.createDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span></li>
                                                <li>Expiry Date: <span>{new Date(estimate?.expirationDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-lg-12 m-b-20">
                                        <h5>Estimate to:</h5>
                                        <ul className="list-unstyled">
                                            <li>{estimate?.clientAddress}</li>
                                            <li><a href="mailto:barrycuda@example.com">{estimate?.email}</a></li>
                                            <li>{estimate?.billingAddress}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <Table
                                        columns={columns}
                                        dataSource={Array.isArray(estimate?.items) ? estimate.items : []}
                                        rowKey={(record) => record?.estimateIdentifier}
                                    />
                                </div>
                                <div>
                                    <div className="row invoice-payment">
                                        <div className="col-sm-7">
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="m-b-20">
                                                <div className="table-responsive no-border">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <th>Subtotal:</th>
                                                                <td className="text-end">{estimate?.overAllTotal}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Tax: <span className="text-regular">(25%)</span></th>
                                                                <td className="text-end">{estimate?.grandTotal}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Total:</th>
                                                                <td className="text-end text-primary"><h5>{estimate?.grandTotal}</h5></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invoice-info">
                                        <h5>Other information</h5>
                                        <p className="text-muted">{estimate?.otherInformation}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EstimateView;
