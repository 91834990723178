import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import toast from "react-hot-toast";

const payRollItemSlice = createSlice({
        name: 'payrollitem',
        initialState: {
                allPayRollItem: [],
                payRollItem: null,
                isLoading: false,
                count: null,

                alloverTime: []
        },
        reducers: {
                getAllPayRollItemSuccess: (state, action) => {
                        state.isLoading = false;
                        state.allPayRollItem = action.payload;
                },
                getAllPayRollItemFailure: (state, action) => {
                        state.isLoading = false;
                        state.allPayRollItem = []
                },
                addPayRollItemSuccess: (state, action) => {
                        state.isLoading = false;
                },
                addPayRollItemFailure: (state, action) => {
                        state.isLoading = false;
                },
                deletePayRollSuccess: (state, action) => {
                        state.isLoading = false;
                },
                deletePayRollFailure: (state, action) => {
                        state.isLoading = false;
                },
                getPayRollSuccess: (state, action) => {
                        state.isLoading = false;
                        state.payRollItem = action.payload;
                },
                getPayRollFailure: (state, action) => {
                        state.isLoading = false;
                        state.payRollItem = null
                },
                updatePayrollSuccess: (state, action) => {
                        state.isLoading = false;
                },
                updatePayrollFailure: (state, action) => {
                        state.isLoading = false;
                },
                // overtime
                getAllOverTimeSuccess: (state, action) => {
                        state.isLoading = false;
                        state.alloverTime = action.payload;
                },
                getAllOverTimeFailure: (state, action) => {
                        state.isLoading = false;
                        state.alloverTime = [];
                },
                addOverTimeSuccess: (state, action) => {
                        state.isLoading = false;
                },
                addOverTimeFailure: (state, action) => {
                        state.isLoading = false;
                },
                updateOverTimeSuccess: (state, action) => {
                        state.isLoading = false;
                },
                updateOverTimeFailure: (state, action) => {
                        state.isLoading = false;
                },
                setLoading: (state, action) => {
                        state.isLoading = true;
                }
        }
});

// get all payrollitem
export const getAllPayRollItem = (type) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/PayRoll/all/lists?type=${type}`);
                if (!response.data.success) {
                        toast.success(response.data.message);
                        dispatch(getAllPayRollItemSuccess(response.data.data));
                } else {
                        dispatch(getAllPayRollItemFailure());
                }
        } catch (error) {
                toast.error(error.message);
                dispatch(getAllPayRollItemFailure());
        }
}

// create payroll
export const addPayRollItem = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/payRoll/add-payroll`, data);
                if (!response.data.success) {
                        toast.success(response.data.message);
                        dispatch(addPayRollItemSuccess())
                } else {
                        dispatch(addPayRollItemFailure())
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(addPayRollItemFailure())
        }
}

// delete payroll
export const deletePayRoll = (identifier) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/payRoll/delete/${identifier}`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(deletePayRollSuccess());
                } else {
                        dispatch(deletePayRollFailure())
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(deletePayRollFailure());
        }
}

// get payroll 
export const getPayRoll = (identifier) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/payRoll/${identifier}`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(getPayRollSuccess(response.data));
                } else {
                        dispatch(getPayRollFailure())
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(getPayRollFailure())
        }
}

// update payroll
export const updatePayroll = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/payRoll/update-payroll`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(updatePayrollSuccess())
                } else {
                        dispatch(updatePayrollFailure())
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(updatePayrollFailure())
        }
}

// create overtime
export const addOverTime = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/PayRoll/add/overTime`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(addOverTimeSuccess());
                }
                else {
                        dispatch(addOverTimeFailure());
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(addOverTimeFailure())
        }
}

// overtime upadte 
export const updateOverTime = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/PayRoll/update/OverTime`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(updateOverTimeSuccess())
                } else {
                        dispatch(updateOverTimeFailure())
                }

        }
        catch (error) {
                toast.error(error.message);
                dispatch(updateOverTimeFailure());
        }
}

export const {
        getAllPayRollItemSuccess, getAllPayRollItemFailure,
        addPayRollItemSuccess, addPayRollItemFailure,
        deletePayRollSuccess, deletePayRollFailure,
        getPayRollSuccess, getPayRollFailure,
        updatePayrollSuccess, updatePayrollFailure,
        addOverTimeSuccess, addOverTimeFailure,
        updateOverTimeSuccess, updateOverTimeFailure,
        setLoading
} = payRollItemSlice.actions;

export default payRollItemSlice.reducer;