import { Table } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Payments() {
  const [state, setState] = useState({
    search: "",
    page: 1,
    pagesize: 10,
  });

  const onShowSizeChange = (current, pageSize) => {
    setState({ ...state, page: 1, pagesize: pageSize });
  };

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <button className="btn btn-sm btn-primary">Previous</button>;
    }
    if (type === "next") {
      return <button className="btn btn-sm btn-primary">Next</button>;
    }
    return originalElement;
  };

  const columns = [
    {
      title: "Invoice ID",
      render: (text, record, index) => <span>{index + 1}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Client",
      dataIndex: "taxName",
      render: (text, record) => <span>{record.taxName}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "taxRate",
      render: (text, record) => <span>{record.taxRate}</span>,
    },
    {
      title: "Paid Date",
      dataIndex: "isActive",
      render: (text, record) => <span>{record.taxRate}</span>,
    },
    {
      title: "Paid Amount",
      dataIndex: "isActive",
      render: (text, record) => <span>{record.taxRate}</span>,
    },
  ];

  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="page-title">Payments</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="admin-dashboard.html">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">Payments</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <div
              id="DataTables_Table_0_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div
                    className="dataTables_length"
                    id="DataTables_Table_0_length"
                  >
                    <label>
                      Show
                      <select
                        name="DataTables_Table_0_length"
                        aria-controls="DataTables_Table_0"
                        className="custom-select custom-select-sm form-control form-control-sm"
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>{" "}
                      entries
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6"></div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      pagination={{
                        current: state.page,
                        pageSize: state.pagesize,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                        onChange: (page, pageSize) =>
                          setState({ ...state, page, pagesize: pageSize }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-5">
                  <div
                    className="dataTables_info"
                    id="DataTables_Table_0_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 1 to 3 of 3 entries
                  </div>
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="DataTables_Table_0_paginate"
                  >
                    <ul className="pagination">
                      <li
                        className="paginate_button page-item previous disabled"
                        id="DataTables_Table_0_previous"
                      >
                        <Link
                          to="#"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="0"
                          tabindex="0"
                          className="page-link"
                        >
                          Previous
                        </Link>
                      </li>
                      <li className="paginate_button page-item active">
                        <Link
                          to="#"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="1"
                          tabindex="0"
                          className="page-link"
                        >
                          1
                        </Link>
                      </li>
                      <li
                        className="paginate_button page-item next disabled"
                        id="DataTables_Table_0_next"
                      >
                        <Link
                          to="#"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="2"
                          tabindex="0"
                          className="page-link"
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
