import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllBoardMembers,
  updateDueDate,
  updateTaskDescription,
  updateTaskTitle,
} from "../../../../features/boardSlice";
import { genericImg } from "../../../../assets/img";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TaskAssignUsers from "./TaskAssignUsers";
import TaskComments from "./comments/TaskComments";
import * as signalR from '@microsoft/signalr';
import httpHandler from "../../../../utils/httpHandler";

function TaskDetailModal(props) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const { isLoading, taskDetail } = useSelector((state) => state.taskBoard);
  const [title, setTitle] = useState(taskDetail?.title);
  const [description, setDescription] = useState(taskDetail?.description);
  const [dueDate, setDueDate] = useState(null);
  const [showTaskUserAssignModal, setTaskUserAssignModal] = useState(false);
  const [connection, setConnection] = useState(null);
  const toggleTaskUserAssignModal = () =>
    setTaskUserAssignModal(!showTaskUserAssignModal);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ size: [] }],
      [{ font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: ["right", "center", "justify"] }],
      ["link", "image"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "link",
    "image",
  ];

  const handleTitleBlur = () => {
    if (title === taskDetail.title) return;
    else {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("taskIdentifier", taskDetail.taskIdentifier);
      dispatch(updateTaskTitle(formData, props.boardID));
    }
  };

  const handleProcedureContentChange = (content) => setDescription(content);

  const handleDescriptionBlur = () => {
    if (description === taskDetail.description) return;
    else {
      const formData = new FormData();
      formData.append("taskIdentifier", taskDetail.taskIdentifier);
      formData.append("description", description);
      dispatch(updateTaskDescription(formData, props.boardId));
    }
  };
  const handleDueDateChange = () => {
    if (dueDate) {
      const formatData = new FormData();
      formatData.append("taskIdentifier", taskDetail.taskIdentifier);
      formatData.append("dueDate", dueDate.toISOString());
      dispatch(updateDueDate(formatData, dueDate.toISOString()));
    }
  };
  const handleExpiryStatus = (date) => {
    const expiryDate = new Date(date);
    const today = new Date();

    const diffInMs = expiryDate.getTime() - today.getTime();
    const diffInHours = diffInMs / (1000 * 60 * 60);

    if (diffInHours < 0) return "Overdue";
    else if (Math.abs(diffInHours) <= 12) return "Due Soon";
    else {
      return "";
    }
  };

  const handleMembersClick = () => {
    dispatch(getAllBoardMembers(props.boardID, ""));
    toggleTaskUserAssignModal();
  };

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_API_URL}/taskCommentHub?taskId=${props.taskDetail?.taskIdentifier}`,
        {
          accessTokenFactory: () => user.token,
        }
      )
      .withAutomaticReconnect()
      .build();
  
    setConnection(newConnection);
  }, [user.token, props.taskDetail?.taskIdentifier]);
  
  useEffect(() => {
    if (connection) {
      if (connection.state === signalR.HubConnectionState.Disconnected) {
        connection
          .start()
          .then(() => {
            console.log("SignalR Connected");
            connection.on("ReceiveTaskComment", (comment) => {
              if (comment.taskIdentifier === props.taskDetail?.taskIdentifier) {
                props.setTaskComments((prevComments) => [...prevComments, comment]);
              }
            });
          })
          .catch((err) => console.error("Error while starting connection: ", err));
      } else {
        console.warn("SignalR connection is already in progress or connected.");
      }
    }
  }, [connection, props.taskDetail?.taskIdentifier, props]);

  //Add comment in task Detail
  const addComment = (text, parentId) => {
    const formData = new FormData();
    formData.append("taskIdentifier", props.taskDetail?.taskIdentifier);
    formData.append("comment", text);
    formData.append("userIdentifier", user.userIdentifier);
    if (parentId) formData.append("parentId", parentId);

    return new Promise((resolve, reject) => {
      httpHandler
        .post(`/api/taskBoard/addComment`, formData)
        .then((response) => {
          if (
            connection &&
            connection.state === signalR.HubConnectionState.Connected
          ) {
            connection
              .invoke("SendMessage", response.data.data)
              .then(() => {
                resolve(); // Resolve the promise after successful invocation
              })
              .catch((error) => {
                console.error(error);
                reject(error); // Reject the promise if invocation fails
              });
          } else {
            console.error(
              "SignalR connection is not in the 'Connected' state."
            );
            reject(
              new Error("SignalR connection is not in the 'Connected' state.")
            );
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error); // Reject the promise if the HTTP request fails
        });
    });
  };

  return (
    <>
      {!isLoading && (
        <>
          <Modal
            className="Card-modal"
            size="lg"
            isOpen={props.taskDetailModalOpen}
            toggle={() => props.toggleTaskDetailModal()}
          >
            <ModalHeader toggle={() => props.toggleTaskDetailModal()}>
              <div className="card-header js-card-detail-header">
                <div className="card-title">
                  <i className="fa fa-list" aria-hidden="true"></i>
                  <textarea
                    name="task-title"
                    type="text"
                    className="textarea-box"
                    onFocusCapture={() => setTitle(taskDetail?.title)}
                    onBlur={handleTitleBlur}
                    onChange={(event) => setTitle(event.target.value)}
                    defaultValue={taskDetail?.title}
                  ></textarea>
                </div>
                <div className="card-header-inline-content quiet js-current-list">
                  <p className="u-inline-block u-bottom">
                    in list{" "}
                    <Link to={"#"} className="js-open-move-from-header">
                      <u>{taskDetail?.listName}</u>
                    </Link>
                  </p>
                </div>
                <div className="card-header-inline-content js-subscribed-indicator-header">
                  <i className="fa fa-eye"></i>
                </div>
              </div>
            </ModalHeader>
            <div className="fixed-header">
              <div className="navbar justify-content-start align-items-center">
                {props.taskDetail?.assignedUsers?.length > 0 && (
                  <div className="task-assign">
                    {props.taskDetail?.assignedUsers
                      .slice(0, 6)
                      .map((user, index) => (
                        <Link
                          key={user.employeeIdentifier}
                          className="avatar"
                          to="#"
                          style={{ width: "24px", height: "24px" }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={user?.name}
                        >
                          <img
                            key={user.employeeIdentifier}
                            src={
                              user?.userProfile ? user?.userProfile : genericImg
                            }
                            alt=""
                            className="task-avatar"
                          />
                        </Link>
                      ))}
                  </div>
                )}
                {taskDetail?.dueDate && (
                  <button
                    className="mx-4 button-box"
                    type="button"
                    data-testid="due-date-badge-with-date-range-picker"
                  >
                    <span>{taskDetail.dueDate}</span>
                    <span className="due-block">
                      {handleExpiryStatus(taskDetail.dueDate)}
                    </span>
                  </button>
                )}
              </div>
            </div>
            <ModalBody className="card-body-box">
              <div className="card-main-col">
                <div className="card-module-title card-module-title-no-divider description-title">
                  <i className="fa fa-list me-2"></i>
                  <h3 className="u-inline-block m-0">Description</h3>
                </div>
                <div className="u-gutter">
                  <div attr="desc" className="editable">
                    <div className="description-content js-desc-content">
                      <div className="u-bottom js-hide-with-desc">
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          defaultValue={taskDetail?.description}
                          onChange={handleProcedureContentChange}
                          onBlur={handleDescriptionBlur}
                          onFocusCapture={taskDetail?.description}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <TaskComments
                  comments={props.comments}
                  taskIdentifier={props.taskDetail?.taskIdentifier}
                  boardId={props.boardId}
                  setTaskComments={props.setTaskComments}
                  addComment={addComment}
                />
              </div>
              <div className="card-sidebar">
                <h3 className="mod-no-top-margin js-sidebar-add-heading">
                  Add to card
                </h3>
                <div className="u-clearfix">
                  <Link
                    to={"#"}
                    className="button-link js-change-card-members"
                    title="Members"
                    onClick={handleMembersClick}
                  >
                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                    <span className="js-sidebar-action-text">Members</span>
                  </Link>
                  <DatePicker
                    onCalendarOpen={() =>
                      setDueDate(new Date(taskDetail?.dueDate))
                    }
                    onCalendarClose={handleDueDateChange}
                    className="button-link js-card-cover-chooser"
                    placeholderText="Dates"
                    selected={dueDate}
                    onChange={(date) => setDueDate(date)}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                  />
                  <Link
                    to={"#"}
                    className="button-link js-card-cover-chooser"
                    title="Cover"
                  >
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <span className="js-sidebar-action-text">Cover</span>
                  </Link>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <TaskAssignUsers
            boardID={props?.boardID}
            taskID={taskDetail?.taskIdentifier}
            showTaskUserAssignModal={showTaskUserAssignModal}
            toggleTaskUserAssignModal={toggleTaskUserAssignModal}
          />
        </>
      )}
    </>
  );
}

export default TaskDetailModal;
