import { createSlice } from "@reduxjs/toolkit";
import httpHandler from "../utils/httpHandler";
import { toast } from "react-hot-toast";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: [],
    isLoading: true,
    project: null,
    projectsCount: null,
    unAssignedEmployees: [],
    images: [],
    files: [],
  },
  reducers: {
    getProjectsSuccess: (state, action) => {
      state.projects = action.payload.data.data;
      state.isLoading = false;
      state.projectsCount = action.payload.data.count;
    },
    getProjectsFailure: (state, action) => {
      state.projects = [];
      state.isLoading = false;
    },
    getProjectSuccess: (state, action) => {
      state.project = action.payload.data.data;
      const attachments = action.payload.data.data.filepaths;
      const images = attachments.filter((attachment) => {
        const ext =
          attachment.fileName &&
          attachment.fileName.split(".").pop().toLowerCase();
        return ext === "jpg" || ext === "jpeg" || ext === "png";
      });
      const files = attachments.filter((attachment) => {
        const ext =
          attachment.fileName &&
          attachment.fileName.split(".").pop().toLowerCase();
        return ext === "pdf" || ext === "doc" || ext === "docx";
      });
      state.images = images;
      state.files = files;
      state.isLoading = false;
    },
    getProjectFailure: (state, action) => {
      state.isLoading = false;
    },
    deleteProjectSuccess: (state, action) => {
      const projectId = action.payload.id;
      state.projects = state.projects.filter(
        (project) => project.projectIdentifier !== projectId
      );
      state.isLoading = false;
      state.projectsCount = state.projectsCount - 1;
    },
    deleteAttachmentSuccess: (state, action) => {
      state.isLoading = false;
    },
    addAttachmentSuccess: (state, action) => {
      state.isLoading = false;
    },
    addAttachmentFailure: (state, action) => {
      state.isLoading = false;
    },
    deleteAttachmentFailure: (state, action) => {
      state.isLoading = false;
    },
    getUnAssignedEmpForProjectSuccess: (state, action) => {
      state.unAssignedEmployees = action.payload.data;
      state.isLoading = false;
    },
    getUnAssignedEmpForProjectFailure: (state, action) => {
      state.isLoading = false;
    },
    projectAssignmentSuccess: (state, action) => {
      state.isLoading = false;
    },
    projectAssignmentFailure: (state, action) => {
      state.isLoading = false;
    },
    removeAssigneeFromProjectSuccess: (state, action) => {
      state.isLoading = false;
    },
    removeAssigneeFromProjectFailure: (state, action) => {
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = true;
    },
  },
});

export const getAllProjects =
  (search, page, pagesize, isCompany, companyId) => async (dispatch) => {
    try {
      dispatch(setLoading);
      var response;
      if (isCompany) {
        response = await httpHandler.get(
          `/api/project/allProjects?search=${search}&page=${page}&pagesize=${pagesize}&companyIdentifier=${companyId}`
        );
      } else {
        response = await httpHandler.get(
          `/api/project/allProjects?search=${search}&page=${page}&pagesize=${pagesize}`
        );
      }
      dispatch(getProjectsSuccess(response));
    } catch (error) {
      dispatch(getProjectsFailure(error));
    }
  };

export const getProject = (id) => async (dispatch) => {
  try {
    dispatch(setLoading);
    const response = await httpHandler.get(`/api/project/getProject/${id}`);
    if (response.data.success) {
      dispatch(getProjectSuccess(response));
    }
  } catch (error) {
    dispatch(getProjectFailure(error));
  }
};

export const createProject =
  (data, navigate, isCompany, companyId) => async () => {
    try {
      const response = await httpHandler.post(`/api/project/addProject`, data);
      if (response.data.success) {
        toast.success(response.data.message);
        if (isCompany) {
          navigate(`/company-projects/${companyId}`);
        } else {
          navigate("/admin/projects");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
export const updateProject =
  (data, id, navigate, isCompany, companyId) => async () => {
    try {
      const response = await httpHandler.post(
        `/api/project/editProject/${id}`,
        data
      );
      if (response.data.success) {
        toast.success(response.data.message);
        if (isCompany) {
          navigate(`/company-projects/${companyId}`);
        } else {
          navigate("/admin/projects");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

export const deleteProject = (id) => async (dispatch) => {
  try {
    debugger
    dispatch(setLoading());
    const response = await httpHandler.get(`/api/project/deleteProject/${id}`);
    if (response.data.success) {
      dispatch(deleteProjectSuccess({ id }));
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const changeProjectPriority =
  (id, newPriority, reloadData) => async () => {
    try {
      const response = await httpHandler.post(`/api/project/changePriority`, {
        id: id,
        newPriority: newPriority,
      });
      if (response.data.success) {
        reloadData();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

export const changeProjectStatus = (id, newStatus, reloadData) => async () => {
  try {
    const response = await httpHandler.post(`/api/project/changeStatus`, {
      id: id,
      newStatus: newStatus,
    });
    if (response.data.success) {
      reloadData();
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const projectAssignment = (data, projectId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await httpHandler.post(
      `/api/project/projectAssignment`,
      data
    );
    if (response.data.success) {
      toast.success(response.data.message);
      dispatch(getProject(projectId));
      dispatch(projectAssignmentSuccess());
    } else {
      dispatch(projectAssignmentFailure());
      toast.error(response.data.message);
    }
  } catch (error) {
    dispatch(projectAssignmentFailure());
    toast.error(error.message);
  }
};

export const addProjectAttachment = (data, projectId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await httpHandler.post(
      `/api/project/AddAttachmentToProject`,
      data
    );
    if (response.data.success) {
      dispatch(getProject(projectId));
      dispatch(addAttachmentSuccess(response.data));
      toast.success(response.data.message);
    } else {
      dispatch(addAttachmentFailure());
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.error);
    dispatch(addAttachmentFailure());
  }
};

export const removeAssigneeFromProject =
  (data, projectId) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await httpHandler.post(
        `/api/project/removeProjectAssignee`,
        data
      );
      if (response.data.success) {
        dispatch(getProject(projectId));
        dispatch(removeAssigneeFromProjectSuccess(response.data));
        toast.success(response.data.message);
      } else {
        dispatch(removeAssigneeFromProjectFailure());
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(removeAssigneeFromProjectFailure());
    }
  };

export const deleteProjectAttachment =
  (objectIdentifier, attachmentIdentifier, userIdentifier) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await httpHandler.post(
        `/api/project/deleteAttachmentFromProject`,
        { objectIdentifier, attachmentIdentifier, userIdentifier }
      );
      if (response.data.success) {
        dispatch(deleteAttachmentSuccess(attachmentIdentifier));
        dispatch(getProject(objectIdentifier));
        toast.success(response.data.message);
      } else {
        dispatch(deleteAttachmentFailure());
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(deleteAttachmentFailure());
      toast.error(error.message);
    }
  };

export const getUnAssignedEmpForProject =
  (search, page, pagesize, projectIdentifier, companyIdentifier) =>
  async (dispatch) => {
    try {
      //dispatch(setLoading(true));
      const response = await httpHandler.get(
        `/api/project/getUnAssignedEmpForProject?search=${search}&page=${page}&pagesize=${pagesize}&projectIdentifier=${projectIdentifier}&companyIdentifier=${companyIdentifier}`
      );
      if (response.data.success) {
        dispatch(getUnAssignedEmpForProjectSuccess(response.data));
      } else {
        dispatch(getUnAssignedEmpForProjectFailure());
      }
    } catch (error) {
      dispatch(getUnAssignedEmpForProjectFailure());
    }
  };

export const {
  getProjectsSuccess,
  getProjectsFailure,
  getProjectSuccess,
  getProjectFailure,
  deleteProjectSuccess,
  deleteAttachmentSuccess,
  deleteAttachmentFailure,
  addAttachmentSuccess,
  addAttachmentFailure,
  getUnAssignedEmpForProjectSuccess,
  getUnAssignedEmpForProjectFailure,
  projectAssignmentSuccess,
  projectAssignmentFailure,
  removeAssigneeFromProjectSuccess,
  removeAssigneeFromProjectFailure,
  setLoading,
} = projectSlice.actions;
export default projectSlice.reducer;
