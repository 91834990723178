import React, { useEffect, useState } from "react";
import { PlaceHolder, avatar_01, genericImg } from "../../../assets/img";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAllEmployeesforCompany } from "../../../features/employeeSlice";
import httpHandler from "../../../utils/httpHandler";
import { toast } from "react-hot-toast";
import { projectAssignment } from "../../../features/projectSlice";

function ProjectView() {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const { companyEmployees } = useSelector((state) => state.employee);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const state = {
    search: "",
    page: 1,
    pagesize: 100,
  };

  const formatDate = (date) => {
    return moment(date).format("DD-MMM-YYYY");
  };

  useEffect(() => {
    getProject();
  }, []);

  const getProject = () => {
    return new Promise((reject) => {
      httpHandler
        .get(`/api/project/getProject/${projectId}`)
        .then((response) => {
          if (response.data.success) {
            setProject(response.data.data);
            dispatch(
              getAllEmployeesforCompany(
                state.search,
                project ? project.companyIdentifier : "",
                state.page,
                state.pagesize
              )
            );
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleUserSelection = (employeeIdentifier) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(employeeIdentifier)) {
        return prevSelectedUsers.filter((id) => id !== employeeIdentifier);
      } else {
        return [...prevSelectedUsers, employeeIdentifier];
      }
    });
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("projectIdentifier", projectId);
    if (selectedUsers.length > 0) {
      for (let i = 0; i < selectedUsers.length; i++) {
        formData.append("employeeIdentifiers", selectedUsers[i]);
      }
      dispatch(projectAssignment(formData));
    }
  };

  return (
    <>
      {project ? (
        <>
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Project View</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/app/main/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Project</li>
                  </ul>
                </div>
                <div className="col-auto float-end ml-auto">
                  <Link
                    to={`/update-project/${projectId}`}
                    className="btn add-btn"
                  >
                    <i className="fa fa-plus" /> Edit Project
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <div className="project-title">
                      <h5 className="card-title">{project.projectName}</h5>
                    </div>
                    <p>{project.projectDescription}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title m-b-15">Project details</h6>
                    <table className="table table-striped table-border">
                      <tbody>
                        <tr>
                          <td>Budget:</td>
                          <td className="text-end">{project.budget}</td>
                        </tr>
                        <tr>
                          <td>Created:</td>
                          <td className="text-end">
                            {formatDate(project.startDate)}
                          </td>
                        </tr>
                        <tr>
                          <td>Deadline:</td>
                          <td className="text-end">
                            {formatDate(project.endDate)}
                          </td>
                        </tr>
                        <tr>
                          <td>Priority:</td>
                          <td className="text-end">{project.priority}</td>
                        </tr>
                        <tr>
                          <td>Project Leader:</td>
                          <td className="text-end">
                            <Link to="#">{project.ownerName}</Link>
                          </td>
                        </tr>
                        <tr>
                          <td>Status:</td>
                          <td className="text-end">{project.status}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="progress progress-xs mb-0">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        data-bs-toggle="tooltip"
                        title="40%"
                        style={{ width: "40%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card project-user">
                  <div className="card-body">
                    <h6 className="card-title m-b-20">Assigned Leader </h6>
                    <ul className="list-box">
                      <li>
                        <Link to={"#"}>
                          <div className="list-item">
                            <div className="list-left">
                              <span className="avatar">
                                <img alt="" src={genericImg} />
                              </span>
                            </div>
                            <div className="list-body">
                              <span className="message-author">
                                {project.ownerName}
                              </span>
                              <div className="clearfix" />
                              <span className="message-content">
                                Project Leader
                              </span>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card project-user">
                  <div className="card-body">
                    <h6 className="card-title m-b-20">
                      Assigned users
                      <button
                        type="button"
                        className="float-end btn btn-primary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#assign_user"
                      >
                        <i className="fa fa-plus" /> Add
                      </button>
                    </h6>
                    <ul className="list-box"></ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="assign_user"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Assign the user to this project
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="input-group m-b-30">
                    <input
                      placeholder="Search a user to assign"
                      className="form-control search-input"
                      type="text"
                    />
                    <span className="input-group-append">
                      <button className="btn btn-primary">Search</button>
                    </span>
                  </div>
                  <div>
                    <ul className="chat-user-list">
                      {companyEmployees.length > 0 ? (
                        companyEmployees.map((employee) => (
                          <li key={employee.employeeIdentifier}>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectedUsers.includes(
                                  employee.employeeIdentifier
                                )}
                                onChange={() =>
                                  handleUserSelection(
                                    employee.employeeIdentifier
                                  )
                                }
                              />
                              <Link to="#">
                                <div className="media">
                                  <span className="avatar">
                                    <img alt="" src={avatar_01} />
                                  </span>
                                  <div className="media-body align-self-center text-nowrap">
                                    <div className="user-name">
                                      {employee.name}
                                    </div>
                                    <span className="designation">
                                      {employee.designation}
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </label>
                          </li>
                        ))
                      ) : (
                        <p>Nothing Here!</p>
                      )}
                    </ul>
                  </div>
                  <div className="submit-section">
                    <button
                      className="btn btn-primary submit-btn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        "Something Went wrong."
      )}
    </>
  );
}

export default ProjectView;
