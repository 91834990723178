import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { orangeLogo } from '../../../assets/img';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactToPrint from 'react-to-print';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { CSVLink } from 'react-csv';
import { getInvoice } from '../../../features/invoiceSlice';

function InvoiceView() {
        const dispatch = useDispatch();
        const { invoiceIdentifier } = useParams();
        const { invoice } = useSelector((state) => state.invoice);
        const componentRef = useRef();

        useEffect(() => {
                dispatch(getInvoice(invoiceIdentifier));
        }, [dispatch, invoiceIdentifier]);

        console.log('invoice', invoice);

        const printPDF = () => {
                const input = document.getElementById('estimate-pdf');
                html2canvas(input, { scale: 2 }).then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF('p', 'mm', 'a4');
                        const imgWidth = 210;
                        const pageHeight = 297;
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;
                        let heightLeft = imgHeight;
                        let position = 0;

                        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;

                        while (heightLeft >= 0) {
                                position = heightLeft - imgHeight;
                                pdf.addPage();
                                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                                heightLeft -= pageHeight;
                        }

                        pdf.save('invoice.pdf');
                });
        };

        const columns = [
                {
                        title: '#',
                        render: (text, record, index) => <span>{index + 1}</span>,
                        sorter: (a, b) => a.id - b.id,
                },
                {
                        title: 'ITEM',
                        dataIndex: 'title',
                        render: (text, record) => <span>{record.title}</span>,
                },
                {
                        title: 'DESCRIPTION',
                        dataIndex: 'description',
                        render: (text, record) => <span>{record.description}</span>,
                },
                {
                        title: 'UNIT COST',
                        dataIndex: 'unitPrice',
                        render: (text, record) => <span>{record.unitPrice}</span>,
                },
                {
                        title: 'QUANTITY',
                        dataIndex: 'quantity',
                        render: (text, record) => <span>{record.quantity}</span>, // Corrected to render quantity
                },
                {
                        title: 'TOTAL',
                        dataIndex: 'subTotal',
                        render: (text, record) => <span>{record.subTotal}</span>, // Corrected to render subtotal
                },
        ];

        // Prepare CSV data
        const csvData = invoice?.items ? invoice.items.map((item, index) => ({
                '#': index + 1,
                'ITEM': item.title,
                'DESCRIPTION': item.description,
                'UNIT COST': item.unitPrice,
                'QUANTITY': item.quantity,
                'TOTAL': item.subTotal,
        })) : [];

        const csvHeaders = [
                { label: '#', key: '#' },
                { label: 'ITEM', key: 'ITEM' },
                { label: 'DESCRIPTION', key: 'DESCRIPTION' },
                { label: 'UNIT COST', key: 'UNIT COST' },
                { label: 'QUANTITY', key: 'QUANTITY' },
                { label: 'TOTAL', key: 'TOTAL' },
        ];

        return (
                <>
                        <Helmet>
                                <title>Invoice - HRMS Admin Template</title>
                                <meta name="description" content="Estimate page" />
                        </Helmet>
                        {/* Page Content */}
                        <div className="content container-fluid" id="estimate-content">
                                {/* Page Header */}
                                <div className="page-header">
                                        <div className="row align-items-center">
                                                <div className="col">
                                                        <h3 className="page-title">Invoice</h3>
                                                        <ul className="breadcrumb">
                                                                <li className="breadcrumb-item"><Link to="/app/main/dashboard">Dashboard</Link></li>
                                                                <li className="breadcrumb-item active">Invoice</li>
                                                        </ul>
                                                </div>
                                                <div className="col-auto float-end ml-auto">
                                                        <div className="btn-group btn-group-sm">
                                                                <CSVLink data={csvData} headers={csvHeaders} filename={"invoice.csv"} className="btn btn-white">CSV</CSVLink>
                                                                <button className="btn btn-white" onClick={printPDF}>PDF</button>
                                                                <ReactToPrint
                                                                        trigger={() => <button className="btn btn-white">Print</button>}
                                                                        content={() => componentRef.current}
                                                                />
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                {/* /Page Header */}
                                <div className="row" id='estimate-pdf' ref={componentRef}>
                                        <div className="col-md-12">
                                                <div className="card">
                                                        <div className="card-body">
                                                                <div className="row">
                                                                        <div className="col-sm-6 m-b-20">
                                                                                <img src={orangeLogo} className="inv-logo" alt="Company Logo" />
                                                                                <ul className="list-unstyled">
                                                                                        {/* <li>{invoice?.title}</li> */}
                                                                                        {/* <li>{invoice?.company[0]?.address}</li> */}
                                                                                        {/* <li>GST No: {invoice?.taxIdentificationNumber}</li> */}
                                                                                </ul>
                                                                        </div>
                                                                        <div className="col-sm-6 m-b-20">
                                                                                <div className="invoice-details">
                                                                                        <h3 className="text-uppercase">Invoice #49029</h3>
                                                                                        <ul className="list-unstyled">
                                                                                                <li>Create Date: <span>{new Date(invoice?.invoiceDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span></li>
                                                                                                <li>Expiry Date: <span>{new Date(invoice?.dueDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span></li>
                                                                                        </ul>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="row">
                                                                        <div className="col-sm-12 col-lg-12 m-b-20">
                                                                                <h5>Invoice to:</h5>
                                                                                <ul className="list-unstyled">
                                                                                        <li>{invoice?.clientAddress}</li>
                                                                                        <li><a href="mailto:barrycuda@example.com">{invoice?.email}</a></li>
                                                                                        <li>{invoice?.billingAddress}</li>
                                                                                </ul>
                                                                        </div>
                                                                </div>
                                                                <div className="table-responsive">
                                                                        <Table
                                                                                columns={columns}
                                                                                dataSource={Array.isArray(invoice?.items) ? invoice.items : []}
                                                                                rowKey={(record) => record?.invoiceIdentifier}
                                                                        />
                                                                </div>
                                                                <div>
                                                                        <div className="row invoice-payment">
                                                                                <div className="col-sm-7">
                                                                                </div>
                                                                                <div className="col-sm-5">
                                                                                        <div className="m-b-20">
                                                                                                <div className="table-responsive no-border">
                                                                                                        <table className="table">
                                                                                                                <tbody>
                                                                                                                        <tr>
                                                                                                                                <th>Subtotal:</th>
                                                                                                                                <td className="text-end">{invoice?.overAllTotal}</td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                                <th>Tax: <span className="text-regular">(25%)</span></th>
                                                                                                                                <td className="text-end">{invoice?.grandTotal}</td>
                                                                                                                        </tr>
                                                                                                                        <tr>
                                                                                                                                <th>Total:</th>
                                                                                                                                <td className="text-end text-primary"><h5>{invoice?.grandTotal}</h5></td>
                                                                                                                        </tr>
                                                                                                                </tbody>
                                                                                                        </table>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className="invoice-info">
                                                                                <h5>Other information</h5>
                                                                                <p className="text-muted">{invoice?.otherInformation}</p>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        );
}

export default InvoiceView;
