import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import httpHandler from '../utils/httpHandler';

const resignationSlice = createSlice({
        name: 'resignation',
        initialState: {
                allResignation: [],
                isLoading: false,
                count: 0,
                resignation: null
        },
        reducers: {
                getAllResignationSuccess: (state, action) => {
                        state.isLoading = false;
                        state.allResignation = action.payload;
                },
                getAllResignationFailure: (state, action) => {
                        state.isLoading = false;
                        state.allResignation = []
                },
                addResignationSuccess: (state, action) => {
                        state.isLoading = false;
                },
                addResignationFailure: (state, action) => {
                        state.isLoading = false;
                },
                getResignationSuccess: (state, action) => {
                        state.isLoading = false;
                        state.resignation = action.payload;
                },
                getResignationFailure: (state, action) => {
                        state.isLoading = false;

                },
                deleteResignationSuccess: (state, action) => {
                        state.isLoading = false;
                },
                deleteResignationFailure: (state, action) => {
                        state.isLoading = false;
                },
                updateResignationSuccess: (state, action) => {
                        state.isLoading = false;
                },
                updateResignationFailure: (state, action) => {
                        state.isLoading = false;
                },
                setLoading: (state, action) => {
                        state.isLoading = true
                }
        }
});

export const getAllResignation = () => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/resignation/all`);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(getAllResignationSuccess(response?.data?.data));
                }
                else {
                        dispatch(getAllResignationFailure());
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(getAllResignationFailure());
        }
}

export const addResignation = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/resignation/add-resignation`, data);
                if (response.data.success) {
                        toast.success(response.data.success);
                        dispatch(addResignationSuccess(response));
                }
                else {
                        dispatch(addResignationFailure());
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(addResignationFailure());
        }
}

export const deleteResignation = (identifier) => async (dispatch) => {
        try {
                const response = await httpHandler.get(`/api/resignation/delete/${identifier}`)
                if (response.data.success) {
                        toast.success(response.data.message)
                        dispatch(deleteResignationSuccess())
                } else {
                        dispatch(deleteResignationFailure())
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(deleteResignationFailure())
        }
}

export const getResignation = (identifier) => async (dispatch) => {
        try {
                const response = await httpHandler.get(`/api/resignation/get/${identifier}`)
                if (!response.data.success) {
                        dispatch(getResignationSuccess(response.data.data))
                } else {
                        dispatch(getResignationFailure())
                }
        }
        catch (error) {
                toast.error(error.message)
                dispatch(getResignationFailure())
        }
}

export const updateResignation = (data) => async (dispatch) => {
        try {
                const response = await httpHandler.post(`/api/resignation/update-resignation`, data);
                if (response.data.success) {
                        toast.success(response.data.message)
                        dispatch(updateResignationSuccess())
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(updateResignationFailure())
        }
}

export const {
        getAllResignationSuccess, getAllResignationFailure,
        addResignationSuccess, addResignationFailure,
        getResignationSuccess, getResignationFailure,
        deleteResignationSuccess, deleteResignationFailure,
        updateResignationSuccess, updateResignationFailure,
        setLoading
} = resignationSlice.actions;

export default resignationSlice.reducer;

