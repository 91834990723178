import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { addLeave } from '../../../features/leaveSlice';
import { useDispatch } from 'react-redux';
import { getAllEmployees } from '../../../features/employeeSlice';
import { useSelector } from 'react-redux';

// Define the validation schema using Yup
const leaveSchema = yup.object({
        leaveType: yup.string().required('Leave type is required'),
        from: yup.date().required('From date is required'),
        to: yup.date().required('To date is required'),
        reason: yup.string().required('Leave reason is required'),
        createdBy: yup.string().required('Employee name is required'),
}).required();

export default function CreateLeave({ showModal, closeModal }) {
        const dispatch = useDispatch();
        const { employees } = useSelector((state) => state.employee)
        const { control, handleSubmit, formState: { errors } } = useForm({
                resolver: yupResolver(leaveSchema),
                defaultValues: {
                        leaveType: '',
                        from: '',
                        to: '',
                        reason: '',
                        createdBy: ''
                }

        });

        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10
        })

        useEffect(() => {
                dispatch(getAllEmployees(state.search, state.page, state.pagesize))
        }, [dispatch]);


        const employeeOptions = employees.map(employee => ({
                value: employee.employeeIdentifier,
                label: employee.name
        }));

        const leaveOptions = [
                { value: 'casual', label: 'Casual Leave 12 Days' },
                { value: 'medical', label: 'Medical Leave' },
                { value: 'loss', label: 'Loss of Pay' }
        ];

        const onSubmit = (data) => {
                const arr = [
                        new Date(data.from).toISOString()
                ]
                const formData = new FormData();
                formData.append('reason', data.reason);
                formData.append('createdBy', data.createdBy);
                formData.append('leaveType', data.leaveType);
                formData.append('leaveIdentifier', null);
                formData.append('status', 'pending');
                formData.append('leaveOn', arr);

                dispatch(addLeave(formData));
        };


        return (
                <>
                        {showModal && (
                                <>
                                        <div className="modal-backdrop fade show"></div>
                                        <div
                                                className={`modal custom-modal fade ${showModal ? "show" : ""}`}
                                                style={{ display: showModal ? "block" : "none" }}
                                                aria-modal="true"
                                                role="dialog"
                                        >
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">
                                                                <div className="modal-header">
                                                                        <h5 className="modal-title">Add Leave</h5>
                                                                        <button type="button" className="btn-close" onClick={closeModal}></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                                                <div className="form-group">
                                                                                        <label>Employee <span className="text-danger">*</span></label>
                                                                                        <Controller
                                                                                                name="createdBy"
                                                                                                control={control}
                                                                                                render={({ field: { value, onChange } }) => (
                                                                                                        <ReactSelect
                                                                                                                options={employeeOptions}
                                                                                                                value={employeeOptions.find(option => option.value === value)}
                                                                                                                onChange={selected => onChange(selected?.value)}
                                                                                                                isClearable
                                                                                                                isSearchable
                                                                                                                className="company-box"
                                                                                                        />
                                                                                                )}
                                                                                        />
                                                                                        <small style={{ color: 'red' }}>{errors?.createdBy?.message}</small>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                        <label>Leave Type <span className="text-danger">*</span></label>
                                                                                        <Controller
                                                                                                name="leaveType"
                                                                                                control={control}
                                                                                                render={({ field: { value, onChange } }) => (
                                                                                                        <ReactSelect
                                                                                                                options={leaveOptions}
                                                                                                                value={leaveOptions.find(option => option.value === value)}
                                                                                                                onChange={selected => onChange(selected?.value)}
                                                                                                                isClearable
                                                                                                                isSearchable
                                                                                                                className="company-box"
                                                                                                        />
                                                                                                )}
                                                                                        />
                                                                                        <small style={{ color: 'red' }}>{errors?.leaveType?.message}</small>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                        <label>From <span className="text-danger">*</span></label>
                                                                                        <Controller
                                                                                                name="from"
                                                                                                control={control}
                                                                                                render={({ field }) => (
                                                                                                        <input
                                                                                                                {...field}
                                                                                                                className={`form-control ${errors.from ? 'is-invalid' : ''}`}
                                                                                                                type="date"
                                                                                                        />
                                                                                                )}
                                                                                        />
                                                                                        <small style={{ color: 'red' }}>{errors?.from?.message}</small>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                        <label>To <span className="text-danger">*</span></label>
                                                                                        <Controller
                                                                                                name="to"
                                                                                                control={control}
                                                                                                render={({ field }) => (
                                                                                                        <input
                                                                                                                {...field}
                                                                                                                className={`form-control ${errors.to ? 'is-invalid' : ''}`}
                                                                                                                type="date"
                                                                                                        />
                                                                                                )}
                                                                                        />
                                                                                        <small style={{ color: 'red' }}>{errors?.to?.message}</small>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                        <label>Leave Reason <span className="text-danger">*</span></label>
                                                                                        <Controller
                                                                                                name="reason"
                                                                                                control={control}
                                                                                                render={({ field }) => (
                                                                                                        <textarea
                                                                                                                {...field}
                                                                                                                className={`form-control ${errors.reason ? 'is-invalid' : ''}`}
                                                                                                                rows="4"
                                                                                                        />
                                                                                                )}
                                                                                        />
                                                                                        <small style={{ color: 'red' }}>{errors?.reason?.message}</small>
                                                                                </div>
                                                                                <div className="submit-section">
                                                                                        <button type="submit" className="btn btn-primary submit-btn">Submit</button>
                                                                                </div>
                                                                        </form>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </>
                        )}
                </>
        );
}
