import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteProject, getAllProjects } from "../../../features/projectSlice";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";

const Projects = () => {
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.project);
  const [state, setState] = useState({
    search: "",
    page: 1,
    pagesize: 10,
  });
  useEffect(() => {
    const isCompany = false;
    const allProjects = async () => {
      dispatch(
        getAllProjects(state.search, state.page, state.pagesize, isCompany)
      );
    };
    allProjects();
  }, [state.search, state.page, state.pagesize]);

  const handleDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteProject(id));
  };
  const formatDate = (date) => {
    return moment(date).format("DD-MMM-YYYY");
  };
  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Projects</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/main/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Projects</li>
              </ul>
            </div>
            <div className="col-auto float-end ml-auto">
              <Link to="/admin/create-project" className="btn add-btn">
                <i className="fa fa-plus" /> Create Project
              </Link>
              <div className="view-icons">
                <Link to="/admin/projects-list" className="list-view btn btn-link">
                  <i className="fa fa-bars" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row filter-row">
          <div className="col-sm-6 col-md-3">
            <div className="form-group form-focus">
              <input
                type="text"
                className="form-control floating"
                onChange={(e) =>
                  setState({
                    ...state,
                    search: e.target.value ? e.target.value.toLowerCase() : "",
                  })
                }
              />
              <label className="focus-label">Search...</label>
            </div>
          </div>
        </div>
        <div className="row">
          {projects.length > 0
            ? projects.map((project) => (
                <div
                  className="col-lg-4 col-sm-6 col-md-4 col-xl-3"
                  key={project.projectIdentifier}
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="dropdown dropdown-action profile-action">
                        <Link
                          to="#"
                          className="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="material-icons">more_vert</i>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link
                            to={`/admin/update-project/${project.projectIdentifier}`}
                            className="dropdown-item"
                          >
                            <i className="fa fa-pencil m-r-5" /> Edit
                          </Link>
                          <Link
                            className="dropdown-item"
                            to={"#"}
                            onClick={() => {
                              handleDeleteClick(project.projectIdentifier);
                            }}
                          >
                            <i className="fa fa-trash-o m-r-5" /> Delete
                          </Link>
                        </div>
                      </div>
                      <h4 className="project-title">
                        <Link to={`/admin/project-view/${project.projectIdentifier}`}>
                          {project.projectName}
                        </Link>
                      </h4>
                      <p className="text-muted">{project.projectDescription}</p>
                      <div className="pro-deadline m-b-15">
                        <div className="sub-title">Deadline:</div>
                        <div className="text-muted">
                          {formatDate(project.endDate)}
                        </div>
                      </div>
                      <div className="project-members m-b-15">
                        <div>Project Leader :</div>
                        <ul className="team-members">
                          <li>
                            <div className="text-muted">
                              {project.ownerName}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </>
  );
};

export default Projects;
