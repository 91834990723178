import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { deleteLeave, getAllLeave, getLeaveById, leaveApprovelUpdate } from '../../../features/leaveSlice';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import CreateLeave from './CreateLeave';
import EditLeave from './EditLeave';

export default function AllLeave() {
        const dispatch = useDispatch();
        const [showModal, setShowModal] = useState(false);
        const [showEditModal, setShowEditModal] = useState(false);
        const { allLeave, count } = useSelector((state) => state.leave);
        const { companyID } = useParams();

        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10
        });

        console.log('companyID', allLeave);

        const openModal = () => setShowModal(true);
        const closeModal = () => setShowModal(false);

        useEffect(() => {
                dispatch(getAllLeave())
        }, [dispatch]);

        const handleDeleteClick = id => {
                confirmAlert({
                        title: 'Confirm to delete',
                        message: 'Are you sure to do this.',
                        buttons: [
                                {
                                        label: 'Yes',
                                        onClick: () => handleDelete(id),
                                },
                                {
                                        label: 'No',
                                },
                        ],
                });
        };

        const handleDelete = id => {
                dispatch(deleteLeave(id));
        }

        const handleStatusChange = (newStatus, companyID, leaveIdentifier) => {
                dispatch(leaveApprovelUpdate(newStatus, companyID, leaveIdentifier));
        };

        const onShowSizeChange = (current, pageSize) => {
                setState({ ...state, page: 1, pagesize: pageSize });
        };

        const itemRender = (current, type, originalElement) => {
                if (type === 'prev') {
                        return <button className="btn btn-sm btn-primary">Previous</button>;
                }
                if (type === 'next') {
                        return <button className="btn btn-sm btn-primary">Next</button>;
                }
                return originalElement;
        };

        const columns = [
                {
                        title: 'Employee',
                        dataIndex: 'employee',
                        render: (text, record) => <span>{record.employee}</span>,
                        sorter: (a, b) => a.employee.length - b.employee.length
                },
                {
                        title: 'Leave Type',
                        dataIndex: 'leaveType',
                        render: (text, record) => <span>{record.leaveType}</span>,
                        sorter: (a, b) => a.leaveType - b.leaveType
                },
                {
                        title: 'From',
                        dataIndex: 'taxRate',
                        render: (text, record) => <span>{record.taxRate}</span>,
                        sorter: (a, b) => a.taxRate - b.taxRate
                },
                {
                        title: 'To',
                        dataIndex: 'taxRate',
                        render: (text, record) => <span>{record.taxRate}</span>,
                        sorter: (a, b) => a.taxRate - b.taxRate
                },
                {
                        title: 'No of Days',
                        dataIndex: 'taxRate',
                        render: (text, record) => <span>{record.taxRate}</span>,
                        sorter: (a, b) => a.taxRate - b.taxRate
                },
                {
                        title: 'Reason',
                        dataIndex: 'reason',
                        render: (text, record) => <span>{record.reason}</span>,
                        sorter: (a, b) => a.reason - b.reason
                },
                {
                        title: 'Status',
                        dataIndex: 'isActive',
                        render: (text, record) => (
                                <div className="dropdown action-label">
                                        <a
                                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                href="#"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="true"
                                        >
                                                <i className={`fa fa-dot-circle-o ${record.isActive ? 'text-success' : 'text-danger'}`}></i>
                                                {record.isActive ? 'Approved' : 'Pending'}
                                        </a>
                                        <div className="dropdown-menu">
                                                <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleStatusChange('Approved', companyID, record.leaveIdentifier)}

                                                >
                                                        <i className="fa fa-dot-circle-o text-success"></i> Active
                                                </span>
                                                <span onClick={() => handleStatusChange('Pending', companyID, record.leaveIdentifier)}
                                                        className="dropdown-item" style={{ cursor: 'pointer' }} >
                                                        <i className="fa fa-dot-circle-o text-danger"></i> Inactive
                                                </span>
                                        </div>
                                </div>
                        ),
                },
                {
                        title: "Action",
                        render: (text, record) => (
                                <div
                                        className="d-flex justify-content-around"
                                        data-popper-placement="bottom-end"
                                >
                                        <Link
                                                className="dropdown-item px-2 text-success"
                                                onClick={() => {
                                                        dispatch(getLeaveById(record.leaveIdentifier));
                                                        setShowEditModal(true);
                                                }}
                                        >
                                                <i className="fa fa-pencil mr-2" />
                                        </Link>
                                        <Link
                                                className="dropdown-item px-2 text-danger"
                                                to={"#"}
                                                onClick={() => {
                                                        handleDeleteClick(record.leaveIdentifier);
                                                }}
                                        >
                                                <i className="fa fa-trash-o m-r-5" />
                                        </Link>
                                </div>
                        ),
                },
        ];


        return (
                <div className="content container-fluid">

                        <div className="page-header">
                                <div className="row align-items-center">
                                        <div className="col">
                                                <h3 className="page-title">Leaves</h3>
                                                <ul className="breadcrumb">
                                                        <li className="breadcrumb-item"><a href="admin-dashboard.html">Dashboard</a></li>
                                                        <li className="breadcrumb-item active">Leaves</li>
                                                </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                                <a href="#" className="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_leave" onClick={openModal}><i className="fa fa-plus"></i> Add Leave</a>
                                        </div>
                                </div>
                        </div>
                        <div className="row">
                                <div className="col-md-3">
                                        <div className="stats-info">
                                                <h6>Today Presents</h6>
                                                <h4>12 / 60</h4>
                                        </div>
                                </div>
                                <div className="col-md-3">
                                        <div className="stats-info">
                                                <h6>Planned Leaves</h6>
                                                <h4>8 <span>Today</span></h4>
                                        </div>
                                </div>
                                <div className="col-md-3">
                                        <div className="stats-info">
                                                <h6>Unplanned Leaves</h6>
                                                <h4>0 <span>Today</span></h4>
                                        </div>
                                </div>
                                <div className="col-md-3">
                                        <div className="stats-info">
                                                <h6>Pending Requests</h6>
                                                <h4>12</h4>
                                        </div>
                                </div>
                        </div>
                        <div className="row filter-row">
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus">
                                                <input type="text" className="form-control floating" />
                                                <label className="focus-label">Employee Name</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus select-focus focused">
                                                <select className="select floating select2-hidden-accessible" data-select2-id="select2-data-1-e0mr" tabIndex="-1" aria-hidden="true">
                                                        <option data-select2-id="select2-data-3-5vg7"> -- Select -- </option>
                                                        <option>Casual Leave</option>
                                                        <option>Medical Leave</option>
                                                        <option>Loss of Pay</option>
                                                </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="select2-data-2-z1r1" style={{ width: "100%" }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false" aria-labelledby="select2-ahyi-container" aria-controls="select2-ahyi-container"><span className="select2-selection__rendered" id="select2-ahyi-container" role="textbox" aria-readonly="true" title=" -- Select -- "> -- Select -- </span>
                                                        <span
                                                                className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                                                </span>
                                                </span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                                                <label className="focus-label">Leave Type</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus select-focus focused">
                                                <select className="select floating select2-hidden-accessible" data-select2-id="select2-data-4-4wxi" tabIndex="-1" aria-hidden="true">
                                                        <option data-select2-id="select2-data-6-f7p3"> -- Select -- </option>
                                                        <option> Pending </option>
                                                        <option> Approved </option>
                                                        <option> Rejected </option>
                                                </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="select2-data-5-l9mh" style={{ width: "100%" }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false" aria-labelledby="select2-dxiw-container" aria-controls="select2-dxiw-container"><span className="select2-selection__rendered" id="select2-dxiw-container" role="textbox" aria-readonly="true" title=" -- Select -- "> -- Select -- </span>
                                                        <span
                                                                className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                                                </span>
                                                </span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                                                <label className="focus-label">Leave Status</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus">
                                                <div className="cal-icon">
                                                        <input className="form-control floating datetimepicker" type="text" />
                                                </div>
                                                <label className="focus-label">From</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus">
                                                <div className="cal-icon">
                                                        <input className="form-control floating datetimepicker" type="text" />
                                                </div>
                                                <label className="focus-label">To</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <a href="#" className="btn btn-success w-100"> Search </a>
                                </div>
                        </div>

                        <div className="row">
                                <div className="col-md-12">
                                        <div className="table-responsive">
                                                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                        <div className="row">
                                                                <div className="col-sm-12 col-md-6">
                                                                        <div className="dataTables_length" id="DataTables_Table_0_length">
                                                                                <label>Show
                                                                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm">
                                                                                                <option value="10">10</option>
                                                                                                <option value="25">25</option>
                                                                                                <option value="50">50</option>
                                                                                                <option value="100">100</option>
                                                                                        </select> entries</label>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6"></div>
                                                        </div>

                                                        <div className="row">
                                                                <div className="col-sm-12">
                                                                        <div className='table-responsive'>
                                                                                <Table
                                                                                        columns={columns}
                                                                                        dataSource={allLeave}
                                                                                        rowKey={(record) => record?.leaveIdentifier}
                                                                                        pagination={{
                                                                                                current: state.page,
                                                                                                pageSize: state.pagesize,
                                                                                                total: count,
                                                                                                showTotal: (total, range) =>
                                                                                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                                                                showSizeChanger: true,
                                                                                                onShowSizeChange: onShowSizeChange,
                                                                                                itemRender: itemRender,
                                                                                                onChange: (page, pageSize) =>
                                                                                                        setState({ ...state, page, pagesize: pageSize }),
                                                                                        }}
                                                                                />
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                        <CreateLeave showModal={showModal} closeModal={closeModal} />
                        <EditLeave showModal={showEditModal} closeModal={() => setShowEditModal(false)} />
                </div >
        )
}
