import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { emailrgx } from '../../../constant';
import { getAllClients, getAllClientsforCompany } from '../../../features/clientSlice';
import { addEstimate, addEstimateSent, getAllTaxTypes, getEstimate, updateEstimate } from '../../../features/estimateSlice';
import { getAllProjects } from '../../../features/projectSlice';
import { getAllCompanies } from '../../../features/companySlice';
import Loader from '../../../components/loader/Loader';
import moment from 'moment';

const schema = yup.object({
    billingAddress: yup.string().required('Billing Address is required').trim(),
    clientId: yup.string(),
    createDate: yup.date().required('Estimate Date is required'),
    email: yup.string().required('Email is required').matches(emailrgx, 'Invalid Email').trim(),
    expirationDate: yup.date().required('Expiration Date is required'),
    otherInfo: yup.string(),
    tax: yup.string().required('Tax is required').trim(),
    title: yup.string().required('Title is required').trim(),
    discount: yup.number().required('Discount is required'),
}).required();

function EditEstimate() {
    const id = useParams();
    const [isSend, setIsSend] = useState(false)
    const identifier = id?.estimateIdentifier;
    const navigate = useNavigate()
    const [overallTotal, setOverallTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [taxPercentage, setTaxPercentage] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const dispatch = useDispatch();
    const { estimate } = useSelector(state => state.estimates);
    const { companyClients } = useSelector(state => state.client);
    const { taxTypes } = useSelector(state => state.estimates);
    const { projects } = useSelector(state => state.project);
    const { companiesList } = useSelector(state => state.company);
    const { isLoading } = useSelector((state) => state.estimates);
    const [discount, setDiscount] = useState(0);
    const selectedCompanyIdentifier = estimate?.company?.[0]?.companyIdentifier;
    const [isDelay, setIsDelay] = useState(true);

    const state = {
        search: '',
        page: 1,
        pagesize: 10
    };

    const { register, formState: { errors }, handleSubmit, control, getValues, setValue } = useForm({
        defaultValues: { items: [{ title: '', description: '', unitPrice: 0, quantity: 0, subTotal: 0 }] },
        resolver: yupResolver(schema),
    });

    const { fields, append, remove } = useFieldArray({ name: 'items', control });

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsDelay(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        dispatch(getEstimate(identifier));
    }, [dispatch, identifier]);

    useEffect(() => {
        dispatch(getAllCompanies(state.search, state.page, state.pagesize, companiesList))
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllClientsforCompany(selectedCompanyId, state.search, state.page, state.pagesize, true));
    }, [state.search, state.page, state.pagesize, true, selectedCompanyId]);

    useEffect(() => {
        dispatch(getAllProjects(state.search, state.page, state.pagesize, true, estimate?.company[0]?.companyIdentifier))
    }, [dispatch, state.search, state.page, state.pagesize, true, estimate?.company[0]?.companyIdentifier]);

    useEffect(() => {
        setValue('items', estimate?.items || [{ title: '', description: '', unitPrice: 0, quantity: 0, subTotal: 0, itemIdentifier: '' }]);
    }, [estimate, setValue]);

    useEffect(() => {
        if (estimate) {
            const selectedTax = taxTypes.find(type => type.id === estimate.tax);
            setTaxPercentage(selectedTax?.taxRate || 0);
        }
    }, [estimate, taxTypes]);

    useEffect(() => {
        setDiscount(estimate?.discount);
    });

    useEffect(() => {
        calculateInitialTotals();
    }, [fields]);


    const amountCalculation = (index, unitPrice, quantity) => {
        const subTotal = unitPrice * quantity || 0;
        setValue(`items[${index}].subTotal`, subTotal);

        calculateInitialTotals();

        const discountValue = parseFloat(getValues('discount')) || 0;
        applyDiscount(discountValue);
    };

    const calculateInitialTotals = () => {
        let initialOverallTotal = 0;

        // Loop through items and recalculate the overall total
        fields.forEach((field, i) => {
            initialOverallTotal += parseFloat(getValues(`items[${i}].subTotal`)) || 0;
        });

        const initialTaxAmount = (initialOverallTotal * taxPercentage) / 100;
        const discountAmount = initialOverallTotal + initialTaxAmount;
        const actualDiscount = (discountAmount * discount) / 100;

        setOverallTotal(initialOverallTotal);
        setGrandTotal(initialOverallTotal + initialTaxAmount - actualDiscount);
    };

    const applyDiscount = (value) => {
        const discountValue = value !== null && value !== '' ? parseFloat(value) : 0;

        const taxAmount = (overallTotal * taxPercentage) / 100;
        const totalBeforeDiscount = overallTotal + taxAmount;

        const discountedAmount = (totalBeforeDiscount * discountValue) / 100;
        setGrandTotal(totalBeforeDiscount - discountedAmount);
    };

    const removeItem = (index) => {
        remove(index);

        let newOverallTotal = 0;
        fields.forEach((field, i) => {
            const subTotal = parseFloat(getValues(`items[${i}].subTotal`)) || 0;
            newOverallTotal += subTotal;
        });

        const taxAmount = (newOverallTotal * taxPercentage) / 100;
        setOverallTotal(newOverallTotal);

        const totalBeforeDiscount = newOverallTotal + taxAmount;
        setTotal(totalBeforeDiscount);

        const discountValue = parseFloat(getValues('discount')) || 0;
        applyDiscount(discountValue);
    };

    useEffect(() => {
        if ($('.select').length > 0) {
            $('.select').select2({ minimumResultsForSearch: -1, width: '100%' });
        }
    });

    useEffect(() => {
        dispatch(getAllTaxTypes());
    }, []);


    const clientOptions = companyClients.map(client => ({
        value: client.clientIdentifier,
        label: client.clientName
    }));

    const companyOptions = companiesList.map(company => ({
        value: company.companyIdentifier,
        label: company.name
    }));

    const taxOptions = taxTypes.map(type => ({
        value: type.id,
        label: type.taxName
    }));

    const projectOptions = projects.map(project => ({
        value: project.projectIdentifier,
        label: project.projectName
    }));

    const onsubmit = (data) => {
        data.grandTotal = grandTotal;
        data.overallTotal = overallTotal;

        const createDate = new Date(data.createDate);
        const expirationDate = new Date(data.expirationDate);

        const companyArray = companiesList
            .filter(company => company.companyIdentifier === data.companyIdentifier)
            .map(company => ({
                companyIdentifier: company.companyIdentifier,
            }));

        const estimateData = {
            title: data.title,
            clientIdentifier: data.clientIdentifier,
            projectIdentifier: data?.projectIdentifier,
            company: companyArray,
            email: data.email,
            createDate: new Date(createDate.getTime() - createDate.getTimezoneOffset() * 60000).toISOString(),
            expirationDate: new Date(expirationDate.getTime() - expirationDate.getTimezoneOffset() * 60000).toISOString(),
            tax: data.tax,
            billingAddress: data.billingAddress,
            items: data.items,
            otherInformation: data.otherInfo,
            discount: data.discount,
            overallTotal: data.overallTotal,
            grandTotal: data.grandTotal,
            clientAddress: data?.clientAddress,
            estimateIdentifier: identifier
        };

        dispatch(updateEstimate(estimateData, isSend));
    };


    return (
        <>
            <Helmet>
                <title>Edit Estimate - HRMS Admin Template</title>
                <meta name="description" content="Login page" />
            </Helmet>
            {
                isLoading || isDelay ? (
                    <Loader />
                )
                    :
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h3 className="page-title">Edit Estimate</h3>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/app/main/dashboard">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Edit Estimate</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <form onSubmit={handleSubmit(onsubmit)}>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <Controller
                                                    name="title"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control mb-0 ${errors?.title ? "error-input mb-0" : ""
                                                                }`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue={estimate?.title}
                                                />
                                                <small>{errors?.title?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Company</label>
                                                <Controller
                                                    name="companyIdentifier"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <ReactSelect
                                                            options={companyOptions}
                                                            value={companyOptions.find(option => option.value === value)}
                                                            isClearable
                                                            isSearchable
                                                            className="company-box"
                                                            onChange={(selected) => {
                                                                const selectedValue = selected ? selected.value : "";
                                                                onChange(selectedValue); // Update react-hook-form state
                                                                setSelectedCompanyId(selectedValue); // Update local state
                                                            }}
                                                        />
                                                    )}
                                                    defaultValue={selectedCompanyIdentifier}
                                                />
                                                <small>{errors?.clientId?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Client</label>
                                                <Controller
                                                    name="clientIdentifier"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <CreatableSelect
                                                            options={clientOptions}
                                                            onChange={selected => {
                                                                onChange(selected?.value);
                                                            }}
                                                            value={clientOptions.find(option => option.value === value)}
                                                        />
                                                    )}
                                                    defaultValue={estimate?.clientIdentifier}
                                                />
                                                <small>{errors?.clientId?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Project <span className="text-danger">*</span></label>
                                                <Controller
                                                    name="projectIdentifier"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <ReactSelect
                                                            options={projectOptions}
                                                            value={projectOptions.find(option => option.value === value)}
                                                            isClearable
                                                            isSearchable
                                                            className="company-box"
                                                            onChange={selected => {
                                                                onChange(selected?.value);
                                                            }}
                                                        />
                                                    )}
                                                    defaultValue={estimate?.projectIdentifier}
                                                />
                                                {errors.projectIdentifier && <p className="error text-danger">{errors?.projectIdentifier?.message}</p>}
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control mb-0 ${errors?.email ? "error-input mb-0" : ""
                                                                }`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue={estimate?.email}
                                                />
                                                <small>{errors?.email?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Estimate Date</label>
                                                <Controller
                                                    name="createDate"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control mb-0 ${errors?.createDate ? "error-input mb-0" : ""
                                                                }`}
                                                            type="date"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue={moment(estimate?.createDate).format('YYYY-MM-DD')}

                                                />
                                                <small>{errors?.createDate?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Expiration Date</label>
                                                <Controller
                                                    name="expirationDate"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control mb-0 ${errors?.expirationDate ? "error-input mb-0" : ""
                                                                }`}
                                                            type="date"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue={moment(estimate?.expirationDate).format('YYYY-MM-DD')}
                                                />
                                                <small>{errors?.expirationDate?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Tax</label>
                                                <Controller
                                                    name="tax"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <ReactSelect
                                                            options={taxOptions}
                                                            value={taxOptions.find(option => option.value === value)}
                                                            onChange={selected => {
                                                                const selectedTax = taxTypes.find(type => type.id === selected?.value);
                                                                setTaxPercentage(selectedTax?.taxRate || 0);
                                                                onChange(selected?.value);
                                                            }}
                                                            isClearable
                                                            isSearchable
                                                            className="company-box"
                                                        />
                                                    )}
                                                    defaultValue={estimate?.tax}
                                                />
                                                <small>{errors?.tax?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Billing Address</label>
                                                <Controller
                                                    name="billingAddress"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <textarea
                                                            className={`form-control mb-0 ${errors?.billingAddress ? "error-input mb-0" : ""
                                                                }`}
                                                            type="date"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue={estimate?.billingAddress}
                                                />
                                                <small>{errors?.billingAddress?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>Client Address</label>
                                                <Controller
                                                    name="clientAddress"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <textarea
                                                            className={`form-control mb-0 ${errors?.billingAddress ? "error-input mb-0" : ""
                                                                }`}
                                                            type="date"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue={estimate?.clientAddress}
                                                />
                                                <small>{errors?.clientAddress?.message}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="table-responsive">
                                                <table className="table table-hover table-white">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '20px' }}>#</th>
                                                            <th className="col-sm-2">Item</th>
                                                            <th className="col-md-6">Description</th>
                                                            <th style={{ width: '100px' }}>Unit Cost</th>
                                                            <th style={{ width: '80px' }}>Qty</th>
                                                            <th>Amount</th>
                                                            <th> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fields?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        name={`title-${index}`}
                                                                        className="form-control"
                                                                        type="text"
                                                                        style={{ minWidth: '150px' }}
                                                                        {...register(`items[${index}].title`)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        style={{ minWidth: '150px' }}
                                                                        {...register(`items[${index}].description`)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        style={{ width: '100px' }}
                                                                        {...register(`items[${index}].unitPrice`)}
                                                                        onChange={(e) => {
                                                                            const unitPrice = parseFloat(e.target.value) || 0;
                                                                            const quantity = parseFloat(getValues(`items[${index}].quantity`)) || 0;
                                                                            amountCalculation(index, unitPrice, quantity);
                                                                        }}
                                                                        min="0"
                                                                        required
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        style={{ width: '80px' }}
                                                                        {...register(`items[${index}].quantity`)}
                                                                        onChange={(e) => {
                                                                            const quantity = parseFloat(e.target.value) || 0;
                                                                            const unitPrice = parseFloat(getValues(`items[${index}].unitPrice`)) || 0;
                                                                            amountCalculation(index, unitPrice, quantity);
                                                                        }}
                                                                        min="0"
                                                                        required
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        style={{ width: '120px' }}
                                                                        {...register(`items[${index}].subTotal`)}
                                                                        readOnly
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link text-danger font-18"
                                                                        title="Remove"
                                                                        onClick={() => removeItem(index)}
                                                                    >
                                                                        <i className="fa fa-trash-o" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary"
                                                    onClick={() => {
                                                        append({
                                                            name: "",
                                                            description: "",
                                                            unitPrice: 0,
                                                            quantity: 0,
                                                            subTotal: 0
                                                        });
                                                    }}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-hover table-white">
                                                    <tbody>
                                                        <tr>
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td className="text-end">Total</td>
                                                            <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>
                                                                {overallTotal}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className="text-end">Tax</td>
                                                            <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>{estimate?.tax} %</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className="text-end">
                                                                Discount %
                                                            </td>
                                                            <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>
                                                                <Controller
                                                                    name="discount"
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <input
                                                                            className={`form-control text-end`}
                                                                            type="number"
                                                                            value={value}
                                                                            onChange={(e) => {
                                                                                onChange(e);
                                                                                applyDiscount(e.target.value);
                                                                            }}
                                                                            min="0"
                                                                            max="90"
                                                                            autoComplete="false"
                                                                        />
                                                                    )}
                                                                    defaultValue={estimate?.discount}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                Grand Total
                                                            </td>
                                                            <td style={{ textAlign: 'right', paddingRight: '30px', fontWeight: 'bold', fontSize: '16px', width: '230px' }}>
                                                                $
                                                                {grandTotal.toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Other Information</label>
                                                        <Controller
                                                            name="otherInfo"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <textarea
                                                                    className={`form-control ${errors?.otherInfo ? "error-input mb-0" : ""
                                                                        }`}
                                                                    rows={4}
                                                                    type="date"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue={estimate?.otherInformation}
                                                        />
                                                        <small>{errors?.otherInfo?.message}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-section">
                                        <button
                                            type="submit"
                                            className="btn btn-primary submit-btn m-r-10"
                                            onClick={() => setIsSend(false)}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary submit-btn m-r-10"
                                            onClick={() => setIsSend(true)}
                                        >
                                            Save &amp; Send
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default EditEstimate;