import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { allCompaniesList } from "../../../features/companySlice";
import { emailrgx } from "../../../constant";
import ReactSelect from "react-select";
import { getClient, updateClient } from "../../../features/clientSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/Loader";

// Validation schema
const schema = yup.object({
  clientName: yup.string().required("Name is required").trim(),
  clientEmail: yup
    .string()
    .required("Email is required")
    .matches(emailrgx, "Invalid Email")
    .trim(),
  clientAddress: yup.string().required("Address is required"),
  country: yup.string().required("Country is required").trim(),
  about: yup.string().required().trim(),
  companyIdentifier: yup.string().required(),
}).required();

function UpdateClient() {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading: companyLoading, allCompanies } = useSelector((state) => state.company);
  const { isLoading, client } = useSelector((state) => state.client);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Fetch client data on mount
  useEffect(() => {
    dispatch(getClient(clientId));
  }, [dispatch, clientId]);

  // Fetch companies list on mount
  useEffect(() => {
    dispatch(allCompaniesList());
  }, [dispatch]);

  // Prepare company options for ReactSelect
  const companyOptions = allCompanies.map((company) => ({
    value: company.companyIdentifier,
    label: company.name,
  }));

  // Handle form submission
  const onSubmit = (data) => {
    const isCompany = false;
    const formData = new FormData();
    formData.append("ClientName", data.clientName);
    formData.append("ClientEmail", data.clientEmail);
    formData.append("ClientAddress", data.clientAddress);
    formData.append("Country", data.country);
    formData.append("About", data.about);
    formData.append("CompanyIdentifier", data.companyIdentifier);

    dispatch(updateClient(formData, clientId, navigate, isCompany));
  };

  return (
    <>
      {isLoading || companyLoading ? (
        <Loader />
      ) : client ? (
        <div className="account-content">
          <div className="container">
            <div className="account-wrapper">
              <h3 className="account-title">Update Client</h3>
              <div className="modal-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Client Name</label>
                        <Controller
                          name="clientName"
                          control={control}
                          defaultValue={client.clientName}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control ${errors?.clientName ? "error-input" : ""}`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                        />
                        <small>{errors?.clientName?.message}</small>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Client Email</label>
                        <Controller
                          name="clientEmail"
                          control={control}
                          defaultValue={client.clientEmail}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control ${errors?.clientEmail ? "error-input" : ""}`}
                              type="email"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                        />
                        <small>{errors?.clientEmail?.message}</small>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Client Address</label>
                        <Controller
                          name="clientAddress"
                          control={control}
                          defaultValue={client.clientAddress}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control ${errors?.clientAddress ? "error-input" : ""}`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                        />
                        <small>{errors?.clientAddress?.message}</small>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Country</label>
                        <Controller
                          name="country"
                          control={control}
                          defaultValue={client.country}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control ${errors?.country ? "error-input" : ""}`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                        />
                        <small>{errors?.country?.message}</small>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>About</label>
                        <Controller
                          name="about"
                          control={control}
                          defaultValue={client.about}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control ${errors?.about ? "error-input" : ""}`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                        />
                        <small>{errors?.about?.message}</small>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Company</label>
                        <Controller
                          name="companyIdentifier"
                          control={control}
                          defaultValue={client.companyIdentifier}
                          render={({ field: { value, onChange } }) => (
                            <ReactSelect
                              options={companyOptions}
                              value={companyOptions.find(
                                (option) => option.value === value
                              )}
                              onChange={(selected) => onChange(selected?.value)}
                              isClearable
                              isSearchable
                            />
                          )}
                        />
                        <small>{errors?.companyIdentifier?.message}</small>
                      </div>
                    </div>
                  </div>

                  <div className="form-group text-center">
                    <button className="btn btn-primary account-btn" type="submit">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Client data not found.</p>
      )}
    </>
  );
}

export default UpdateClient;